import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { basePath, cmsPath, devMode } from "./config";

import Support from "./support";
import { CMSwithLogin } from "./cms";

const App = () => (
  <Router basename={basePath}>
    <Switch>
      { <Route path={cmsPath} component={CMSwithLogin} />}
      <Route path={basePath} component={Support} />
    </Switch>
  </Router>
);

export default App;
