import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";

import { faqsPath } from "../config";
import { faqsActions } from "../redux/faqs/actions";

import TextBlock from "./TextBlock";

const TagAsAnchor = ({ data: { name, slug } }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClick = () => {
    const { setFaqState, setTags } = faqsActions;
    dispatch(setFaqState({ activeTags: [] }));
    dispatch(setTags([slug]));
    history.push(faqsPath);
  };

  return (
    <div className="content__anchor-tag" onClick={handleClick}>
      <TextBlock data={`<p>${name}</p>`} />
    </div>
  );
};

export default TagAsAnchor;
