import React from "react";
import { useTranslation } from "react-i18next";

import Col from "react-bootstrap/Col";
import { Checkbox } from "./Checkbox";

export const ResellerCustomerEscalationCheckbox = () => {
  const { t } = useTranslation();

  return (
    <Col xs={12}>
      <Checkbox storeKey={"resellerCustomerEscalation"} label={t("reseller-customer-escalation")} />
    </Col>
  );
};
