import React from "react";
import { useTranslation } from "react-i18next";

import AttachmentIcon from "@mui/icons-material/Attachment";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import ClearIcon from "@mui/icons-material/Clear";
import HourglassFullIcon from "@mui/icons-material/HourglassFull";

export const Attachments = ({
  attachmentUploadFailedToken,
  attachmentsCountLimit,
  attachments,
  attachmentTokens,
  removeAttachment,
  handleFileInputChange,
  handleFileInputClick,
  uploading,
}) => {
  const { t } = useTranslation();
  const attachmentsCount = attachments.length;
  const canAddMoreAttachments = attachmentsCount < attachmentsCountLimit;
  const remainingAttachmentsCount = attachmentsCountLimit - attachmentsCount;

  const generateAttachments = (attachments) =>
    attachments.map((attachment, index) => (
      <Attachment
        key={index}
        attachment={attachment}
        attachmentToken={attachmentTokens[index]}
        attachmentUploadFailedToken={attachmentUploadFailedToken}
        removeAttachment={() => removeAttachment(index)}
      />
    ));

  const AttachmentsList = ({ attachments }) => (
    <>
      {generateAttachments(attachments)}
      {canAddMoreAttachments && (
        <AddMoreAttachments
          text={t("can-add-more-files", {
            count: remainingAttachmentsCount,
          })}
        />
      )}
    </>
  );

  return (
    <div className="attachments-container">
      {uploading && <LoadingSpinner />}
      {attachmentsCount ? (
        <AttachmentsList attachments={attachments} />
      ) : (
        <UploadFiles text={t("add-files", { limit: attachmentsCountLimit })} />
      )}
      <FilesInput onFileInputChange={handleFileInputChange} onFileInputClick={handleFileInputClick} />
    </div>
  );
};

const Attachment = ({ attachment, attachmentToken, attachmentUploadFailedToken, removeAttachment }) => {
  let className = "attachment";
  const receivedUploadResult = attachmentToken !== undefined;
  const uploadedSuccessfully = attachmentToken !== attachmentUploadFailedToken;

  if (receivedUploadResult) {
    uploadedSuccessfully ? (className += " attachment--success") : (className += " attachment--fail");
  }

  return (
    <div className={className}>
      <div className="attachment__body">
        <AttachmentIcon className="attachment__file-icon" />
        <span className="attachment__file-name">{attachment.name}</span>
      </div>
      <ClearIcon className="attachment__remove-icon" onClick={removeAttachment} />
    </div>
  );
};

const UploadFiles = ({ text }) => (
  <label className="attachments__upload-files">
    <AttachFileIcon />
    <p className="upload-files__text">{text}</p>
  </label>
);

const FilesInput = ({ onFileInputChange, onFileInputClick }) => (
  <input
    className="attachments__files-input"
    type="file"
    name="attachments"
    title=""
    onChange={onFileInputChange}
    onClick={onFileInputClick}
    multiple
  />
);

const AddMoreAttachments = ({ text }) => <p className="attachments__add-more">{text}</p>;

export const AttachmentErrors = ({ errors, onClearErrorsIconClick }) => {
  let className = "attachments-errors-container";

  if (errors.length) {
    className += " attachments-errors-container--visible";
  }

  return (
    <div className={className}>
      <ClearIcon className="attachments__errors-clear-icon" onClick={onClearErrorsIconClick} />
      <AttachmentErrorsList errors={errors} />
    </div>
  );
};

const AttachmentErrorsList = ({ errors }) => {
  const generateErrors = (errors) => errors.map((error, index) => <AttachmentError key={index} error={error} />);

  return <ul className="attachments__errors-list">{generateErrors(errors)}</ul>;
};

const AttachmentError = ({ error }) => <li className="attachment__error">{error}</li>;

const LoadingSpinner = () => (
  <div className="spinner">
    <HourglassFullIcon className="spinner__icon" />
  </div>
);
