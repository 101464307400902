import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";

import { faqsPath } from "../../config";
import { faqsActions } from "../../redux/faqs/actions";

import SearchIcon from "@mui/icons-material/Search";

import { styles } from "./styles";

const useStyles = createUseStyles(styles);

const Search = () => {
  const { t } = useTranslation();
  const { search, activeTopic, allTopics, allQuestions } = useSelector((state) => state.faqs);
  const dispatch = useDispatch();
  const { setFaqSearch, setTopic } = bindActionCreators(faqsActions, dispatch);
  const classes = useStyles();
  const history = useHistory();

  const onChange = ({ target: { value } }) => {
    allQuestions.map((question) => (question.keywordsFound = 0));
    if (history.location.pathname !== faqsPath) {
      history.push(faqsPath);
    }
    if (value) {
      if (!!allTopics.CUSTOM_TOPIC && activeTopic !== allTopics.CUSTOM_TOPIC.slug) {
        setTopic(allTopics.CUSTOM_TOPIC.slug);
      }
    } else {
      if (!!allTopics.COMMON_QUESTIONS && activeTopic === allTopics.CUSTOM_TOPIC.slug) {
        setTopic(allTopics.COMMON_QUESTIONS.slug);
      }
    }
    setFaqSearch(value);
  };

  return (
    <>
      <input className={classes.input} type="text" value={search} placeholder={t("search")} onChange={onChange} />
      <SearchIcon className={classes.magnify} />
    </>
  );
};

export default Search;
