import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { zendeskActions } from "../redux/zendesk-form/actions";
import { makeStyles } from "@material-ui/core";

import TextField from "@material-ui/core/TextField";

import { fontSize, colors } from "../styles/variables";

const styles = {
  root: {
    overflow: "hidden",
  },
  input: {
    fontSize: fontSize.lg,
    lineHeight: "normal",
    background: colors.zenFormInputBackground,
  },
  label: {
    fontSize: fontSize.lg,
    width: "calc(100% - 28px)",
  },
  notchedOutline: {
    border: 0,
  },
};

const useStyles = makeStyles(styles);

export const BasicInput = ({ storeKey, ...customTextFieldProps }) => {
  const dispatch = useDispatch();
  const { setState } = bindActionCreators(zendeskActions, dispatch);
  const value = useSelector((state) => state.zendeskForm[storeKey]);
  const classes = useStyles();

  return (
    <TextField
      fullWidth
      inputProps={{ className: classes.input }}
      InputProps={{
        classes: {
          root: classes.root,
          notchedOutline: classes.notchedOutline,
        },
      }}
      InputLabelProps={{
        className: classes.label,
      }}
      margin="normal"
      variant="outlined"
      value={value}
      onChange={(e) => {
        setState({ [storeKey]: e.target.value });
      }}
      {...customTextFieldProps}
    />
  );
};
