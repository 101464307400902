import React from "react";
import { useTranslation } from "react-i18next";

import { ButtonAsAnchor } from "../common/ButtonAsAnchor";

export const OrderNumberTip = ({ location }) => {
  const { t } = useTranslation();

  return (
    <p style={{ margin: "1em 0" }}>
      * {t("check-email-to-find-order-number-1")}
      <ButtonAsAnchor text={t("check-email-to-find-order-number-link")} location={location} spaceAfter={false} />
      {t("check-email-to-find-order-number-2")}
    </p>
  );
};
