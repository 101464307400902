import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router";
import i18next from "i18next";

import { faqsPath, NONCE } from "../config";
import * as serviceWorker from "./serviceWorker";
import "./i18n";
import { appActions } from "../redux/app/actions";
import { faqsActions } from "../redux/faqs/actions";
import { fetchSupportWizardTree, wizardActions } from "../redux/wizard/actions";
import { orderCheckerActions } from "../redux/order-checker/actions";

import { Cache } from "../helpers/Cache";

import App from "./App";

import "bootstrap/dist/css/bootstrap-grid.min.css";

const Support = () => {
  const dispatch = useDispatch();
  const { language, nonce } = useSelector((state) => state.app);
  const location = useLocation();

  if (nonce !== NONCE) {
    Cache.clearLastVisit();
    dispatch(appActions.resetApp());
    dispatch(appActions.setNonce(NONCE));
    dispatch(appActions.setLanguage(language));
    dispatch(faqsActions.resetFAQs());
    dispatch(wizardActions.resetWizard());
    dispatch(fetchSupportWizardTree());
    dispatch(orderCheckerActions.resetState());
  }

  useEffect(() => {
    if (location.pathname.startsWith(faqsPath)) {
      i18next.changeLanguage("en");
    } else {
      i18next.changeLanguage(language);
    }
  }, []);

  return <App />;
};

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

export default Support;
