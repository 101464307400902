import React from "react";
import { useSelector } from "react-redux";

import { PRODUCT_NAMES } from "../config";
import { getProductFromState } from "../helpers/urlWiz";
import { replacePlaceholderWithProductCapitalized, onFAQsPage } from "./utils";
import { wizardAnswersSelector } from "../redux/wizard/selectors";

import TextBlock from "./TextBlock";
import ImageBlock from "./ImageBlock";
import VideoBlock from "./VideoBlock";
import InlineBlock from "./InlineBlock";
import FAQList from "./FAQList";
import { ContentContainer } from "./ContentContainer";

import "./styles.scss";

const Content = ({ data }) => {
  if (data === null) return null;
  const { title, slug, contentCentred, content = "" } = data;
  const wizardAnswers = useSelector(wizardAnswersSelector);
  const { activeProduct } = useSelector((state) => state.faqs);

  const product = onFAQsPage() ? activeProduct : getProductFromState(wizardAnswers);

  return (
    <ContentContainer contentCentred={contentCentred}>
      <h1 className="content__title">{replacePlaceholderWithProductCapitalized(title, PRODUCT_NAMES[product])}</h1>
      {!!content && content.map((pieceOfContent, index) => createElementFromContent(index, pieceOfContent))}
    </ContentContainer>
  );
};

const createElementFromContent = (key, content) => {
  const { type } = content;
  let embedded;

  switch (type) {
    case "text":
      return <TextBlock key={key} data={content.value} />;
    case "image":
      return <ImageBlock key={key} data={content.image} />;
    case "video":
      ({ embedded } = content);
      const { sources: videoSources } = content;
      const sources = Object.values(videoSources).filter((videoSource) => !!videoSource);
      return <VideoBlock key={key} data={{ sources, embedded }} />;
    case "inline-text-text":
    case "inline-text-image":
    case "inline-image-text":
    case "inline-image-image":
    case "inline-3-images":
    case "inline-tag-tag":
      return <InlineBlock key={key} data={content} />;
    // case "faq":
    //   const {
    //     faqRef: { id, slug, title },
    //   } = content;
    //   ({ embedded } = content);
    //   return <FAQ key={key} data={{ id, slug, title, embedded }} />;
    case "faq-list":
      return <FAQList key={key} faqs={content.faqs} />;
    default:
      return <p key={Math.random() * 1000000}>{type} not covered yet</p>;
  }
};

export default Content;
