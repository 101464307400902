import React, { useEffect, useState, useRef } from "react";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { ATTACHMENTS, zendeskUploadsEndpoint } from "../config";
import { zendeskActions } from "../redux/zendesk-form/actions";

import Col from "react-bootstrap/Col";
import { Attachments, AttachmentErrors } from "./Attachments";
import { FormSection } from "./FormSection";

import "../styles/attachments.scss";

export default ({ message }) => {
  const { t } = useTranslation();
  const storeKey = "attachmentTokens";
  const attachmentTokens = useSelector((state) => state.zendeskForm[storeKey]);

  const dispatch = useDispatch();
  const setState = bindActionCreators(zendeskActions.setState, dispatch);
  const [attachments, setAttachments] = useState([]);
  const [uploading, setUploading] = useState(false);
  const prevAttachmentsCountRef = useRef(attachments.length);
  const [errors, setErrors] = useState([]);

  const getFileExtension = (filename) => filename.split(".").slice(-1)[0];

  const filterFilesWithSupportedFormats = (files) => {
    const errors = [];

    const filteredFiles = files.filter((file) => {
      const fileExtension = getFileExtension(file.name);
      const isSupportedFormat = fileExtension && ATTACHMENTS.SUPPORTED_FORMATS.includes(fileExtension.toLowerCase());

      if (!isSupportedFormat) {
        errors.push(t("file-format-not-supported", { filename: file.name }));
      }

      return isSupportedFormat;
    });

    return [filteredFiles, errors];
  };

  const filterFilesThatMeetSizeLimit = (files) => {
    const errors = [];

    const filteredFiles = files.filter((file) => {
      const hasNotExceededSizeLimit = file.size <= ATTACHMENTS.SIZE_LIMIT;

      if (!hasNotExceededSizeLimit) {
        errors.push(
          t("exceeded-the-file-size-limit", {
            filename: file.name,
            sizeLimitInMB: ATTACHMENTS.SIZE_LIMIT_IN_MB,
          }),
        );
      }

      return hasNotExceededSizeLimit;
    });

    return [filteredFiles, errors];
  };

  const sliceAttachments = (files) => {
    const errors = [];

    if (files.length > ATTACHMENTS.COUNT_LIMIT) {
      const slicedAttachments = files.slice(0, ATTACHMENTS.COUNT_LIMIT);
      errors.push(t("exceeded-the-files-count-limit"));

      return [slicedAttachments, errors];
    }

    if (attachments.length + files.length > ATTACHMENTS.COUNT_LIMIT) {
      errors.push(t("exceeded-the-files-count-limit"));
    }

    return [files, errors];
  };

  const filterFilesThatMeetConstraints = (files) => {
    const [supportedFiles, formatErrors] = filterFilesWithSupportedFormats(files);
    const [filesThatMeetSizeLimit, sizeErrors] = filterFilesThatMeetSizeLimit(supportedFiles);
    const [slicedFiles, countLimitErrors] = sliceAttachments(filesThatMeetSizeLimit);

    return [slicedFiles, [...countLimitErrors, ...formatErrors, ...sizeErrors]];
  };

  const handleFileInputChange = (event) => {
    const [files, errors] = filterFilesThatMeetConstraints(Object.values(event.target.files));
    const hasSelectedFiles = !!files.length;
    const hasUploadedFiles = !!attachments.length;

    if (hasSelectedFiles) {
      if (hasUploadedFiles) {
        setAttachments([...attachments, ...files].slice(0, ATTACHMENTS.COUNT_LIMIT));
      } else {
        setAttachments(files);
      }
    }

    setErrors(errors);
  };

  const handleFileInputClick = (event) => {
    // allows consecutive reuploading of the same file
    event.target.value = null;
    setErrors([]);
  };

  const handleClearErrorsIconClick = () => {
    setErrors([]);
  };

  const uploadFile = async (file, index) => {
    const [fileName, fileExtension] = file.name.split(".");
    const url = zendeskUploadsEndpoint + file.name;
    const data = file;
    const config = {
      headers: {
        "Content-Type": `image/${fileExtension}`,
      },
    };

    try {
      if (!!attachmentTokens[index]) {
        return Promise.resolve(attachmentTokens[index]);
      }

      return await axios.post(url, data, config);
    } catch (error) {
      return ATTACHMENTS.UPLOAD_FAILED_TOKEN;
    }
  };

  const extractAttachmentTokens = async (files) => {
    const uploadPromises = files.map(uploadFile);
    const uploadResults = await Promise.all(uploadPromises);

    const getAttachmentToken = (uploadResult) => {
      try {
        if (attachmentTokens.includes(uploadResult)) {
          return uploadResult;
        }

        return uploadResult.data.upload.token;
      } catch (error) {
        return ATTACHMENTS.UPLOAD_FAILED_TOKEN;
      }
    };

    return Promise.resolve(uploadResults.map(getAttachmentToken));
  };

  const removeAttachment = (index) => {
    attachments.splice(index, 1);
    setAttachments([...attachments]);

    attachmentTokens.splice(index, 1);
    setState({ [storeKey]: attachmentTokens });
    setErrors([]);
  };

  useEffect(() => {
    //clear cached attachment tokens on page load
    setState({ [storeKey]: [] });
  }, []);

  useEffect(() => {
    if (prevAttachmentsCountRef.current < attachments.length) {
      setUploading(true);

      extractAttachmentTokens(attachments).then((attachmentTokens) => {
        setState({ [storeKey]: attachmentTokens });
        setUploading(false);
      });
    }

    prevAttachmentsCountRef.current = attachments.length;
  }, [attachments]);

  return (
    <>
      <FormSection text={t("attach-images")}>
        <p className="attachments__upload-tip">{message || t("attachments-upload-tip")}</p>
      </FormSection>
      <Col sm={12} className="attachments-wrapper">
        <Attachments
          attachmentUploadFailedToken={ATTACHMENTS.UPLOAD_FAILED_TOKEN}
          attachmentsCountLimit={ATTACHMENTS.COUNT_LIMIT}
          attachments={attachments}
          attachmentTokens={attachmentTokens}
          handleFileInputChange={handleFileInputChange}
          handleFileInputClick={handleFileInputClick}
          removeAttachment={removeAttachment}
          uploading={uploading}
        />
        <AttachmentErrors errors={errors} onClearErrorsIconClick={handleClearErrorsIconClick} />
      </Col>
    </>
  );
};
