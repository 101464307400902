import React from "react";
import { useSelector } from "react-redux";

import { openInNewTab } from "../helpers/urlWiz";
import { orderCheckerSelector } from "../redux/order-checker/selectors";

import { ButtonAnswer } from "../wizard/Answer";

export const ReviewOrderButton = ({ answerData: { title } }) => {
  const { orderStatusUrl } = useSelector(orderCheckerSelector);

  const reviewOrder = () => {
    orderStatusUrl && openInNewTab(orderStatusUrl);
  };

  return <ButtonAnswer title={title} onClick={reviewOrder} />;
};
