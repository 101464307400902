import { fontSize } from "../../styles/variables";

const input = {
  width: "100%",
  margin: "8px 0",
  padding: "6px 64px 6px 12px",
  fontSize: fontSize.titleSm,
  lineHeight: 1.5,
  border: "1px solid black",
  position: "relative",
  zIndex: 1,
  background: "transparent",
  fontWeight: 500,
};

const magnify = {
  width: "56px !important",
  height: "56px !important",
  position: "absolute",
  top: "50%",
  right: "20px",
  transform: "translateY(-50%)",
};

export const styles = {
  input,
  magnify,
};
