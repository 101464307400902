import {
  SET_EDITOR_TOKEN,
  SET_EDITOR_TOKEN_EXPIRY_TIME,
  SET_SUPPORT_WIZARD_TREE,
  SET_EXPANDED_BRANCH,
  START_COPYING_BRANCH,
  CANCEL_COPYING_BRANCH,
  START_CUTTING_BRANCH,
  CANCEL_CUTTING_BRANCH,
} from "./types";

const initialState = {
  editorToken: "",
  editorTokenExpiryTime: 0,
  supportWizardTree: {},
  expandedBranch: "",
  copyingBranch: false,
  copiedBranch: "",
  copiedBranchSlug: "",
  copiedBranchCollectionType: "",
  cuttingBranch: false,
  cutBranch: "",
  cutBranchSlug: "",
  cutBranchCollectionType: "",
};

export const cmsReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_EDITOR_TOKEN:
      return {
        ...state,
        editorToken: payload,
      };
    case SET_EDITOR_TOKEN_EXPIRY_TIME:
      return {
        ...state,
        editorTokenExpiryTime: payload,
      };
    case SET_SUPPORT_WIZARD_TREE:
      return {
        ...state,
        supportWizardTree: payload,
      };
    case SET_EXPANDED_BRANCH:
      return {
        ...state,
        expandedBranch: payload,
      };
    case START_COPYING_BRANCH:
      const { copiedBranch, copiedBranchSlug, copiedBranchCollectionType } = payload;
      return {
        ...state,
        copyingBranch: true,
        copiedBranch,
        copiedBranchSlug,
        copiedBranchCollectionType,
      };
    case CANCEL_COPYING_BRANCH:
      return {
        ...state,
        copyingBranch: false,
        copiedBranch: "",
        copiedBranchSlug: "",
        copiedBranchCollectionType: "",
      };
    case START_CUTTING_BRANCH:
      const { cutBranch, cutBranchSlug, cutBranchCollectionType } = payload;
      return {
        ...state,
        cuttingBranch: true,
        cutBranch,
        cutBranchSlug,
        cutBranchCollectionType,
      };
    case CANCEL_CUTTING_BRANCH:
      return {
        ...state,
        cuttingBranch: false,
        cutBranch: "",
        cutBranchSlug: "",
        cutBranchCollectionType: "",
      };
    default:
      return state;
  }
};
