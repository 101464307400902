import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { zendeskActions } from "../redux/zendesk-form/actions";

import Col from "react-bootstrap/Col";
import { FormControlLabel, TextField, MenuItem, RadioGroup, Radio, makeStyles } from "@material-ui/core";
import { BasicInput } from "./Input";
import { FormSection } from "./FormSection";

import { styles } from "./CountrySelect";
import "../styles/shared.scss";

export const Subject = () => {
  const { t } = useTranslation();
  return (
    <>
      <FormSection text={t("subject")} />
      <Col sm={12}>
        <BasicInput storeKey={"contactReason"} label={t("contact-reason")} required placeholder={t("contact-reason-placeholder")} />
      </Col>
      <ContactMethod />
    </>
  );
};

export const ContactID = () => {
  const { t } = useTranslation();
  return (
    <Col sm={12}>
      <BasicInput storeKey={"contactID"} label={t("contact-id")} required />
    </Col>
  );
};

const useStyles = makeStyles(styles);

export const ContactMethod = () => {
  const { t } = useTranslation();
  const storeKey = "contactMethod";
  const contactMethod = useSelector((state) => state.zendeskForm[storeKey]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const setState = bindActionCreators(zendeskActions.setState, dispatch);

  return (
    <Col sm={12}>
      <TextField
        required={true}
        value={contactMethod}
        onChange={(e) => setState({ [storeKey]: e.target.value })}
        inputProps={{ className: classes.input }}
        InputProps={{
          classes: {
            root: classes.root,
            notchedOutline: classes.notchedOutline,
          },
        }}
        variant="outlined"
        fullWidth
        select
        label={t("contact-method")}
        margin="normal"
        InputLabelProps={{ className: classes.label }}
      >
        <MenuItem value="">{t("contact-method")}</MenuItem>
        <MenuItem value="phone">{t("phone")}</MenuItem>
        <MenuItem value="chat">{t("chat")}</MenuItem>
      </TextField>
    </Col>
  );
};

export const IssueResolved = ({ issueResolved, setIssueResolved }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <FormSection text={t("issue-resolved")} />
      <Col sm={12}>
        <RadioGroup
          className={classes.radioGroup}
          aria-label="issue-resolved"
          name="issueResolved"
          onChange={(e) => setIssueResolved(e.target.value)}
        >
          <FormControlLabel value="yes" control={<Radio checked={issueResolved === "yes"} />} label={t("yes")} />
          <FormControlLabel value="no" control={<Radio checked={issueResolved === "no"} />} label={t("no")} />
          <FormControlLabel
            className={classes.noRightMargin}
            value="disconnected"
            control={<Radio checked={issueResolved === "disconnected"} />}
            label={t("disconnected")}
          />
        </RadioGroup>
      </Col>
    </>
  );
};

export const Priority = () => {
  const { t } = useTranslation();
  const storeKey = "priority";
  const priority = useSelector((state) => state.zendeskForm[storeKey]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const { setState } = bindActionCreators(zendeskActions, dispatch);

  return (
    <Col sm={12} style={{ padding: 0 }}>
      <TextField
        required={true}
        value={priority}
        onChange={(e) => setState({ [storeKey]: e.target.value })}
        inputProps={{ className: classes.input }}
        InputProps={{
          classes: {
            root: classes.root,
            notchedOutline: classes.notchedOutline,
          },
        }}
        variant="outlined"
        fullWidth
        select
        label={t("priority")}
        margin="normal"
        InputLabelProps={{ className: classes.label }}
      >
        <MenuItem value="">{t("priority")}</MenuItem>
        <MenuItem value="low">{t("low")}</MenuItem>
        <MenuItem value="normal">{t("normal")}</MenuItem>
        <MenuItem value="high">{t("high")}</MenuItem>
        <MenuItem value="urgent">{t("urgent")}</MenuItem>
      </TextField>
    </Col>
  );
};
