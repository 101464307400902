import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import ReactGA from "react-ga";
import { makeStyles } from "@material-ui/core";
import axios from "axios";
import _ from "lodash";

import {
  zendeskEndpoint,
  thankYouPath,
  wizardPath,
  thankYouPathMarketing,
  recaptchaSiteKey,
  devMode,
  ZENDESK_TICKET,
  ANSWER_SLUG_TO_ZENDESK_TICKET_TITLE,
  ORDER_CHECKER_OTHER_STORES_PATHS,
  liveChatPath,
} from "../config";
import { wizardAnswersSelector, wizardAnswerTitlesSelector } from "../redux/wizard/selectors";
import { zendeskActions } from "../redux/zendesk-form/actions";
import { Cache, CACHE, COLLECTION_TYPES } from "../helpers/Cache";
import { filterNotWizardEndSlug, openInNewTab } from "../helpers/urlWiz";
import { useLiveChatServiceAvailability, useCallBackServiceAvailability } from "../hooks";

import ReCAPTCHA from "react-google-recaptcha";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { BasicInput } from "./Input";
import { Submit } from "./Submit";

import { colors, fontSize, mediaQueries } from "../styles/variables";

const BUSINESS_INQUIRES = ["reseller", "supplier", "distributor", "press", "other"];

const populateContactReason = async (wizardAnswers, traegerRised) => {
  const isAnswer = (index) => index % 2 === 1;

  const isLowerCase = (value) => value === _.kebabCase(_.lowerCase(value));

  const isUpperCase = (value) => !isLowerCase(value);

  const isLastTitlePartLowerCase = (titleParts) => isLowerCase(titleParts[titleParts.length - 1]);

  const isLikeLastTitlePart = (titleParts, value) => titleParts[titleParts.length - 1] === value;

  const trimAfterFirstLowerCasePart = (titleParts) => {
    const indexOfTitlePartInLowerCase = titleParts.findIndex(isLowerCase);

    if (indexOfTitlePartInLowerCase !== -1) {
      return titleParts.slice(0, indexOfTitlePartInLowerCase + 1);
    }

    return titleParts;
  };

  const answerSlugs = wizardAnswers.reduce((answerSlugs, wizardAnswer, index, wizardAnswers) => {
    if (isAnswer(index)) {
      answerSlugs.push(wizardAnswers[index]);
    }

    return answerSlugs;
  }, []);

  const collectionPreffix = CACHE.PREFIXES[COLLECTION_TYPES.ANSWERS];
  const answers = await Promise.all(answerSlugs.map((answerSlug) => Cache.wizardStore.getItem(collectionPreffix + answerSlug)));

  const titleParts = wizardAnswers.reduce(
    (contactReason, element, index) => {
      if (isAnswer(index)) {
        const answerIndex = Math.floor(index / 2);
        const answer = answers[answerIndex];

        if (answer) {
          const answerSlugToZendeskTicketTitle = answer[ANSWER_SLUG_TO_ZENDESK_TICKET_TITLE.RESPONSE_KEY];

          if (answerSlugToZendeskTicketTitle) {
            answerSlugToZendeskTicketTitle.map((element, index, array) => {
              element = array[array.length - index - 1]; // start from end because lower case slugs have priority and should only be at the end, but can be overwritten in other cases
              const slugPositionIndex = element[ANSWER_SLUG_TO_ZENDESK_TICKET_TITLE.POSITION_KEY] - 1;
              const slugAlias = element[ANSWER_SLUG_TO_ZENDESK_TICKET_TITLE.ALIAS_KEY];
              const existingTitlePart = contactReason[slugPositionIndex];

              if (!existingTitlePart) {
                // if no title part is defined at slug's position
                contactReason[slugPositionIndex] = slugAlias;
              } else if (!isLastTitlePartLowerCase(contactReason)) {
                // if title part is defined at slug's position and last title part is in upper case
                if (!isLowerCase(existingTitlePart)) {
                  // overwrite only title parts in upper case
                  contactReason[slugPositionIndex] = slugAlias;
                }
              } else if (isLowerCase(existingTitlePart)) {
                // if title part is defined at slug's position and is in lower case and last title part is in lower case
                if ((!isLikeLastTitlePart(contactReason, slugAlias) && isLowerCase(slugAlias)) || isUpperCase(slugAlias)) {
                  // overwrite only if not duplicate of the last title part and is in lower case
                  contactReason[slugPositionIndex] = slugAlias;
                }
              } else {
                // if title part is defined at slug's position and is in upper case and last title part is in lower case
                if (!isLikeLastTitlePart(contactReason, slugAlias)) {
                  // overwrite only if not duplicate of the last title part
                  contactReason[slugPositionIndex] = slugAlias;
                }
              }
            });
          }
        }
      }

      return contactReason;
    },
    [traegerRised ? ZENDESK_TICKET.TRAEGER_RAISED : ZENDESK_TICKET.TECHNICAL],
  );

  return trimAfterFirstLowerCasePart(titleParts.filter(Boolean)).join(ZENDESK_TICKET.TITLE_PARTS_SEPARATOR);
};

const styles = {
  root: {
    overflow: "hidden",
  },
  buttonRoot: ({ tabletUp }) => ({
    minWidth: "fit-content",
    padding: 16,
    backgroundColor: "#CF0A2C",
    color: colors.white,
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 22,
    lineHeight: "135%",
    flexBasis: tabletUp ? "40%" : "45%",
    "& .MuiButton-label": {
      display: "flex",
      flexDirection: "column",
    },
  }),
  helperText: {
    fontSize: 16,
    lineHeight: 1.5,
  },
  error: {
    color: colors.darkRed,
    fontWeight: 700,
  },
  proveHuman: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.lg : fontSize.mdLg,
    margin: `${tabletUp ? fontSize.lg : fontSize.mdLg} 0`,
    textAlign: "justify",
  }),
  errorText: ({ tabletUp }) => ({
    color: colors.darkRed,
    textAlign: "center",
    width: "100%",
    fontSize: tabletUp ? fontSize.lg : fontSize.mdLg,
    margin: `${tabletUp ? fontSize.lg : fontSize.mdLg} 0`,
  }),
  submit: ({ uptoPhone }) => ({
    margin: "auto 0",
    paddingBottom: "16px",
    paddingTop: "16px",
    display: "flex",
    justifyContent: "center",
    gap: 16,
    flexWrap: uptoPhone ? "wrap" : "unset",
  }),
  notchedOutline: {
    border: 0,
  },
  noPadding: {
    padding: 0,
  },
  label: {
    fontSize: fontSize.lg,
    width: "calc(100% - 28px)",
  },
  input: {
    padding: 10,
  },
};

const useStyles = makeStyles(styles);

export const MessageBox = ({ labelKey = "your-message" }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Col xs={12}>
      <BasicInput
        storeKey={"message"}
        label={t(labelKey)}
        multiline
        minRows={5}
        required
        InputProps={{
          style: {
            padding: 0,
          },
          classes: {
            root: classes.root,
            notchedOutline: classes.notchedOutline,
            input: classes.input,
          },
        }}
        InputLabelProps={{ className: classes.label }}
      />
    </Col>
  );
};

export const Form = ({
  overrideValues = {},
  children,
  attachmentsRequired = false,
  withReCAPTCHA = false,
  issueResolved = "unset",
  setIssueResolved,
  displayBottomHorizontalLine = true,
  withLiveChatServiceButton = false,
  withCallBackServiceButton = false,
  hideSubmitButton = false,
  isReseller = false,
}) => {
  const { isLiveChatServiceAvailable, isLoadingLiveChatServiceAvailability } = useLiveChatServiceAvailability();
  const { isCallBackServiceAvailable, isLoadingCallBackServiceAvailability } = useCallBackServiceAvailability();

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const tabletUp = useMediaQuery(mediaQueries.bootstrap.tablet);
  const uptoPhone = useMediaQuery(mediaQueries.uptoPhone);
  const classes = useStyles({ tabletUp, uptoPhone });

  const emailsMatch = useSelector((state) => state.zendeskForm.confirmEmail) === useSelector((state) => state.zendeskForm.email);
  const confirmEmail = useSelector((state) => state.zendeskForm.confirmEmail);

  const wizardAnswers = useSelector(wizardAnswersSelector);
  const wizardAnswerTitles = useSelector(wizardAnswerTitlesSelector);

  const { zendeskForm, orderChecker } = useSelector((state) => state);
  const {
    agentEmail,
    contactID,
    contactMethod,
    priority,
    name,
    firstName,
    lastName,
    country,
    email,
    phone,
    address,
    resellerCompanyName,
    resellerContactName,
    resellerEmail,
    resellerPhone,
    resellerAddress,
    resellerCountry,
    resellerCustomerEscalation,
    serialNumber,
    orderNumber,
    message,
    canBeCalled,
    attachmentTokens,
    orderMethod,
    purchasedFromOther,
    purchasedFromRetailer,
    contactReason,
    companyName,
    publicationPlatform,
    website,
    websiteAccountLink,
    deviceOs,
    deviceInfo,
    deviceAppVersion,
    liveChatAvailable,
    // includeSteps
  } = zendeskForm;
  const { zendeskTicketSubject, zendeskTicketFields: zendeskTicketFieldsFromOrderChecker } = orderChecker;

  const [tappedLiveChat, setTappedLiveChat] = useState(false);
  const [tappedCallBack, setTappedCallBack] = useState(false);

  const isLiveChatServiceUnavailable = !liveChatAvailable || !isLiveChatServiceAvailable;
  const isNotUsingOnlyEmailButton = withCallBackServiceButton || withLiveChatServiceButton;
  const defaultEmailButtonText = isNotUsingOnlyEmailButton ? t("email-us") : t("send");
  const [emailButtonText, setEmailButtonText] = useState(defaultEmailButtonText);
  const [liveChatButtonText, setLiveChatButtonText] = useState(t("chat-with-us"));
  const [callBackButtonText, setCallBackButtonText] = useState(t("call-back"));

  const [APIError, setAPIError] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const [recaptcha, setRecaptcha] = useState(false);
  const [captchaError, setRecaptchaError] = useState(false);
  const [attachmentsError, setAttachemntsError] = useState(false);

  useEffect(() => {
    const event = {
      action: "contact-us",
      label: `${location.pathname}${location.search}`,
      category: "support-wizard",
    };
    !devMode && ReactGA.event(event);
  }, [location.search, location.pathname]);

  useEffect(() => {
    setAttachemntsError(false);
  }, [attachmentTokens]);

  useEffect(() => {
    if (!isSending) {
      if (tappedLiveChat) {
        setLiveChatButtonText(t("chat-with-us"));
      } else if (tappedCallBack) {
        setCallBackButtonText(t("call-back"));
      } else {
        setEmailButtonText(defaultEmailButtonText);
      }
    }

    if (isSending) {
      const text = t("sending");

      if (tappedLiveChat) {
        setLiveChatButtonText(text);
      } else if (tappedCallBack) {
        setCallBackButtonText(text);
      } else {
        setEmailButtonText(text);
      }
    }
  }, [isSending]);

  useEffect(() => {
    if (!isSending && isSent) {
      const text = t("done");

      if (tappedLiveChat) {
        setLiveChatButtonText(text);
      } else if (tappedCallBack) {
        setCallBackButtonText(text);
      } else {
        setEmailButtonText(text);
      }
    }
  }, [isSending, isSent]);

  const traegerChatElementRef = useRef(null);

  useEffect(() => {
    traegerChatElementRef.current = document.querySelector('#amazon-connect-chat-widget');
  }, []);

  const validateForm = () => {
    if (!issueResolved) return false;
    if (issueResolved !== "unset" && issueResolved === "no" && !priority) return false;
    if (!emailsMatch) return false;
    if (!message) return false;
    if (attachmentsRequired && !attachmentTokens.length) {
      setAttachemntsError(true);
      return false;
    }

    return true;
  };

  const getM1OrM2Route = (wizardAnswers) => {
    const [firstQ, firstA, secondQ, secondA] = wizardAnswers;

    if (secondQ === "which-product-type") {
      if (secondA === "gen-one") {
        return "m1";
      }

      if (secondA === "gen-two") {
        return "m2";
      }
    }

    return null;
  };

  const wizardAnswerTitlesToTopic = (wizardAnswerTitles) =>
    wizardAnswerTitles
      .map((question, index) => {
        if (index % 2 === 0) {
          const answer = wizardAnswerTitles[index + 1];
          return `${question} = ${answer}`;
        }
      })
      .filter((pair) => pair !== undefined)
      .join("\n");

  const zendeskTicketFieldsFromOrderCheckerToTopic = (zendeskTicketFieldsFromOrderChecker) => {
    if (!zendeskTicketFieldsFromOrderChecker) {
      return "";
    }

    const windowLocationAndPath = location.pathname + location.search;
    const isOtherStorePath = (path) => windowLocationAndPath.startsWith(path);

    if (ORDER_CHECKER_OTHER_STORES_PATHS.map(isOtherStorePath).some(Boolean)) {
      return "\n\nStore = Other";
    }

    return "\n\n" + zendeskTicketFieldsFromOrderChecker.join("\n");
  };

  const filterValidAttachmentTokens = (attachmentTokens) => attachmentTokens.filter((attachmentToken) => !!attachmentToken);

  const resetForm = () => {
    dispatch(zendeskActions.resetState());
    tappedLiveChat && dispatch(zendeskActions.setState({ liveChatAvailable }));
    issueResolved !== "unset" && setIssueResolved("");
  };

  const createTicket = async (e) => {
    e.preventDefault();

    if (!validateForm()) return;

    const body = {
      agentEmail,
      contactID,
      issueResolved,
      contactMethod,
      priority,
      name,
      firstName,
      lastName,
      country,
      email,
      phone,
      address,
      resellerCompanyName,
      resellerContactName,
      resellerEmail,
      resellerPhone,
      resellerAddress,
      resellerCountry,
      resellerCustomerEscalation,
      serialNumber,
      orderNumber,
      message,
      canBeCalled,
      attachmentTokens: filterValidAttachmentTokens(attachmentTokens),
      device: history.location.pathname === wizardPath ? undefined || "" : "",
      // topic: includeSteps ? wizardAnswerTitlesToTopic() : '',
      topic: history.location.pathname === wizardPath ? wizardAnswerTitlesToTopic(wizardAnswerTitles) : history.location.pathname,
      orderMethod,
      purchasedFromOther,
      purchasedFromRetailer,
      contactReason,
      companyName,
      publicationPlatform,
      website,
      websiteAccountLink,
      deviceOs,
      deviceInfo,
      deviceAppVersion,
      zendeskTicketFieldsFromOrderChecker,
      ...overrideValues,
    };
    const traegerRised = !!agentEmail;

    // if (!BUSINESS_INQUIRES.includes(body.contactReason)) {
    if (body.contactReason !== "reseller/wholesale" && body.contactReason !== "press" && body.contactReason !== "business-inquiries") {
      body.contactReason = await populateContactReason(filterNotWizardEndSlug(wizardAnswers), traegerRised);

      if (traegerRised) {
        // in case of tickets raised by Traeger, the format will be '[TRSWF] [contact-reason]'
        body.contactReason += ` ${contactReason}`;
      } else if (body.contactReason.includes("ORD-OC")) {
        // in case of tickets from Order Checker, the format will be '[ORD-OC-[zendeskTicketSubject]] Webform'
        if (zendeskTicketSubject) {
          body.contactReason += `-${zendeskTicketSubject}`;
        }

        body.topic += zendeskTicketFieldsFromOrderCheckerToTopic(zendeskTicketFieldsFromOrderChecker);
      }
    }

    if (tappedLiveChat) {
      body.liveChat = "yes";
    }

    if (tappedCallBack) {
      body.callBack = true;
    }

    const route = getM1OrM2Route(wizardAnswers);
    if (route) {
      body.route = route;
    }

    if (recaptcha || !withReCAPTCHA) {
      setIsSending(true);
      try {
        if(isReseller)
          await sendResellerEmail(body);
        else
          await axios.post(zendeskEndpoint, body);
        setIsSent(true);
        setIsSending(false);
        setTappedLiveChat(false);
        setTappedCallBack(false);
        setRecaptchaError(false);
        setAPIError(false);
        resetForm();

        const customerDetails = new URLSearchParams({ name: `${firstName} ${lastName}`, email });
        tappedLiveChat && openInNewTab(`${liveChatPath}?${customerDetails}`);

        if (traegerRised) {
          // const { grecaptcha } = window;
          // !!grecaptcha && grecaptcha.reset();
          dispatch(zendeskActions.setState({ agentEmail }));
        } else {
          // if (BUSINESS_INQUIRES.includes(body.contactReason)) {
          if (
            body.contactReason === "reseller/wholesale" ||
            body.contactReason === "press" ||
            body.contactReason === "business-inquiries"
          ) {
            history.push(thankYouPathMarketing);
          } else {
            history.push(thankYouPath);
          }
        }
      } catch (error) {
        setAPIError(true);
        setIsSending(false);
        console.error(error);
      }
    } else {
      setRecaptchaError(true);
      setRecaptcha(false);
      setIsSending(false);
    }
  };

  function handleLiveChatClick(event) {

    event.preventDefault();
    //setTappedLiveChat(true);
    if (traegerChatElementRef.current) {
      traegerChatElementRef.current.querySelector('button').click();
    }
  }

  function handleCallBackClick() {
    setTappedCallBack(true);
  }

  function onChange(value) {
    if (value) {
      setRecaptcha(true);
      setRecaptchaError(false);
    } else {
      setRecaptcha(false);
      setRecaptchaError(true);
    }
  }

  const sendResellerEmail = async function(body) {

    const message = `
      Reseller Details\n
      ---------------------------------\n\n
     
        Resseller Company Name: ${body.resellerCompanyName}\n
        Reseller Contact Name: ${body.resellerContactName}\n
        Reseller Email: ${body.resellerEmail}\n
        Reseller Phone: ${body.resellerPhone}\n
        Reseller Address: ${body.resellerAddress}\n
        Reseller Country: ${body.country}\n
      ---------------------------------\n
      Customer Details\n\n
      
        Customer Name: ${body.customerName}\n
        Customer Email: ${body.customerEmail}\n
        Customer Phone: ${body.customerPhone}\n
        Customer Address: ${body.customerAddress}\n\n
        
        
        Serial Number: ${body.serialNumber}\n
        Contact the Customer Directly: ${body.canBeCalled ? 'Yes' : 'No'}\n
        message: ${body.message}\n
    `;
    try {
      await axios.post('/create-ticket', body);
    } catch (error) {
      console.error(error);
    }
  }


  return (
    <div>
      <form onSubmit={createTicket}>
        <hr
          style={{
            height: 3,
            width: "25%",
            color: "#E7E7E7",
            border: "solid",
            borderRadius: 7,
            margin: "8px auto",
          }}
        />
        <Row className={classes.row}>
          {children}
          {/* <Col xs={12}>
          <Checkbox storeKey={'includeSteps'} label={t('include-steps-you-performed-in-email')} />
        </Col> */}
          {withReCAPTCHA && (
            <Col sm={12}>
              <p className={classes.proveHuman + (captchaError ? ` ${classes.error}` : "")}>{t("prove-you-are-human")}</p>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <ReCAPTCHA sitekey={recaptchaSiteKey} onChange={onChange} />
              </div>
            </Col>
          )}
          <Col sm={12} className={classes.submit}>

            {!hideSubmitButton && (<Submit variant={"contained"} classes={{ root: classes.buttonRoot }}>
              <span>{emailButtonText}</span>
            </Submit>)}
            
            {withLiveChatServiceButton && (
              <Submit
                variant={"contained"}
                classes={{ root: classes.buttonRoot }}
                onClick={handleLiveChatClick}                
                //disabled={isLiveChatServiceUnavailable || isLoadingLiveChatServiceAvailability}
              >
                <span>{liveChatButtonText}</span>
                {
                  //isLiveChatServiceUnavailable && <span className={classes.helperText}>{t("service-unavailability")}</span>
                }
              </Submit>
            )}

            {withCallBackServiceButton && (
              <Submit
                variant={"contained"}
                classes={{ root: classes.buttonRoot }}
                onClick={handleCallBackClick}
                disabled={!isCallBackServiceAvailable || isLoadingCallBackServiceAvailability}
              >
                <span>{callBackButtonText}</span>
                {!isCallBackServiceAvailable && <span className={classes.helperText}>{t("service-unavailability")}</span>}
              </Submit>
            )}

            {attachmentsError && <p className={classes.errorText}>{t("no-attachments")}</p>}
            {APIError && <p className={classes.errorText}>{t("try-again-later")}</p>}
            {(email || confirmEmail) && !emailsMatch && <p className={classes.errorText}>{t("check-emails-match")}</p>}

            {(email || confirmEmail) && emailsMatch && issueResolved !== "unset" && !issueResolved && (
              <p className={classes.errorText}>Please select a value for "Issue Resolved"</p>
            )}
            {(email || confirmEmail) && emailsMatch && issueResolved === "no" && !priority && (
              <p className={classes.errorText}>Please select a value for "Priority"</p>
            )}
          </Col>
        </Row>
        {displayBottomHorizontalLine && (
          <hr
            style={{
              height: 3,
              width: "25%",
              color: "#E7E7E7",
              border: "solid",
              borderRadius: 7,
              margin: "8px auto",
            }}
          />
        )}
      </form>
    </div>
  );
};
