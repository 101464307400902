import React from "react";
import { useTranslation } from "react-i18next";

import { ContentContainer } from "../content/ContentContainer";

export const FormTitleAndSubtitle = ({ title, subtitle, contentCentred = true }) => {
  const { t } = useTranslation();
  const defaultTitle = t("customer-happiness-team-ready-to-help");

  return (
    <ContentContainer contentCentred={contentCentred}>
      <h1 className="content__title">{title || defaultTitle}</h1>
      <br/>
    </ContentContainer>
  );
};
