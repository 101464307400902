import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router";

import CloseIcon from "@mui/icons-material/Close";

import { faqsPath } from "../../config";
import { faqsActions } from "../../redux/faqs/actions";

const Tag = ({ name, slug, active, classes }) => {
  const dispatch = useDispatch();
  const { setTags, setTopic } = bindActionCreators(faqsActions, dispatch);
  const history = useHistory();
  const { activeTopic, allTopics } = useSelector((state) => state.faqs);

  const toggleTag = (e) => {
    if (Object.keys(allTopics).length > 0) {
      setTags([e.currentTarget.value]);
      if (!!allTopics.CUSTOM_TOPIC && activeTopic !== allTopics.CUSTOM_TOPIC.slug) {
        setTopic(allTopics.CUSTOM_TOPIC.slug);
      }
      if (history.location.pathname !== faqsPath) {
        history.push(faqsPath);
      }
    }
  };

  return (
    <button className={`${classes.tag}${active ? ` ${classes.tagActive}` : ""}`} value={slug} onClick={toggleTag}>
      <div className={classes.valueWrapper}>
        {active && <CloseIcon className={classes.icon} />}
        <p className={classes.tagValue}>{name}</p>
      </div>
    </button>
  );
};

export default Tag;
