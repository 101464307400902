import React from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { makeStyles } from "@material-ui/core";

import { recaptchaSiteKey } from "../config";

import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ReCAPTCHA from "react-google-recaptcha";
import { BasicInput } from "./Input";
import { Submit } from "./Submit";

import { colors, fontSize, mediaQueries } from "../styles/variables";
import "../styles/shared.scss";

export const styles = {
  buttonRoot: {
    padding: 16,
    backgroundColor: "#CF0A2C",
    color: colors.white,
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 22,
    lineHeight: "135%",
    flexBasis: "40%",
    outline: "none",
    border: "none",
    cursor: "pointer",
  },
  contactSupportButton: {
    padding: 16,
    backgroundColor: "#CF0A2C",
    color: colors.white,
    textTransform: "capitalize",
    fontWeight: 600,
    fontSize: 22,
    lineHeight: "135%",
    outline: "none",
    border: "none",
    cursor: "pointer",
  },
  orderStatusButton: {
    padding: "6px 8px",
    backgroundColor: "green",
    color: colors.white,
    textTransform: "uppercase",
    fontWeight: 500,
    fontSize: "0.875rem",
    lineHeight: "1.75",
    outline: "none",
    border: "none",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "green",
    },
  },
  proveHuman: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.lg : fontSize.mdLg,
    margin: `${tabletUp ? fontSize.lg : fontSize.mdLg} 0`,
    textAlign: "justify",
  }),
  text: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.lg : fontSize.mdLg,
    textAlign: "center",
    marginTop: "16px",
  }),
  status: {
    color: colors.darkGreen,
    fontWeight: 700,
  },
  error: {
    color: colors.darkRed,
    fontWeight: 700,
  },
  submit: {
    margin: "auto 0",
    paddingBottom: "16px",
    paddingTop: "16px",
    justifyContent: "center",
    display: "flex",
  },
};

const useStyles = makeStyles(styles);

export const OrderDetails = ({ statusMessage, recaptchaError, errorMessage, setRecaptchaInstance, onRecaptchaChange, onSubmit }) => {
  const { t } = useTranslation();
  const tabletUp = useMediaQuery(mediaQueries.bootstrap.tablet);
  const classes = useStyles({ tabletUp });

  return (
    <form onSubmit={onSubmit}>
      <Row>
        <Col xs={12}>
          <BasicInput type="email" storeKey="email" label={t("email")} required placeholder={t("example-email")} />
        </Col>
        <Col xs={12}>
          <BasicInput storeKey="orderNumber" label={t("order-number")} required />
        </Col>
        <Col xs={12}>
          <p className={classes.proveHuman + (recaptchaError ? ` ${classes.error}` : "")}>{t("prove-you-are-human")}</p>
          <div
            style={{
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <ReCAPTCHA ref={(e) => setRecaptchaInstance(e)} sitekey={recaptchaSiteKey} onChange={onRecaptchaChange} />
          </div>
        </Col>
      </Row>
      {statusMessage && (
        <Col sm={12}>
          <h3 className={`${classes.text} ${classes.status}`}>{statusMessage}</h3>
        </Col>
      )}
      {errorMessage && (
        <Col sm={12}>
          <h3 className={`${classes.text} ${classes.error}`}>{errorMessage}</h3>
        </Col>
      )}
      <Col xs={12} className={classes.submit} style={{ flexWrap: "wrap" }}>
        <Submit text={t("search")} variant={"contained"} classes={{ root: classes.buttonRoot }} />
      </Col>
    </form>
  );
};
