import React from "react";

const VideoBlock = ({ data: { sources, embedded } }) => (
  <div
    className="content__video"
    dangerouslySetInnerHTML={{
      __html: embedded ? video(sources) : iframe(sources),
    }}
  ></div>
);

const video = (sources) => {
  const sourcesString = sources.map((source) => `<source src="${source}" type="video/mp4" />`).join("");
  return `<video controls>${sourcesString}Your browser does not support the video tag.</video>`;
};

const iframe = (sources) =>
  `<div class="iframe-container"><iframe width="560" height="315" src="${sources[0]}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen title="${sources[0]}"></iframe></div>`;

export default VideoBlock;
