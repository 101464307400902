import { strapiURL, ANSWER_SLUG_TO_ZENDESK_TICKET_TITLE } from "../../config";
import { COLLECTION_TYPES } from "./index";

export const isNull = (value) => value === null;

export const isNotUndefined = (value) => value !== undefined;

export const isEmpty = (array) => array.length === 0;

export const isFAQ = (element) => typeof element.id === "number";

export const getID = (element) => element.id.split("/")[2];

export const getSlugs = (elements) => {
  try {
    return elements.map((element) => element.slug);
  } catch (err) {
    return [];
  }
};

export const getUniqueSlugs = (elements) => {
  try {
    const slugs = new Set();
    elements.map((element) => slugs.add(element.slug));
    return [...slugs];
  } catch (err) {
    return [];
  }
};

export const orderBySlug = (slugs, elements) =>
  slugs
    .reduce((orderedElements, slug, index) => {
      const element = elements.find((element) => element.slug === slug);
      orderedElements[index] = element;

      return orderedElements;
    }, [])
    .filter(Boolean);

export const getColletionType = (element) => (!isFAQ(element) ? element.id.split("/")[1] : COLLECTION_TYPES.FAQS);

export const getStrapiData = (collectionType, queryString) =>
  fetch(`${strapiURL}/${collectionType}?${queryString}`)
    .then((response) => response.json())
    .catch(() => {
      console.log("Couldn't fetch data from Strapi");
    });

export const getIndexIfNotCached = (uncachedElementIndexes, element, index) => {
  isNull(element) && uncachedElementIndexes.push(index);
  return uncachedElementIndexes;
};

export const filterOutForms = (elements) => elements.filter((element) => isFAQ(element) || !element.id.includes(COLLECTION_TYPES.FORMS));

export const mergeCachedAndUncachedElements = (orderedSlugs, uncachedElements, cachedElements) => {
  cachedElements.map((cachedElement) => {
    const index = orderedSlugs.findIndex((slug) => slug === cachedElement.slug);

    if (index !== -1) {
      uncachedElements.splice(index, 0, cachedElement);
    }
  });

  return uncachedElements;
};

export const getContentByLanguage = (elements, collectionType, language) => {
  const getQuestionContentByLanguage = (question, language) => {
    try {
      const { slug, contentCentred, escalation, title, content, DE } = question;
      if (language === "en") {
        return { slug, contentCentred, escalation, title, content };
      }

      const { title: titleDE } = DE;

      if (!titleDE) {
        return { slug, contentCentred, escalation, title, content };
      }

      return { slug, contentCentred, escalation, ...DE };
    } catch (error) { }
  };

  const getAnswerContentByLanguage = (answer, language) => {
    try {
      const { slug, redirectLink, title, image, DE } = answer;

      if (language === "en") {
        return { slug, redirectLink, title, image };
      }

      const { title: titleDE } = DE;

      if (!titleDE) {
        return { slug, redirectLink, title, image };
      }

      return { slug, redirectLink, title: DE.title, image: DE.image };
    } catch (error) { }
  };

  const getFAQContentByLanguage = (faq, language) => {
    try {
      const { slug, MEATER, MEATER_Plus, MEATER_Block, MEATER_2_Plus, MEATER_Pro_Xl, MEATER_Se, title, content, DE } = faq;
      return language === "en"
        ? { slug, MEATER, MEATER_Plus, MEATER_Block, MEATER_2_Plus, MEATER_Pro_Xl, MEATER_Se, title, content }
        : { slug, MEATER, MEATER_Plus, MEATER_Block, MEATER_2_Plus, MEATER_Pro_Xl, MEATER_Se, ...DE };
    } catch (error) { }
  };

  const getContent = {
    [COLLECTION_TYPES.QUESTIONS]: getQuestionContentByLanguage,
    [COLLECTION_TYPES.ANSWERS]: getAnswerContentByLanguage,
    [COLLECTION_TYPES.FAQS]: getFAQContentByLanguage,
  };

  return Array.isArray(elements)
    ? elements.map((element) => getContent[collectionType](element, language))
    : getContent[collectionType](elements, language);
};

export const getUniqueElements = (array) => {
  const uniqueElements = {};
  array.map((element) => {
    uniqueElements[element.slug] = element;
  });

  return Object.values(uniqueElements);
};

export const createQueryString = (array) => array.map((element) => `id_in=${!isFAQ(element) ? getID(element) : element.id}`).join("&");

export const checkImage = (path) =>
  new Promise((resolve, reject) => {
    if (!!path) {
      const img = new Image();
      img.onload = () => resolve(path);
      img.onerror = () => reject();

      img.src = path;
    }
  });

export const checkContentForImages = (content) => {
  const getImageFromContent = (content) => {
    switch (content.type) {
      case "text":
        const paths = content.value.match(/([a-z\-_0-9\/\:\.]*\.(jpg|jpeg|png|gif))/gi);
        !!paths && paths.map((path) => checkImage(strapiURL + path));
        break;
      case "image":
        checkImage(strapiURL + content.image.imageSource);
        break;
      case "inline-text-image":
        checkImage(strapiURL + content.imageRight.imageSource);
        break;
      case "inline-image-text":
        checkImage(strapiURL + content.imageLeft.imageSource);
        break;
      case "inline-image-image":
        checkImage(strapiURL + content.imageLeft.imageSource);
        checkImage(strapiURL + content.imageRight.imageSource);
        break;
      case "inline-3-images":
        checkImage(strapiURL + content.imageLeft.imageSource);
        checkImage(strapiURL + content.imageCenter.imageSource);
        checkImage(strapiURL + content.imageRight.imageSource);
    }
  };

  content.map(getImageFromContent);
};

export const extractDataFrom = (collectionType) => {
  const destructurateQuestion = ({ id, ...rest }) => ({ ...rest });

  const destructurateAnswer = ({ slug, redirectLink, title, image, DE, ...rest }) => ({
    slug,
    redirectLink,
    title,
    [ANSWER_SLUG_TO_ZENDESK_TICKET_TITLE.RESPONSE_KEY]: rest[ANSWER_SLUG_TO_ZENDESK_TICKET_TITLE.RESPONSE_KEY],
    image: !!image ? `${strapiURL}${image.imageSource}` : image,
    DE: {
      title: DE.title,
      image: !!DE.image ? `${strapiURL}${DE.image.imageSource}` : DE.image,
    },
  });

  const destructurateFAQ = ({ slug, title, MEATER, MEATER_Plus, MEATER_Block, MEATER_2_Plus, MEATER_Pro_Xl, MEATER_Se, content, DE }) => ({
    slug,
    title,
    MEATER,
    MEATER_Plus,
    MEATER_Block,
    MEATER_2_Plus,
    MEATER_Pro_Xl,
    MEATER_Se,
    content,
    DE,
  });

  const destructurate = {
    [COLLECTION_TYPES.QUESTIONS]: destructurateQuestion,
    [COLLECTION_TYPES.ANSWERS]: destructurateAnswer,
    [COLLECTION_TYPES.FAQS]: destructurateFAQ,
  };

  return destructurate[collectionType];
};
