import React from "react";

import MobileMenu from "./MobileMenu";
import DesktopMenu from "./DesktopMenu";

import "./styles.scss";

const Navbar = () => (
  <>
    <DesktopMenu />
    <MobileMenu />
  </>
);

export default Navbar;
