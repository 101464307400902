import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

import { fetchFAQs } from "../redux/faqs/actions";

import FAQ from "../content/FAQ";
import { ContentContainer } from "../content/ContentContainer";

export default () => {
  const dispatch = useDispatch();
  const allQuestions = useSelector((state) => state.faqs.allQuestions);
  const [cancelAmendOrderFAQ] = allQuestions.filter((faqs) => faqs.slug === "cancel-order");

  useEffect(() => {
    dispatch(fetchFAQs());
  }, []);

  return <Disclaimer faq={cancelAmendOrderFAQ} />;
};

const Disclaimer = ({ faq }) => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <p className="content__text">{t("cancel-amend-order-disclaimer-1")}</p>
      <p className="content__text">{t("cancel-amend-order-disclaimer-2")}</p>
      {faq && <DisclaimerFAQ faq={faq} />}
      <p className="content__text">{t("cancel-amend-order-disclaimer-3")}</p>
    </ContentContainer>
  );
};

const DisclaimerFAQ = ({ faq: { id, title, slug } }) => <FAQ key={id} data={{ id, title, slug, embedded: false }} />;
