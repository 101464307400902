import { useEffect } from "react";

export const useHandleWindowResize = (resizeHandler) => {
  useEffect(() => {
    window.addEventListener("resize", resizeHandler);

    return () => {
      window.removeEventListener("resize", resizeHandler);
    };
  }, [resizeHandler]);
};
