import React from "react";
import { Row, Col } from "react-bootstrap";
import TextBlock from "./TextBlock";
import ImageBlock from "./ImageBlock";
import TagAsAnchor from "./TagAsAnchor";

const InlineBlock = ({ data }) => (
  <div className="content__inline">
    <Row>{inline(data)}</Row>
  </div>
);

const inline = (content) => {
  const { type } = content;
  let textLeft, textRight, imageLeft, imageCenter, imageRight, tagLeft, tagRight, urls;

  switch (type) {
    case "inline-text-text":
      ({ textLeft, textRight } = content);
      return wrapElements([textLeft, textRight]);
    case "inline-text-image":
      ({ textLeft, imageRight } = content);
      return wrapElements([textLeft, imageRight]);
    case "inline-image-text":
      ({ imageLeft, textRight } = content);
      return wrapElements([imageLeft, textRight]);
    case "inline-image-image":
      ({ imageLeft, imageRight } = content);
      return wrapElements([imageLeft, imageRight]);
    case "inline-3-images":
      ({ imageLeft, imageCenter, imageRight, urls } = content);
      return wrapElements([imageLeft, imageCenter, imageRight], urls);
    case "inline-tag-tag":
      ({ tagLeft, tagRight } = content);
      return wrapElements([tagLeft, tagRight]);
    default:
      return "Unknown type for inline element";
  }
};

const wrapElements = (elements, urls = []) => {
  if (typeof urls === "string") {
    urls = urls.split(/[\r\n]+/);
  }

  return elements.map((element, index) => (
    <Col key={index} className="center">
      {inlineElement(index, element, urls[index])}
    </Col>
  ));
};

const inlineElement = (key, content, redirectLink = "") => {
  if (typeof content === "string") {
    return <TextBlock key={key} data={content} />;
  } else if (typeof content === "object") {
    if (content["imageSource"] !== undefined) {
      return <ImageBlock key={key} data={{ ...content, redirectLink }} />;
    } else {
      const { slug, name } = content;
      return <TagAsAnchor key={key} data={{ slug, name }} />;
    }
  }
};

export default InlineBlock;
