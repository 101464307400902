import React, { useState } from "react";
import axios from "axios";

const ForgotPasswordForm = ({ setAuthorized, setLoginEmail, forgotPasswordEndpoint }) => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      setErrorMessage("Please enter your email");
      return;
    }

    !errorMessage && checkEmail(email);
  };

  const handleChange = ({ target: { value } }) => {
    setEmail(value);
    errorMessage && setErrorMessage("");
  };

  const checkEmail = (email) =>
    axios
      .post(forgotPasswordEndpoint, { email })
      .then(({ status }) => {
        if (status === 200) {
          setAuthorized(4);
          setLoginEmail(email);
        }
      })
      .catch(({ response: { data } }) => setErrorMessage(data));

  const showLoginForm = () => setAuthorized(2);

  return (
    <div className="with-login">
      <h1 className="with-login__title">Forgot Password</h1>
      <div className="with-login__error-message">
        <span>{errorMessage}</span>
      </div>
      <form className="with-login__form">
        <input
          className="with-login__form__input"
          type="email"
          name="email"
          value={email}
          required
          placeholder="Enter email address"
          onChange={handleChange}
        />
      </form>
      <button className="with-login__button" type="submit" onClick={handleSubmit}>
        Continue
      </button>
      <div className="with-login__link">
        <span onClick={showLoginForm}>Return to Login</span>
      </div>
    </div>
  );
};

export default ForgotPasswordForm;
