import React from "react";

import { MultiAnswerSlide } from "../wizard/MultiAnswerSlide";
import { ReviewOrderButton } from "./ReviewOrderButton";
import { ContactSupportButton } from "./ContactSupportButton";

export const NoActionsRequired = ({ question }) => (
  <MultiAnswerSlide question={question}>
    {(answersData) => (
      <>
        <ReviewOrderButton answerData={answersData[0]} />
        <ContactSupportButton answerData={answersData[1]} />
      </>
    )}
  </MultiAnswerSlide>
);
