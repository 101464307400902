import { CUSTOMER_SUPPORT_SERVICES } from "../config";
import { useServiceAvailability } from "./useServiceAvailability";

export function useCallBackServiceAvailability() {
  const { isAvailable, isLoading } = useServiceAvailability(CUSTOMER_SUPPORT_SERVICES.CALL_BACK);

  return {
    isCallBackServiceAvailable: isAvailable,
    isLoadingCallBackServiceAvailability: isLoading,
  };
}
