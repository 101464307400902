import React from "react";
import { useDispatch } from "react-redux";

import { orderCheckerActions } from "../redux/order-checker/actions";

import { ButtonAnswer } from "../wizard/Answer";

export const ContactSupportButton = ({ answerData: { title } }) => {
  const dispatch = useDispatch();

  const contactSupport = () => {
    dispatch(orderCheckerActions.displayContactSupportForm());
  };

  return <ButtonAnswer title={title} onClick={contactSupport} />;
};
