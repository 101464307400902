import axios from "axios";
import {
  SET_ACTIVE_TAGS,
  SET_ACTIVE_PRODUCT,
  SET_ACTIVE_TOPIC,
  ADD_VISIBLE_QUESTION,
  REMOVE_VISIBLE_QUESTION,
  SET_SEARCH_VALUE,
  SET_ALL_QUESTIONS,
  SET_ALL_TOPICS,
  SET_ALL_TAGS,
  SET_FAQ_STATE,
  SET_QUESTION,
  RESET_FAQS,
} from "./types";

export const fetchFAQs = () => (dispatch) =>
  axios.get("/faqs-json").then(({ data: { topics, tags, faqs } }) => {
    dispatch(setAllTopics(topics));
    dispatch(setTopic(topics.COMMON_QUESTIONS.slug));
    dispatch(setAllTags(tags));
    dispatch(setAllQuestions(faqs));
    return Promise.resolve(faqs);
  });

const setTags = (payload) => ({ type: SET_ACTIVE_TAGS, payload });

const setProduct = (payload) => ({ type: SET_ACTIVE_PRODUCT, payload });

const setTopic = (payload) => ({ type: SET_ACTIVE_TOPIC, payload });

const addQuestion = (payload) => ({ type: ADD_VISIBLE_QUESTION, payload });

const removeQuestion = (payload) => ({
  type: REMOVE_VISIBLE_QUESTION,
  payload,
});

const setFaqSearch = (payload) => ({ type: SET_SEARCH_VALUE, payload });

const setAllQuestions = (payload) => ({ type: SET_ALL_QUESTIONS, payload });

const setAllTopics = (payload) => ({ type: SET_ALL_TOPICS, payload });

const setAllTags = (payload) => ({ type: SET_ALL_TAGS, payload });

const setFaqState = (payload) => ({ type: SET_FAQ_STATE, payload });

const setQuestions = (payload) => ({ type: SET_QUESTION, payload });

const resetFAQs = () => ({ type: RESET_FAQS });

export const faqsActions = {
  setTags,
  setProduct,
  setTopic,
  addQuestion,
  removeQuestion,
  setFaqSearch,
  setAllQuestions,
  setAllTopics,
  setAllTags,
  setFaqState,
  setQuestions,
  resetFAQs,
};
