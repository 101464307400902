import { addResellerContactNameToZendeskFormState } from "./0";

export const migrations = {
  0: (previousState) => {
    const { zendeskForm: previousZendeskFormState } = previousState;

    const newZendeskFormState = addResellerContactNameToZendeskFormState(previousZendeskFormState);
    previousState.zendeskForm = newZendeskFormState;

    return previousState;
  },
};
