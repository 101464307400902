import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";
import { useMediaQuery } from "react-responsive";
import { createUseStyles } from "react-jss";
import ReactGA from "react-ga";

import { devMode, basePath, wizardPath } from "../config";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { ContentContainer } from "../content/ContentContainer";
import { ButtonAsAnchor } from "../common/ButtonAsAnchor";
import { StrongText } from "../common/StrongText";

import { fontSize, mediaQueries } from "../styles/variables";
import { textStyles, buttonAsAnchor } from "../styles/shared";
import "../styles/shared.scss";

const styles = {
  icon: {
    fontSize: "160px !important",
    display: "block !important",
    margin: "0 auto",
  },
  title: ({ tabletUp }) => ({
    ...textStyles.h1({ tabletUp }),
    textAlign: "center",
    fontWeight: 400,
  }),
  body: ({ tabletUp }) => ({
    ...textStyles.body({ tabletUp }),
    textAlign: "justify",
  }),
  wizardTitle: {
    ...buttonAsAnchor,
    textAlign: "center",
    fontSize: fontSize.xlg,
  },
};

const useStyles = createUseStyles(styles);

export const ThankYou = () => {
  const tabletUp = useMediaQuery(mediaQueries.bootstrap.widePhone);
  const classes = useStyles({ tabletUp });
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const event = {
      action: "thank-you",
      label: `${location.pathname}${location.search}`,
      category: "support-wizard",
    };
    !devMode && ReactGA.event(event);
  }, [location.search, location.pathname]);

  return (
    <div className="p-48">
      <CheckCircleIcon className={classes.icon} />
      <ContentContainer>
        <h1 className="content__title">{t("thank-you-for-feedback")}</h1>
        <p className="content__text">{t("tickets-will-be-answered-1")}</p>
        <p className="content__text">{t("tickets-will-be-answered-2")}</p>
      </ContentContainer>
      <div onClick={() => history.push(basePath)} className={classes.wizardTitle}>
        {t("return-to-support")}
      </div>
    </div>
  );
};

export const ThankYouMarketing = () => {
  const tabletUp = useMediaQuery(mediaQueries.bootstrap.widePhone);
  const classes = useStyles({ tabletUp });
  const history = useHistory();
  const { t } = useTranslation();
  const location = useLocation();

  useEffect(() => {
    const event = {
      action: "thank-you",
      label: `${location.pathname}${location.search}`,
      category: "support-wizard",
    };
    !devMode && ReactGA.event(event);
  }, [location.search, location.pathname]);

  return (
    <div className="p-48">
      <CheckCircleIcon className={classes.icon} />
      <ContentContainer>
        <h1 className="content__title">{t("thank-you-for-feedback")}</h1>
        <p className="content__text">
          {t("tickets-will-be-answered-marketing-1")}
          <StrongText text={t("tickets-will-be-answered-marketing-strong")} />
          {t("tickets-will-be-answered-marketing-2")}
          <ButtonAsAnchor text={t("tickets-will-be-answered-marketing-link")} location={wizardPath} spaceAfter={false} />
          {t("tickets-will-be-answered-marketing-3")}
        </p>
      </ContentContainer>
      <div onClick={() => history.push(basePath)} className={classes.wizardTitle}>
        {t("return-to-support")}
      </div>
    </div>
  );
};
