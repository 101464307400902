import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";
import { createUseStyles } from "react-jss";

import { debounce } from "../helpers/debounce";
import { useHandleWindowResize } from "../hooks";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import LeftChevron from "../assets/heroicons-outline_chevron-left.svg";
import Refresh from "../assets/heroicons-outline_refresh.svg";
import Home from "../assets/heroicons-outline_home.svg";

const styles = {
  slideNav: ({ onEntrySlide }) => ({
    display: "flex",
    justifyContent: onEntrySlide ? "center" : "space-between",
    alignItems: "center",
    marginBottom: "24px",
    paddingTop: "1.5rem",
    width: "100%",
    "@media (max-width: 767px)": {
      justifyContent: "center",
    },
  }),
  menuText: {
    margin: "auto 0",
    // textAlign: 'center',
    fontSize: "0.94rem",
    whiteSpace: "nowrap",
    marginLeft: 15,
    "@media (max-width: 767px)": {
      whiteSpace: "normal",
    },
  },
  navButton: {
    display: "flex",
    alignItems: "center",
    border: "1px solid #D3D3D3",
    borderRadius: "7px",
    padding: 10,
    cursor: "pointer",
    justifyContent: "center",
    "@media (max-width: 767px)": {
      border: "none",
      padding: 0,
      margin: "0 auto",
    },
  },
  horizontalRule: {
    width: "100%",
    height: 1,
    backgroundColor: "#D3D3D3",
    border: "0.75px solid #D3D3D3",
    margin: "8px 0",
    borderRadius: 2,
    "@media (max-width: 767px)": {
      width: "100%",
    },
  },
};

const useStyles = createUseStyles(styles);

export const Navigation = ({ onBackClick, onHomeClick, onRestartClick }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const onEntrySlide = !location.search;
  const classes = useStyles({ onEntrySlide });

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  const NavButton = ({ title, imgSrc, imgAlt, onClick }) => (
    <div className={classes.navButton} onClick={onClick}>
      <img src={imgSrc} alt={imgAlt} />
      <p className={classes.menuText}>{title}</p>
    </div>
  );

  const debouncedHandleResize = debounce(function handleResize() {
    setDimensions({
      height: window.innerHeight,
      width: window.innerWidth,
    });
  });

  useHandleWindowResize(debouncedHandleResize);

  return (
    <Row>
      <Col xs={12}>
        <div>
          <hr className={classes.horizontalRule} />
          <div className={classes.slideNav}>
            {!onEntrySlide && dimensions.width >= 767 && (
              <NavButton title={t("previous")} imgSrc={LeftChevron} imgAlt={t("nav-previous-alt")} onClick={onBackClick} />
            )}
            <NavButton title={t("support-home")} imgSrc={Home} imgAlt={t("nav-support-home-alt")} onClick={onHomeClick} />
            {!onEntrySlide && (
              <NavButton title={t("restart-wizard")} imgSrc={Refresh} imgAlt={t("nav-restart-wizard-alt")} onClick={onRestartClick} />
            )}
          </div>
        </div>
      </Col>
    </Row>
  );
};
