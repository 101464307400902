import { colors, fontSize } from "../styles/variables";

const pageContainer = {
  background: "white",
  marginTop: "40px",
  position: "relative",
};

const topicsAndQuestionHolder = {
  marginTop: "64px",
};

const hero = {
  width: "100%",
  height: "auto",
};

const heroWrapper = {
  position: "relative",
  marginBottom: "48px",
  maxHeight: "160px",
  overflow: "hidden",
};

const heroTitle = {
  fontFamily: "Raleway, sans-serif",
  fontStyle: "normal",
  lineHeight: "75px",
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  color: colors.white,
  fontSize: fontSize.titleLg,
  margin: 0,
  fontWeight: 500,
};

const arrowUp = {
  color: "white",
  height: "54px !important",
  width: "54px !important",
  marginBottom: "5px",
  borderRadius: "50%",
  backgroundColor: "black",
  cursor: "pointer",
};

const arrowUpButton = {
  width: "64px",
  border: "none",
  background: "transparent",
  padding: "5px 5px 0",
  marginLeft: "auto",
  marginRight: 0,
  marginBottom: "32px",
};

const arrowUpText = {
  margin: "16px 0",
  fontSize: fontSize.md,
  textTransform: "uppercase",
  fontWeight: 500,
};

export const styles = {
  pageContainer,
  topicsAndQuestionHolder,
  hero,
  heroWrapper,
  heroTitle,
  arrowUp,
  arrowUpButton,
  arrowUpText,
};
