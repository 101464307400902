import { SET_TREE, SET_ANSWERS, SET_ANSWER_TITLES, RESET_WIZARD } from "./types";

const initialState = {
  tree: {},
  answers: [],
  answerTitles: [],
};

export const wizardReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_TREE:
      return {
        ...state,
        tree: payload,
      };
    case SET_ANSWERS:
      return {
        ...state,
        answers: payload,
      };
    case SET_ANSWER_TITLES:
      return {
        ...state,
        answerTitles: payload,
      };
    case RESET_WIZARD:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
