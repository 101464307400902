import React from "react";
import { useTranslation } from "react-i18next";

import Col from "react-bootstrap/Col";
import { BasicInput } from "./Input";
import { FormSection } from "./FormSection";

import "../styles/shared.scss";

export const ContactBlock = ({
  subtitleKey = "your-details",
  fields = [
    { key: "first-name", required: true },
    { key: "last-name", required: true },
    { key: "email", required: true },
    { key: "confirm-email", required: true },
    { key: "telephone", required: true },
    { key: "shipping-address", required: false },
  ],
}) => {
  const { t } = useTranslation();

  return (
    <>
      <FormSection text={t(subtitleKey)} />
      <Col sm={12}>
        <BasicInput storeKey={"firstName"} label={t(fields[0].key)} required={fields[0].required} />
      </Col>
      <Col sm={12}>
        <BasicInput storeKey={"lastName"} label={t(fields[1].key)} required={fields[1].required} />
      </Col>
      <Col sm={12}>
        <BasicInput
          type="email"
          storeKey={"email"}
          label={t(fields[2].key)}
          required={fields[2].required}
          placeholder={t("example-email")}
        />
      </Col>
      <Col sm={12}>
        <BasicInput
          type="email"
          storeKey={"confirmEmail"}
          label={t(fields[3].key)}
          required={fields[3].required}
          placeholder={t("example-email")}
        />
      </Col>
      <Col sm={12}>
        <BasicInput storeKey={"phone"} label={t(fields[4].key)} required={fields[4].required} />
      </Col>
      <Col sm={12}>
        <BasicInput storeKey={"address"} label={t(fields[5].key)} required={fields[5].required} />
      </Col>
    </>
  );
};
