import axios from "axios";

import {
  SET_EDITOR_TOKEN,
  SET_EDITOR_TOKEN_EXPIRY_TIME,
  SET_SUPPORT_WIZARD_TREE,
  SET_EXPANDED_BRANCH,
  START_COPYING_BRANCH,
  CANCEL_COPYING_BRANCH,
  START_CUTTING_BRANCH,
  CANCEL_CUTTING_BRANCH,
} from "./types";

const getSupportWizardTree = () => (dispatch) => {
  axios
    .get("/cms/wizard/get-tree")
    .then(({ data }) => {
      dispatch(setSupportWizardTree(data));
    })
    .catch((error) => console.log(error));
};

const addLeaf = (branch, id, slug, title) => (dispatch) => {
  axios
    .post("/cms/wizard/add-leaf", {
      id,
      slug,
      title,
      branch,
    })
    .then(({ data }) => {
      dispatch(setSupportWizardTree(data));
    })
    .catch((error) => console.log(error));
};

const changeLeaf = (branch, newID, newSlug, newTitle) => (dispatch) => {
  axios
    .put("/cms/wizard/change-leaf", {
      newID,
      newTitle,
      newSlug,
      branch,
    })
    .then(({ data }) => {
      dispatch(setSupportWizardTree(data));
    })
    .catch((error) => console.log(error));
};

const changeLeafIndex = (branch, newIndex) => (dispatch) => {
  axios
    .put("/cms/wizard/change-leaf-index", {
      newIndex,
      branch,
    })
    .then(({ data }) => {
      dispatch(setSupportWizardTree(data));
    })
    .catch((error) => console.log(error));
};

const copyBranch =
  ({ copiedBranch, targetLeaf }) =>
  (dispatch) => {
    axios
      .put("/cms/wizard/copy-branch", { copiedBranch, targetLeaf })
      .then(({ data }) => {
        dispatch(setSupportWizardTree(data));
        dispatch(cancelCopyingBranch());
      })
      .catch((error) => console.log(error));
  };

const cutBranch =
  ({ cutBranch, targetLeaf }) =>
  (dispatch) => {
    axios
      .put("/cms/wizard/cut-branch", { cutBranch, targetLeaf })
      .then(({ data }) => {
        dispatch(setSupportWizardTree(data));
        dispatch(cancelCuttingBranch());
      })
      .catch((error) => console.log(error));
  };

const removeLeaf = (branch) => (dispatch) => {
  axios
    .delete("/cms/wizard/remove-leaf", {
      params: { branch },
    })
    .then(({ data }) => {
      dispatch(setSupportWizardTree(data));
    })
    .catch((error) => console.log(error));
};

const setEditorToken = (payload) => ({
  type: SET_EDITOR_TOKEN,
  payload,
});

const setEditorTokenExpiryTime = (payload) => ({
  type: SET_EDITOR_TOKEN_EXPIRY_TIME,
  payload,
});

const setSupportWizardTree = (payload) => ({
  type: SET_SUPPORT_WIZARD_TREE,
  payload,
});

const setExpandedBranch = (payload) => ({
  type: SET_EXPANDED_BRANCH,
  payload,
});

const startCopyingBranch = (payload) => ({
  type: START_COPYING_BRANCH,
  payload,
});

const cancelCopyingBranch = () => ({
  type: CANCEL_COPYING_BRANCH,
});

const startCuttingBranch = (payload) => ({
  type: START_CUTTING_BRANCH,
  payload,
});

const cancelCuttingBranch = () => ({
  type: CANCEL_CUTTING_BRANCH,
});

export const cmsActions = {
  setEditorToken,
  setEditorTokenExpiryTime,
  getSupportWizardTree,
  addLeaf,
  changeLeaf,
  changeLeafIndex,
  copyBranch,
  cutBranch,
  removeLeaf,
  setExpandedBranch,
  startCopyingBranch,
  startCuttingBranch,
  cancelCopyingBranch,
  cancelCuttingBranch,
};
