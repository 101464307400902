import React from "react";
import { Link } from "react-router-dom";

const Navbar = () => (
  <div className="cms__navbar">
    <Link className="cms__navbar__link cms__navbar__link--large" to="/">
      CMS Dashboard
    </Link>
    <Link className="cms__navbar__link" to="/wizard">
      Wizard
    </Link>
    <Link className="cms__navbar__link" to="/faqs">
      FAQs
    </Link>
  </div>
);

export default Navbar;
