import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import axios from "axios";

import { ORDER_CHECKER_ENDPOINTS } from "../config";
import { orderCheckerActions } from "../redux/order-checker/actions";
import { orderCheckerSelector } from "../redux/order-checker/selectors";
import { CACHE, Cache, useCache } from "../helpers/Cache";

import { OrderCheckerUnknownIssueForm, OrderShipmentStatusForm, ZendeskForm } from "../zendesk-form";
import { ReviewOrder } from "./ReviewOrder";
import { PotentialShipmentDelay } from "./PotentialShipmentDelay";
import { ReshipmentRequestIssue } from "./ReshipmentRequestIssue";
import { ReshipmentRequestedSuccessfully } from "./ReshipmentRequestedSuccessfully";
import { RequiresInvestigation } from "./RequiresInvestigation";
import { NoActionsRequired } from "./NoActionsRequired";
import { ReshipmentAlreadyRequested } from "./ReshipmentAlreadyRequested";
import { WithOnMountEffect } from "./WithOnMountEffect";

export const OrderChecker = ({ setNavigationBackClickHandler, supportedStore = true }) => {
  const { wizardStore } = Cache;
  const { WIZARD_LAST_VISIT } = CACHE;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    orderStatusUrl,
    storeIncluded,
    taggedWithUnfulfilledEnquiry,
    taggedWithReshipRequested,
    requiresInvestigation,
    potentialShipmentDelay,
    unknownIssue,
    issueWithReshipmentRequest,
    displayContactSupportForm,
    question,
  } = useSelector(orderCheckerSelector);
  const language = useSelector((state) => state.app.language);
  const [questions, setQuestions] = useState([]);
  const title = supportedStore ? t("order-checker-supported-stores-title") : t("order-checker-unsupported-stores-title");
  const subtitle = supportedStore ? t("order-checker-supported-stores-subtitle") : t("order-checker-unsupported-stores-subtitle");

  const getAnswers = (questions) =>
    questions.reduce((answers, question) => {
      answers.push(...question.leaves);
      return answers;
    }, []);

  const handleCache = async () => {
    const [uncachedQuestions, uncachedAnswers] = await Promise.all([
      Cache.check(wizardStore, questions),
      Cache.check(wizardStore, getAnswers(questions)),
    ]);
    await Promise.all([Cache.load(wizardStore, uncachedAnswers), Cache.load(wizardStore, uncachedQuestions)]);
  };

  const handleFreshCache = handleCache;
  const handleExpiredOrNoCache = handleCache;

  const hideContactSupportForm = () => {
    dispatch(orderCheckerActions.hideContactSupportForm());
  };

  const resetOrderCheckerState = () => {
    dispatch(orderCheckerActions.resetState());
  };

  useCache(handleFreshCache, handleExpiredOrNoCache, wizardStore, WIZARD_LAST_VISIT, [language, questions]);

  useEffect(() => {
    async function fetchOrderCheckerQuestions() {
      const { data } = await axios.get(ORDER_CHECKER_ENDPOINTS.QUESTIONS);
      setQuestions(data);
    }

    resetOrderCheckerState();
    fetchOrderCheckerQuestions();
    return resetOrderCheckerState;
  }, []);

  const OrderCheckerResponse = () => {
    if (orderStatusUrl) {
      if (storeIncluded === true) {
        if (taggedWithReshipRequested == false) {
          if (taggedWithUnfulfilledEnquiry === false) {
            if (requiresInvestigation === true) {
              return <RequiresInvestigation question={question} />;
            }

            if (potentialShipmentDelay === true) {
              if (issueWithReshipmentRequest === true) {
                return <ReshipmentRequestIssue question={question} />;
              }

              if (issueWithReshipmentRequest === false) {
                return <ReshipmentRequestedSuccessfully question={question} />;
              }

              return <PotentialShipmentDelay question={question} />;
            }

            if (requiresInvestigation === false) {
              return <ReviewOrder question={question} />;
            }
          }

          if (taggedWithUnfulfilledEnquiry === true) {
            return <NoActionsRequired question={question} />;
          }
        }

        if (taggedWithReshipRequested == true) {
          return <ReshipmentAlreadyRequested question={question} />;
        }
      }

      if (storeIncluded === false) {
        return <ReviewOrder question={question} />;
      }
    }
  };

  if (displayContactSupportForm) {
    return (
      <WithOnMountEffect onMount={setNavigationBackClickHandler(() => hideContactSupportForm)}>
        <ZendeskForm />
      </WithOnMountEffect>
    );
  }

  if (unknownIssue) {
    return (
      <WithOnMountEffect onMount={setNavigationBackClickHandler(() => resetOrderCheckerState)}>
        <OrderCheckerUnknownIssueForm />
      </WithOnMountEffect>
    );
  }

  if (question) {
    return (
      <WithOnMountEffect onMount={setNavigationBackClickHandler(() => resetOrderCheckerState)}>
        <OrderCheckerResponse />
      </WithOnMountEffect>
    );
  }

  return (
    <WithOnMountEffect onMount={setNavigationBackClickHandler(null)}>
      <OrderShipmentStatusForm title={title} subtitle={subtitle} />
    </WithOnMountEffect>
  );
};
