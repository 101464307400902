import React, { Component } from "react";

import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

import "./styles.scss";

class Alert extends Component {
  static display(type, message) {
    Alert.__singletonRef.__display(type, message);
  }

  constructor(props) {
    super(props);
    this.state = {
      type: "",
      message: "",
      timeOut: null,
      interval: null,
      remainingTime: 0,
      visible: false,
      path: window.location.pathname,
    };

    Alert.__singletonRef = this;

    this.totalTime = 5000;
    this.step = 10;
    this.icons = {
      info: InfoIcon,
      success: CheckCircleIcon,
      error: CancelIcon,
      default: CheckCircleIcon,
    };
  }

  __display = (type, message) => {
    if (this.state.path !== window.location.pathname) {
      this.setState({ path: window.location.pathname });
    }

    this.setState({
      type,
      message,
      visible: true,
    });

    const { remainingTime, timeOut, interval } = this.state;
    if (remainingTime !== 0) {
      clearTimeout(timeOut);
      clearInterval(interval);
    }

    this.setState({ timeOut: this.startTimeOut() });
  };

  startInterval = () => {
    let pastTime = 0;
    let interval = setInterval(() => {
      pastTime += this.step;
      this.setState({ remainingTime: this.totalTime - pastTime });

      if (this.state.path !== window.location.pathname) {
        this.setState({ path: window.location.pathname });
        clearTimeout(this.state.timeOut);
        clearInterval(this.state.interval);
        this.__close();
      }
    }, this.step);

    return interval;
  };

  startTimeOut = () => {
    let interval = this.startInterval();
    this.setState({ interval });

    let timeOut = setTimeout(() => {
      this.__close();
      clearInterval(interval);
    }, this.totalTime);

    return timeOut;
  };

  __close = () => {
    this.setState({
      message: "",
      type: "",
      timeOut: null,
      interval: null,
      remainingTime: 0,
      visible: false,
    });
  };

  render() {
    const { type, message, visible, remainingTime } = this.state;
    const Icon = this.icons[type] || this.icons.default;

    return (
      <div className={"alert" + (type ? ` alert--${type}` : "") + (visible ? " alert--visible " : "")}>
        <div className="alert__body">
          <Icon className={`alert__icon alert__icon--${type}`} />
          <p className="alert__message">{message}</p>
        </div>
        <progress className="alert__progress" value={remainingTime} max={this.totalTime}></progress>
      </div>
    );
  }
}

export default Alert;
