export const SET_ACTIVE_TAGS = "SET_ACTIVE_TAGS";
export const SET_ACTIVE_PRODUCT = "SET_ACTIVE_PRODUCT";
export const SET_ACTIVE_TOPIC = "SET_ACTIVE_TOPIC";
export const ADD_VISIBLE_QUESTION = "ADD_VISIBLE_QUESTION";
export const REMOVE_VISIBLE_QUESTION = "REMOVE_VISIBLE_QUESTION";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const SET_ALL_QUESTIONS = "SET_ALL_FAQ_QUESTIONS";
export const SET_ALL_TOPICS = "SET_ALL_TOPICS";
export const SET_ALL_TAGS = "SET_ALL_TAGS";
export const SET_FAQ_STATE = "SET_FAQ_STATE";
export const SET_QUESTION = "SET_VISIBLE_FAQ_QUESTIONS";
export const RESET_FAQS = "RESET_FAQS";
