import React from "react";

export const ContentContainer = ({ children, forwardedRef, contentCentred }) => {
  let className = "content-container";

  if (contentCentred) {
    className += " content-container--centred";
  }

  return (
    <div ref={forwardedRef} className={className}>
      {children}
    </div>
  );
};

const withForwardRef = (Component) => {
  const forwardRef = (props, ref) => {
    return <Component forwardedRef={ref} {...props} />;
  };

  return React.forwardRef(forwardRef);
};

export const ContentContainerWithForwardRef = withForwardRef(ContentContainer);
