import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { zendeskActions } from "../redux/zendesk-form/actions";

import Col from "react-bootstrap/Col";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { BasicInput } from "./Input";

import { styles } from "./CountrySelect";
import "../styles/shared.scss";

export const CompanyBlock = () => {
  const { t } = useTranslation();

  return (
    <>
      <Col sm={6}>
        <BasicInput storeKey={"companyName"} label={t("company-name")} required />
      </Col>
      <Col sm={6}>
        <BasicInput storeKey={"website"} label={t("website")} />
      </Col>
      {/* <ContactReason /> */}
    </>
  );
};

const useStyles = makeStyles(styles);

const ContactReason = () => {
  const { t } = useTranslation();
  const storeKey = "contactReason";
  const contactReason = useSelector((state) => state.zendeskForm[storeKey]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const setState = bindActionCreators(zendeskActions.setState, dispatch);

  return (
    <Col sm={12}>
      <TextField
        required={true}
        value={contactReason}
        onChange={(e) => setState({ [storeKey]: e.target.value })}
        inputProps={{ className: classes.input }}
        InputProps={{
          classes: {
            root: classes.root,
            notchedOutline: classes.notchedOutline,
          },
        }}
        variant="outlined"
        fullWidth
        select
        label={t("contact-reason")}
        margin="normal"
        InputLabelProps={{ className: classes.label }}
      >
        <MenuItem value="">{t("contact-reason")}</MenuItem>
        <MenuItem value="reseller">{t("reseller")}</MenuItem>
        <MenuItem value="supplier">{t("supplier")}</MenuItem>
        <MenuItem value="distributor">{t("distributor")}</MenuItem>
        <MenuItem value="other">{t("other")}</MenuItem>
      </TextField>
    </Col>
  );
};
