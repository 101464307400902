import React from "react";
import { useHistory } from "react-router";
import { bindActionCreators } from "redux";
import { useSelector, useDispatch } from "react-redux";
import { createUseStyles } from "react-jss";

import { faqsPath } from "../../config";
import { faqsActions } from "../../redux/faqs/actions";

import { styles } from "./styles";

const useStyles = createUseStyles(styles);

const Topic = ({ data: { slug, name } }) => {
  const { activeTopic } = useSelector((state) => state.faqs);
  const dispatch = useDispatch();
  const { setTopic } = bindActionCreators(faqsActions, dispatch);
  const classes = useStyles({ slug, activeTopic });
  const history = useHistory();

  const handleClick = () => {
    setTopic(slug);
    if (history.location.pathname !== faqsPath) {
      history.push(faqsPath);
    }
  };

  return (
    <>
      <h3 className={classes.topicName} onClick={handleClick}>
        {name}
      </h3>
    </>
  );
};

export default Topic;
