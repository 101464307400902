import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useMediaQuery } from "react-responsive";
import { createUseStyles } from "react-jss";
import { useHistory } from "react-router";

import { faqsPath } from "../../config";
import { faqsActions } from "../../redux/faqs/actions";

import Topic from "./Topic";
import AddCircleIcon from "@mui/icons-material/AddCircle";

import { mediaQueries } from "../../styles/variables";
import { styles } from "./styles";

const useStyles = createUseStyles(styles);

const Topics = () => {
  const {
    faqs: { activeTags, activeTopic, activeProduct, visibleQuestions, allTopics, search },
    app: { language },
  } = useSelector((state) => state);
  const dispatch = useDispatch();
  const { setTopic } = bindActionCreators(faqsActions, dispatch);
  const { t } = useTranslation();
  const classes = useStyles(styles);
  const tabletUp = useMediaQuery(mediaQueries.bootstrap.tablet);
  const history = useHistory();
  const showCustomTopic = activeTags.length > 0 || !!search;

  const uniqueTopics = new Set();
  visibleQuestions.map((question) => {
    if (question.tags.includes(activeProduct)) {
      uniqueTopics.add(question.topic);
    }
  });
  const uniqueTopicsArray = [...uniqueTopics];
  const uniqueTopicsData = Object.values(allTopics).filter((topic) => uniqueTopicsArray.includes(topic.slug));

  const topicData = showCustomTopic ? [allTopics.CUSTOM_TOPIC, ...uniqueTopicsData] : uniqueTopicsData;

  const renderTopicsList = () => {
    return topicData.map(({ id, slug, name, DE }) => <Topic key={id} data={{ slug, name: language === "en" ? name : DE.name }} />);
  };

  const renderTopicsOptions = () =>
    topicData.map(({ id, slug, name, DE }) => (
      <option key={id} value={slug}>
        {language === "en" ? name : DE.name}
      </option>
    ));

  const onChangeMobile = (e) => {
    if (history.location.pathname !== faqsPath) {
      history.push(faqsPath);
    }
    setTopic(e.target.value);
  };

  return tabletUp ? (
    <div className={classes.topicsSection}>
      <h2 className={classes.topicTitle}>{t("topics")}</h2>
      {renderTopicsList()}
    </div>
  ) : (
    <div className={classes.topicSelectWrapper}>
      <label className={classes.topicLabel} htmlFor="topicSelect">
        {t("topic")}:
      </label>
      <select className={classes.topicSelect} name="topicSelect" value={activeTopic} onChange={onChangeMobile}>
        {renderTopicsOptions()}
      </select>

      <AddCircleIcon className={classes.plusIcon} />
    </div>
  );
};

export default Topics;
// TODO: split mobile and desktop components into their own thing,
// that way the HOC can deside which to show with what kind of grid markup.
