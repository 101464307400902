import { DISPLAY_CONTACT_SUPPORT_FORM, HIDE_CONTACT_SUPPORT_FORM, SET_ORDER_CHECKER_STATE, RESET_ORDER_CHECKER_STATE } from "./types";

const initialState = {
  orderStatusUrl: "",
  storeIncluded: null,
  taggedWithUnfulfilledEnquiry: null,
  taggedWithReshipRequested: null,
  requiresInvestigation: null,
  potentialShipmentDelay: null,
  unknownIssue: null,
  issueWithReshipmentRequest: null,
  displayContactSupportForm: null,
  reshipmentRequestToken: "",
  question: null,
  zendeskTicketSubject: null,
  zendeskTicketFields: null,
};

export const orderCheckerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case DISPLAY_CONTACT_SUPPORT_FORM:
      return { ...state, displayContactSupportForm: true };
    case HIDE_CONTACT_SUPPORT_FORM:
      return { ...state, displayContactSupportForm: null };
    case SET_ORDER_CHECKER_STATE:
      return { ...state, ...payload };
    case RESET_ORDER_CHECKER_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
