import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from "react-responsive";

import { PRODUCTS } from "../../config";

import Tag from "./Tag";

import { mediaQueries } from "../../styles/variables";
import { styles } from "./styles";

const useStyles = createUseStyles(styles);

const SeeMoreButton = ({ visible, setVisible }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  return (
    <button className={classes.seeMore} onClick={() => setVisible(!visible)}>
      <div className={classes.valueWrapper}>
        <p className={classes.tagValue}>{visible ? t("see-less") : t("see-more")}</p>
      </div>
    </button>
  );
};

const Tags = () => {
  const { MEATER, MEATER_PLUS, MEATER_BLOCK, MEATER_2_PLUS, MEATER_PRO_XL, MEATER_SE } = PRODUCTS;
  const {
    faqs: { visibleQuestions, activeTags, activeProduct, allTags },
    app: { language },
  } = useSelector((state) => state);

  const notMeater = [
    allTags.MEATER_PLUS && allTags.MEATER_PLUS.slug,
    allTags.MEATER_PLUS_DETAILS && allTags.MEATER_PLUS_DETAILS.slug,
    allTags.MEATER_BLOCK && allTags.MEATER_BLOCK.slug,
    allTags.MEATER_BLOCK_DETAILS && allTags.MEATER_BLOCK_DETAILS.slug,
    allTags.MEATER_2_PLUS && allTags.MEATER_2_PLUS.slug,
    allTags.MEATER_2_PLUS_DETAILS && allTags.MEATER_2_PLUS_DETAILS.slug,
    allTags.MEATER_PRO_XL && allTags.MEATER_PRO_XL.slug,
    allTags.MEATER_PRO_XL_DETAILS && allTags.MEATER_PRO_XL_DETAILS.slug,
  ];

  const notMeaterPlus = [
    allTags.MEATER && allTags.MEATER.slug,
    allTags.MEATER_DETAILS && allTags.MEATER_DETAILS.slug,
    allTags.MEATER_SE && allTags.MEATER_SE.slug,
    allTags.MEATER_SE_DETAILS && allTags.MEATER_SE_DETAILS.slug,
    allTags.MEATER_BLOCK && allTags.MEATER_BLOCK.slug,
    allTags.MEATER_BLOCK_DETAILS && allTags.MEATER_BLOCK_DETAILS.slug,
    allTags.MEATER_2_PLUS && allTags.MEATER_2_PLUS.slug,
    allTags.MEATER_2_PLUS_DETAILS && allTags.MEATER_2_PLUS_DETAILS.slug,
    allTags.MEATER_PRO_XL && allTags.MEATER_PRO_XL.slug,
    allTags.MEATER_PRO_XL_DETAILS && allTags.MEATER_PRO_XL_DETAILS.slug,
  ];

  const notMeaterBlock = [
    allTags.MEATER && allTags.MEATER.slug,
    allTags.MEATER_DETAILS && allTags.MEATER_DETAILS.slug,
    allTags.MEATER_SE && allTags.MEATER_SE.slug,
    allTags.MEATER_SE_DETAILS && allTags.MEATER_SE_DETAILS.slug,
    allTags.MEATER_PLUS && allTags.MEATER_PLUS.slug,
    allTags.MEATER_PLUS_DETAILS && allTags.MEATER_PLUS_DETAILS.slug,
    allTags.MEATER_2_PLUS && allTags.MEATER_2_PLUS.slug,
    allTags.MEATER_2_PLUS_DETAILS && allTags.MEATER_2_PLUS_DETAILS.slug,
    allTags.MEATER_PRO_XL && allTags.MEATER_PRO_XL.slug,
    allTags.MEATER_PRO_XL_DETAILS && allTags.MEATER_PRO_XL_DETAILS.slug,
  ];


  const notMeaterProXl = [
    allTags.MEATER && allTags.MEATER.slug,
    allTags.MEATER_DETAILS && allTags.MEATER_DETAILS.slug,
    allTags.MEATER_SE && allTags.MEATER_SE.slug,
    allTags.MEATER_SE_DETAILS && allTags.MEATER_SE_DETAILS.slug,
    allTags.MEATER_PLUS && allTags.MEATER_PLUS.slug,
    allTags.MEATER_PLUS_DETAILS && allTags.MEATER_PLUS_DETAILS.slug,
    allTags.MEATER_2_PLUS && allTags.MEATER_2_PLUS.slug,
    allTags.MEATER_2_PLUS_DETAILS && allTags.MEATER_2_PLUS_DETAILS.slug,
    allTags.MEATER_BLOCK && allTags.MEATER_BLOCK.slug,
    allTags.MEATER_BLOCK_DETAILS && allTags.MEATER_BLOCK_DETAILS.slug,
  ];


  const notMeater2Plus = [
    allTags.MEATER && allTags.MEATER.slug,
    allTags.MEATER_DETAILS && allTags.MEATER_DETAILS.slug,
    allTags.MEATER_SE && allTags.MEATER_SE.slug,
    allTags.MEATER_SE_DETAILS && allTags.MEATER_SE_DETAILS.slug,
    allTags.MEATER_PLUS && allTags.MEATER_PLUS.slug,
    allTags.MEATER_PLUS_DETAILS && allTags.MEATER_PLUS_DETAILS.slug,
    allTags.MEATER_BLOCK && allTags.MEATER_BLOCK.slug,
    allTags.MEATER_BLOCK_DETAILS && allTags.MEATER_BLOCK_DETAILS.slug,
    allTags.MEATER_PRO_XL && allTags.MEATER_PRO_XL.slug,
    allTags.MEATER_PRO_XL_DETAILS && allTags.MEATER_PRO_XL_DETAILS.slug,
  ];

  const tagsWithQuestions = new Set();
  visibleQuestions.forEach((question) => {
    question.tags.forEach(
      (tag) =>
        ((activeProduct === MEATER && !notMeater.includes(tag)) ||
          (activeProduct === MEATER_SE && !notMeater.includes(tag)) ||
          (activeProduct === MEATER_PLUS && !notMeaterPlus.includes(tag)) ||
          (activeProduct === MEATER_BLOCK && !notMeaterBlock.includes(tag)) ||
          (activeProduct === MEATER_PRO_XL && !notMeaterProXl.includes(tag)) ||
          (activeProduct === MEATER_2_PLUS && !notMeater2Plus.includes(tag))) &&
        tagsWithQuestions.add(allTags[tag.toUpperCase().split("-").join("_")]),
    );
  });

  const descendingOrder = (property) => (a, b) => {
    if (a[property] < b[property]) {
      return 1;
    }
    if (a[property] > b[property]) {
      return -1;
    }
    return 0;
  };

  const tagsList = [...tagsWithQuestions];
  const { t } = useTranslation();
  const [seeMore, setSeeMore] = useState(false);
  const classes = useStyles();
  const included = tagsList.filter((tag) => tag && typeof tag.slug === "string" && activeTags.includes(tag.slug));
  const tags = Array.from(new Set([...included, ...tagsList]));
  tags.sort(descendingOrder("priority"));
  const phone = useMediaQuery(mediaQueries.bootstrap.widePhone);
  const maxInitialTags = phone ? 8 : 4;
  const displayedTags = seeMore ? tags : tags.slice(0, maxInitialTags);

  const generateTags = () =>
    displayedTags.map(({ id, slug, name, DE }) => {
      name = language === "en" ? name : DE.name;
      const active = activeTags.includes(slug);
      const props = { name, slug, active, classes };
      return <Tag key={id} {...props} />;
    });

  return (
    <div>
      <div className={classes.wrapperLabel}>{t("narrow-by-subjects")}</div>
      <div className={classes.tagsWrapper}>
        {generateTags()}
        {tags.length > maxInitialTags && <SeeMoreButton visible={seeMore} setVisible={setSeeMore} />}
      </div>
    </div>
  );
};

export default Tags;
