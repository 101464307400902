import { SET_ZENDESK_FORM_STATE, RESET_ZENDESK_FORM_STATE } from "./types";

const initialState = {
  agentEmail: "",
  contactID: "",
  contactMethod: "",
  priority: "",
  orderMethod: "",
  canBeCalled: "",
  attachmentTokens: [],
  includeSteps: true,
  purchasedFromOther: "",
  purchasedFromRetailer: "",
  name: "",
  firstName: "",
  lastName: "",
  email: "",
  confirmEmail: "",
  phone: "",
  address: "",
  resellerCompanyName: "",
  resellerContactName: "",
  resellerEmail: "",
  resellerPhone: "",
  resellerAddress: "",
  resellerCountry: "",
  resellerCustomerEscalation: false,
  serialNumber: "",
  orderNumber: "",
  country: "",
  message: "",
  contactReason: "",
  companyName: "",
  website: "",
  publicationPlatform: "",
  websiteAccountLink: "",
  deviceOs: "",
  deviceInfo: "",
  deviceAppVersion: "",
  liveChatAvailable: null,
};

export const zendeskFormReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_ZENDESK_FORM_STATE:
      return { ...state, ...payload };
    case RESET_ZENDESK_FORM_STATE:
      return { ...initialState };
    default:
      return state;
  }
};
