export const fontSize = {
  sm: "14px",
  md: "16px",
  mdLg: "18px",
  lg: "20px",
  xlg: "24px",
  subtitle: "1.7rem",
  titleLg: "64px",
  titleMd: "40px",
  titleSm: "32px",
};

export const screenWidth = {
  phoneWidth: "576px",
  tabletWidth: "768px",
  desktopWidth: "992px",
  bigTabletWidth: "824px",
  bigScreenWidth: "1200px",
};

export const colors = {
  black: "#000",
  meaterRed: "rgba(210, 20, 40, 0.75)", // '#D21428', //'#c21028',
  darkGray: "#202020",
  lightBlue: "#dbebf8",
  darkerLightBlue: "#C2D2DF",
  darkBlue: "#377abf", // '#2d65a0',
  white: "#fff",
  lightGray: "#d0d0d0",
  linkBlue: "#5f8ab5",
  anchorBlue: "#007bff",
  anchorHoverBlue: "#0056b3",
  anchorVisitedBlue: "#551A8B",
  darkRed: "#dc3545",
  darkGreen: "#28a745",
  lightDarkGrey: "#767676",
  lightRed: "#CF0A2C",
  tileBorderGrey: "#DBDBDB",
  tileBackgroundGrey: "#F6F6F6",
  buttonGrey: "#555555",
  zenFormInputBackground: "#EBEBEB",
  zenFormText: "rgba(0, 0, 0, 0.6)",
};

const tabletWidth = "767px";
const desktopWidth = "1000px";
const bigScreenWidth = "1366px";

export const mediaQueries = {
  largeDesktopUp: { query: `(min-width: ${bigScreenWidth})` },
  largeTabletUp: { query: `(min-width: ${screenWidth.bigTabletWidth})` },
  desktopUp: { query: `(min-width: ${desktopWidth})` },
  tabletUp: { query: `(min-width: ${tabletWidth})` },
  uptoTablet: { query: `(max-width: ${tabletWidth})` },
  uptoPhone: { query: `(max-width: calc(${screenWidth.phoneWidth} - 1px))` },
  bootstrap: {
    widePhone: { query: `(min-width: ${screenWidth.phoneWidth})` },
    tablet: { query: `(min-width: ${screenWidth.tabletWidth})` },
    desktop: { query: `(min-width: ${screenWidth.desktopWidth})` },
    bigDesktop: { query: `(min-width: ${screenWidth.bigScreenWidth})` },
    none: { query: `(min-width: 1px)` },
  },
};

export const wizardFont = {
  fontFamily: "raleway, sans-serif",
};
