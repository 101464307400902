export const addResellerContactNameToZendeskFormState = (previousZendeskFormState) => {
  return Object.keys(previousZendeskFormState).reduce((newZendeskFormState, key) => {
    newZendeskFormState[key] = previousZendeskFormState[key];

    // add 'resellerContactName' after 'resellerCompanyName'
    if (key === "resellerCompanyName") newZendeskFormState.resellerContactName = "";

    return newZendeskFormState;
  }, {});
};
