import React, { useEffect } from "react";

export const WithOnMountEffect = ({ children, onMount }) => {
  useEffect(() => {
    if (typeof onMount === "function") {
      onMount();
    }
  }, []);

  return <>{children}</>;
};
