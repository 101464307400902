import axios from "axios";
import { useEffect, useState } from "react";

export function useServiceAvailability(service) {
  const [isAvailable, setIsAvailable] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getServiceAvailability = async () => {
      try {
        const response = await axios.get(`/service-availabilities/${service}`);
        const result = response.data;

        setIsAvailable(result.data.available);
      } catch (error) {
        setIsAvailable(false);
        console.log(error);
      } finally {
        setIsLoading(false);
      }
    };

    getServiceAvailability();
  }, []);

  return { isAvailable, isLoading };
}
