import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { bindActionCreators } from "redux";

import { TRAEGER_SUPPORT_ENDPOINTS } from "../config";
import { zendeskActions } from "../redux/zendesk-form/actions";

import { Form, MessageBox } from "./Form";
import { AltContact } from "./AltContact";
import { OrderMethod } from "./OrderMethod";
import { DeviceInfo } from "./DeviceInfo";
import { CountrySelect } from "./CountrySelect";
import { ProductInfo, SerialNumber } from "./ProductInfo";
import { CompanyBlock } from "./CompanyBlock";
import { ResellerContactBlock } from "./ResellerContactBlock";
import { PressInfluencerBlock } from "./PressInfluencerBlock";
import { ContactBlock } from "./ContactBlock";
import { OrderShipmentStatus } from "./OrderShipmentStatus";
import Disclaimer from "./Disclaimer";
import { DisclaimerPressBusiness } from "./DisclaimerPressBusiness";
import { DisclaimerOrderOther } from "./DisclaimerOrderOther";
import { DisclaimerOrderCancellation } from "./DiscalimerOrderCancellation";
import { DisclaimerReseller } from "./DisclaimerReseller";
import { ResellerCustomerEscalationCheckbox } from "./ResellerCustomerEscalationCheckbox";
import { CanBeCalled } from "./CanBeCalled";
import Attachments from "./AttachmentsContainer";
import { Subject, ContactID, IssueResolved, Priority } from "./TraegerBlock";
import { withLogin } from "../HOC/withLogin";
import { OrderCancellation } from "./OrderCancellation";
import { FormWrapper } from "./FormWrapper";
import { FormContainer } from "./FormContainer";
import { MainContainer } from "./MainContainer";
import { FormTitleAndSubtitle } from "./FromTitleAndSubtitle";
import { WithLiveChat } from "./WithLiveChat";
import { ContentContainer } from "../content/ContentContainer";

import "../styles/shared.scss";

export const CONTACT_REASONS = {
  RESELLER: "reseller/wholesale",
  PRESS: "press",
  ORDER_INFO: "order-information",
  SUGGESTIONS: "suggestions",
  SUPPORT: "support",
  OTHER: "other",
};

export const ZendeskForm = ({ children, overrideValues }) => (

    <MainContainer>
      <FormTitleAndSubtitle />
      {children}
      <FormContainer>
        <Form
          requiredStoreKeys={["country"]}
          overrideValues={overrideValues || { contactReason: CONTACT_REASONS.SUPPORT }}
          withLiveChatServiceButton
          withCallBackServiceButton
          hideSubmitButton
        >
          
        </Form>
      </FormContainer>
      <AltContact />
    </MainContainer>

);

const TraegerSupportForm = () => {
  const [issueResolved, setIssueResolved] = useState("");

  return (
    <MainContainer>
      <FormContainer className="pb-48">
        <IssueResolved issueResolved={issueResolved} setIssueResolved={setIssueResolved} />
        {issueResolved === "no" && <Priority />}
        <Form
          overrideValues={{ contactReason: CONTACT_REASONS.SUPPORT }}
          withReCAPTCHA={false}
          issueResolved={issueResolved}
          setIssueResolved={setIssueResolved}
          displayBottomHorizontalLine={false}
        >
          <Subject />
          <ContactBlock
            subtitleKey="customer-details"
            fields={[
              { key: "first-name", required: true },
              { key: "last-name", required: false },
              { key: "customer-email", required: true },
              { key: "confirm-customer-email", required: true },
              { key: "telephone", required: false },
              { key: "shipping-address", required: false },
            ]}
          />
          <CountrySelect requierd={false} />
          <ProductInfo />
          <DeviceInfo />
          <OrderMethod />
          <ContactID />
          <MessageBox labelKey="agent-notes" />
        </Form>
      </FormContainer>
    </MainContainer>
  );
};

export const TraegerSupportFormWithLogin = () => {
  const dispatch = useDispatch();
  const { setState } = bindActionCreators(zendeskActions, dispatch);

  const updateStore = (agentEmail) => setState({ agentEmail });

  const TraegerSupportFormWithLogin = withLogin(TraegerSupportForm, TRAEGER_SUPPORT_ENDPOINTS, updateStore);

  return <TraegerSupportFormWithLogin />;
};

export const DamagedProductForm = () => {
  const { t } = useTranslation();

  return (
      <MainContainer>
        <FormTitleAndSubtitle />
        <FormContainer>
          {/* <Form requiredStoreKeys={["country"]} overrideValues={{ contactReason: CONTACT_REASONS.SUPPORT }} attachmentsRequired> */}
          <Form
            requiredStoreKeys={["country"]}
            overrideValues={{ contactReason: CONTACT_REASONS.SUPPORT }}
            withLiveChatServiceButton
            withCallBackServiceButton
            hideSubmitButton
          >
            
          </Form>
        </FormContainer>
        <AltContact />
      </MainContainer>
  );
};

export const DisclaimerZendeskForm = () => (
    <MainContainer>
      <FormTitleAndSubtitle />
      <Disclaimer />
      <FormContainer>
        <Form
          requiredStoreKeys={["country"]}
          overrideValues={{ contactReason: CONTACT_REASONS.SUPPORT }}
          withLiveChatServiceButton
          withCallBackServiceButton
          hideSubmitButton
        >
        </Form>
      </FormContainer>
      <AltContact />
    </MainContainer>
);

export const TechnicalZendeskForm = () => (
    <MainContainer>
      <FormTitleAndSubtitle />
      <FormContainer>
        <Form
          requiredStoreKeys={["country"]}
          overrideValues={{ contactReason: CONTACT_REASONS.SUPPORT }}
          withLiveChatServiceButton
          withCallBackServiceButton
          hideSubmitButton
        >
        </Form>
      </FormContainer>
      <AltContact />
    </MainContainer>
);

export const OrderShipmentStatusForm = ({ title, subtitle }) => (
  <MainContainer>
    <FormTitleAndSubtitle title={title} subtitle={subtitle} contentCentred={false} />
    <FormContainer>
      <FormWrapper>
        <OrderShipmentStatus />
      </FormWrapper>
    </FormContainer>
    <AltContact />
  </MainContainer>
);

export const OrderCancellationChangesForm = () => (
  <MainContainer>
    <DisclaimerOrderCancellation />
    <FormContainer>
      <FormWrapper>
        <OrderCancellation />
      </FormWrapper>
    </FormContainer>
    <AltContact />
  </MainContainer>
);

export const OrderCheckerUnknownIssueForm = () => {
  const { t } = useTranslation();

  return (
    <ZendeskForm>
      <ContentContainer>
        <p className="content__text">{t("order-checker-unknown-issue")}</p>
      </ContentContainer>
    </ZendeskForm>
  );
};

export const OrderCheckerContactUsForm = () => (
  <ZendeskForm
    overrideValues={{ contactReason: "ORD-OC", topic: "/contact-us/order/order-checker\nStore = Unknown\nUsed order checker = No\n" }}
  />
);

export const CancellationChangesContactUsForm = () => <ZendeskForm overrideValues={{ contactReason: "ORD-Cancellation" }} />;

export const OrderOtherZendeskForm = () => (
  <MainContainer>
    <FormTitleAndSubtitle />
    <DisclaimerOrderOther />
    <FormContainer>
      <Form requiredStoreKeys={["country"]} overrideValues={{ contactReason: CONTACT_REASONS.SUPPORT }} hideSubmitButton>
      </Form>
    </FormContainer>
    <AltContact />
  </MainContainer>
);

export const BusinessZendeskForm = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <DisclaimerPressBusiness title={t("business-enquiries")} />
      <FormContainer>
        <FormWrapper>
          <Form
            requiredStoreKeys={["country", "companyName"]}
            overrideValues={{ contactReason: CONTACT_REASONS.RESELLER }}
            displayBottomHorizontalLine={false}
          >
            <ContactBlock
            // fields={[
            //   { key: "first-name", required: true },
            //   { key: "last-name", required: true },
            //   { key: "email", required: true },
            //   { key: "confirm-email", required: true },
            //   { key: "phone-number", required: true },
            // ]}
            />
            <CompanyBlock />
            <CountrySelect />
            <MessageBox />
            {/* <CanBeCalled /> */}
            <Attachments />
          </Form>
        </FormWrapper>
      </FormContainer>
    </MainContainer>
  );
};

export const PressZendeskForm = () => {
  const { t } = useTranslation();

  return (
    <MainContainer>
      <DisclaimerPressBusiness title={t("press-and-influencers")} />
      <FormContainer>
        <FormWrapper>
          <Form
            overrideValues={{ contactReason: CONTACT_REASONS.PRESS }}
            requiredStoreKeys={["country", "publicationPlatform", "websiteAccountLink"]}
            displayBottomHorizontalLine={false}
          >
            <ContactBlock />
            <PressInfluencerBlock />
            <CountrySelect />
            <MessageBox />
          </Form>
        </FormWrapper>
      </FormContainer>
    </MainContainer>
  );
};

export const ResellerZendeskForm = () => {
  
  const hideAmazonChatWidget = () => {
    const chatWidget = document.getElementById("amazon-connect-chat-widget");
    if (chatWidget) {
      chatWidget.style.display = "none";
    } 
  };

  const showAmazonChatWidget = () => {
    const chatWidget = document.getElementById("amazon-connect-chat-widget");
    if (chatWidget) {
      chatWidget.style.display = "initial";
    } 
  };

  useEffect(() => {
    window.addEventListener("load", hideAmazonChatWidget);
    return () => {
      window.removeEventListener("load", hideAmazonChatWidget);
      showAmazonChatWidget();
    }
  }, []);

  return (
    <MainContainer>
      <DisclaimerReseller />
      <FormContainer>
        <FormWrapper>
          <Form
            overrideValues={{ contactReason: CONTACT_REASONS.SUPPORT }}
            requiredStoreKeys={[
              "resellerCompanyName",
              "resellerContactName",
              "resellerEmail",
              "resellerPhone",
              "resellerAddress",
              "resellerCountry",
              "message",
            ]}
            displayBottomHorizontalLine={false}
          >
            <ResellerContactBlock />
            <SerialNumber />
            <ResellerCustomerEscalationCheckbox />
            <MessageBox />
            <Attachments />
          </Form>
        </FormWrapper>
      </FormContainer>
    </MainContainer>
  );
}
