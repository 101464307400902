import React from "react";
import { useTranslation } from "react-i18next";

import { FormTitleAndSubtitle } from "./FromTitleAndSubtitle";
import { ContentContainer } from "../content/ContentContainer";
import { StrongText } from "../common/StrongText";

export const DisclaimerOrderCancellation = () => {
  const { t } = useTranslation();

  return (
    <>
      <FormTitleAndSubtitle
        title={t("cancellation-changes-title")}
        subtitle={t("cancellation-changes-subtitle-1")}
        contentCentred={false}
      />
      <ContentContainer>
        <p className="content__text">
          <StrongText text={t("cancellation-changes-subtitle-strong")} spaceBefore={false} />
          {t("cancellation-changes-subtitle-2")}
        </p>
      </ContentContainer>
    </>
  );
};
