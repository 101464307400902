import React, { Component } from "react";
import { connect } from "react-redux";

import { FORMS } from "../../../config";
import { COLLECTION_TYPES } from "../../../helpers/Cache";
import { cmsActions } from "../../../redux/cms/actions";
import { findByID, findBySlug, capitalizeFirstLetter } from "../../utils";

import Modal from "../modal/Modal";
import Form from "./Form";

class ChangeLeafForm extends Component {
  static display(id, branch, siblingLeaves, hasChildren) {
    ChangeLeafForm.__singletonRef.__display(id, branch, siblingLeaves, hasChildren);
  }

  constructor(props) {
    super(props);
    this.state = {
      leafCollectionType: "",
      leafID: "",
      branch: "",
      slug: "",
      selectedCollectionType: "unselected",
      selectedOptionIndex: "unselected",
      selectedFormIndex: "unselected",
      title: "",
      siblingLeafSlugs: [],
      options: [],
      collectionTypes: [],
      visible: false,
      successMessage: "",
      errorMessage: "",
    };

    ChangeLeafForm.__singletonRef = this;
  }

  __display = (id, branch, siblingLeaves, hasChildren) => {
    const siblingLeafSlugs = siblingLeaves.map(({ slug }) => slug);
    const leafCollectionType = id.split("/")[1];
    const collectionTypes = this.getAvailableCollectionTypes(leafCollectionType, hasChildren);

    this.setState({
      leafCollectionType,
      leafID: id,
      branch,
      siblingLeafSlugs,
      collectionTypes,
      visible: true,
    });
  };

  __close = ({ target: { className } }) => {
    if (typeof className === "object" || className.split(" ")[0] === "modal") {
      this.setState({ visible: false });
      this.resetForm();
    }
  };

  componentDidUpdate(_, prevState) {
    const { leafCollectionType, leafID, selectedCollectionType, selectedOptionIndex, selectedFormIndex, options, visible } = this.state;
    const { selectedOptionIndex: prevSelectedOptionIndex, selectedFormIndex: prevSelectedFormIndex, visible: prevVisible } = prevState;

    if (options.length > 0 && prevSelectedOptionIndex !== selectedOptionIndex && selectedOptionIndex !== "unselected") {
      let { title, id: leafID } = options[Number(selectedOptionIndex)];
      leafID = `/${selectedCollectionType}/${leafID}`;

      this.setState({ leafID, title });
    }

    if (visible && visible !== prevVisible) {
      if (leafCollectionType !== COLLECTION_TYPES.FORMS) {
        this.fetchLeafData(leafID);
      } else {
        const formID = leafID.substring(1).split("/")[1];
        const form = FORMS[formID];
        this.setState({
          slug: form.slug,
          selectedCollectionType: leafCollectionType,
          selectedFormIndex: formID,
          title: form.title,
        });
      }
    }

    if (selectedFormIndex !== "unselected" && prevSelectedFormIndex !== selectedFormIndex) {
      let leafID = `/${selectedCollectionType}/${selectedFormIndex}`;
      this.setState({
        leafID,
        title: FORMS[selectedFormIndex].title,
      });
    }
  }

  handleChange = ({ target: { name: changedProperty, value: newValue } }) => {
    const { selectedCollectionType, siblingLeafSlugs, successMessage, errorMessage } = this.state;

    if (changedProperty === "slug") {
      this.clearSubmitInputs();
      const alreadyAdded = !!siblingLeafSlugs.find((slug) => slug === newValue);
      if (selectedCollectionType === "answers" && alreadyAdded) {
        this.setState({
          errorMessage: "Can't have two or more children with the same slug",
        });
      }
    }

    if (changedProperty === "selectedCollectionType") {
      this.clearForm();
    }

    !!errorMessage && this.setState({ errorMessage: "" });
    !!successMessage && this.setState({ successMessage: "" });
    this.setState({ [changedProperty]: newValue.trim() }, () => {
      if (changedProperty === "selectedCollectionType") {
        this.meetsConstraints();
      }

      const { selectedCollectionType, selectedFormIndex } = this.state;
      if (selectedCollectionType === COLLECTION_TYPES.FORMS && selectedFormIndex !== "unselected") {
        const slug = FORMS[selectedFormIndex].slug;
        this.setState({ slug });
      }
    });
  };

  handleClick = (e) => {
    e.preventDefault();
    if (this.validateSearchInputs()) {
      this.clearSubmitInputs();
      this.setState({ errorMessage: "" });
      const { slug, selectedCollectionType } = this.state;
      findBySlug(selectedCollectionType, slug)
        .then((options) => {
          this.setState({ options });
        })
        .catch(() => {
          this.setState({
            errorMessage: "No search results. Please make sure the slug is correct.",
          });
        });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { leafID, title, branch, slug, selectedCollectionType, successMessage } = this.state;
    const { changeLeaf } = this.props;
    successMessage && this.setState({ successMessage: "" });

    if (this.validateFormInputs()) {
      changeLeaf(branch, leafID, slug, title);
      this.setState({
        leafCollectionType: selectedCollectionType,
        successMessage: "Successfully changed!",
      });
      this.clearForm();
    }
  };

  getAvailableCollectionTypes = (collectionType, hasChildren) => {
    const { QUESTIONS, ANSWERS, FORMS } = COLLECTION_TYPES;

    if (collectionType === QUESTIONS) {
      if (hasChildren) {
        return [QUESTIONS];
      } else {
        return [QUESTIONS, FORMS];
      }
    }
    if (collectionType === ANSWERS) {
      return [ANSWERS];
    }
    if (collectionType === FORMS) {
      return [QUESTIONS, FORMS];
    }
  };

  fetchLeafData = async (leafID) => {
    const option = await findByID(leafID);
    const { slug, title } = option;
    const collectionType = leafID.substring(1).split("/")[0];

    this.setState({
      slug,
      selectedCollectionType: collectionType,
      selectedOptionIndex: "0",
      title,
      options: [option],
    });
  };

  validateSearchInputs = () => {
    const { slug, selectedCollectionType, errorMessage } = this.state;
    const { FORMS } = COLLECTION_TYPES;

    if (selectedCollectionType === "unselected") {
      this.setState({ errorMessage: "Please select collection type." });
      return false;
    }

    if (!this.meetsConstraints()) {
      return false;
    }

    if (selectedCollectionType !== FORMS) {
      if (!slug) {
        this.setState({ errorMessage: "Please enter a slug." });
        return false;
      }
    }

    if (errorMessage) {
      return false;
    }

    return true;
  };

  validateSubmitInputs = () => {
    const { selectedCollectionType, selectedOptionIndex, selectedFormIndex, options } = this.state;
    const { FORMS } = COLLECTION_TYPES;

    if (selectedCollectionType !== FORMS) {
      if (!options.length) {
        this.setState({
          errorMessage: "No search results. Please make a search before submitting.",
        });
        return false;
      }

      if (selectedOptionIndex === "unselected") {
        this.setState({
          errorMessage: "Please select an option from the search results.",
        });
        return false;
      }
    } else {
      if (selectedFormIndex === "unselected") {
        this.setState({
          errorMessage: "Please select a form.",
        });
        return false;
      }
    }

    return true;
  };

  validateFormInputs = () => this.validateSearchInputs() && this.validateSubmitInputs();

  meetsConstraints = () => {
    const { leafCollectionType, selectedCollectionType } = this.state;
    const { ANSWERS } = COLLECTION_TYPES;

    if (leafCollectionType !== selectedCollectionType) {
      let errorMessage = "";
      if (leafCollectionType === ANSWERS) {
        errorMessage = "Answers can't be replaced with other types.";
        this.setState({
          errorMessage,
        });
        return false;
      } else {
        if (selectedCollectionType === ANSWERS) {
          errorMessage = `${capitalizeFirstLetter(leafCollectionType)} can't be replaced with answers.`;
          this.setState({
            errorMessage,
          });
          return false;
        }
      }
    }

    return true;
  };

  generateOptions = (options) => {
    if (options.length > 0) {
      const defaultOption = (
        <option key="-1" value="unselected" disabled hidden>
          Select an option
        </option>
      );

      const optionComponents = options.map(({ slug }, index) => (
        <option key={index} value={String(index)}>
          {slug}
        </option>
      ));

      return [defaultOption, ...optionComponents];
    } else {
      return null;
    }
  };

  clearSubmitInputs = () => {
    this.setState({
      selectedOptionIndex: "unselected",
      title: "",
      options: [],
    });
  };

  clearForm = () => {
    this.setState({
      leafID: "",
      slug: "",
      selectedOptionIndex: "unselected",
      selectedFormIndex: "unselected",
      title: "",
      options: [],
    });
  };

  resetForm = () => {
    this.setState({
      leafCollectionType: "",
      leafID: "",
      branch: "",
      slug: "",
      selectedCollectionType: "unselected",
      selectedOptionIndex: "unselected",
      selectedFormIndex: "unselected",
      title: "",
      siblingLeafSlugs: [],
      options: [],
      successMessage: "",
      errorMessage: "",
    });
  };

  render() {
    const {
      slug,
      selectedCollectionType,
      selectedOptionIndex,
      selectedFormIndex,
      title,
      options,
      collectionTypes,
      visible,
      successMessage,
      errorMessage,
    } = this.state;

    return (
      <Modal visible={visible} close={this.__close}>
        <Form
          data={{
            formTitle: "CHANGE AN OPTION",
            buttonType: "change",
            buttonText: "Change option",
            handleSubmit: this.handleSubmit,
          }}
        >
          <div className="form__search-container">
            <div className="form__input">
              <label htmlFor="selectedCollectionType">Collection type:</label>
              <select name="selectedCollectionType" value={selectedCollectionType} onChange={this.handleChange}>
                <option key="-1" value="unselected" disabled hidden>
                  Select collection type
                </option>
                {collectionTypes.map((collectionType, index) => (
                  <option key={index} value={collectionType}>
                    {capitalizeFirstLetter(collectionType)}
                  </option>
                ))}
              </select>
            </div>
            {selectedCollectionType !== "forms" ? (
              <div className="form__input-wrapper">
                <div className="form__input">
                  <label htmlFor="slug">Slug:</label>
                  <input name="slug" type="text" value={slug} onChange={this.handleChange} />
                </div>
                <button onClick={this.handleClick}>Search</button>
              </div>
            ) : (
              <div className="form__input">
                <label htmlFor="selectedFormIndex">Form:</label>
                <select name="selectedFormIndex" value={selectedFormIndex} onChange={this.handleChange}>
                  <option key="-1" value="unselected" disabled hidden>
                    Select a form
                  </option>
                  {Object.values(FORMS).map(({ id, slug }) => (
                    <option key={id} value={id}>
                      {slug}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          {selectedCollectionType !== "forms" && (
            <div className="form__input">
              <label htmlFor="selectedOptionIndex">Search results:</label>
              <select name="selectedOptionIndex" value={selectedOptionIndex} onChange={this.handleChange}>
                {this.generateOptions(options)}
              </select>
            </div>
          )}
          <div className="form__input">
            <label htmlFor="title">Title:</label>
            <input name="title" type="text" value={title} onChange={this.handleChange} disabled />
          </div>
          {errorMessage && <span className="form__message form__message--error">{errorMessage}</span>}
          {successMessage && <span className="form__message form__message--success">{successMessage}</span>}
        </Form>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeLeaf: (branch, leafID, slug, title) => dispatch(cmsActions.changeLeaf(branch, leafID, slug, title)),
});

export default connect(null, mapDispatchToProps)(ChangeLeafForm);
