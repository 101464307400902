import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { zendeskActions } from "../redux/zendesk-form/actions";
import { makeStyles } from "@material-ui/core";

import Col from "react-bootstrap/Col";
import { BasicInput } from "./Input";
import { FormSection } from "./FormSection";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";

import { fontSize, colors } from "../styles/variables";
import "../styles/shared.scss";

const useStyles = makeStyles({
  alignCenter: {
    textAlign: "center",
    margin: "auto",
  },
  selectColor: {
    color: colors.zenFormText,
  },
  root: {
    overflow: "hidden",
  },
  input: {
    fontSize: fontSize.lg,
    lineHeight: "normal",
    background: colors.zenFormInputBackground,
  },
  label: {
    fontSize: fontSize.lg,
    width: "calc(100% - 28px)",
  },
  notchedOutline: {
    border: 0,
  },
});

export const DeviceInfo = () => {
  const storeKey = "deviceOs";
  const { t } = useTranslation();
  const deviceOs = useSelector((state) => state.zendeskForm[storeKey]);
  const dispatch = useDispatch();
  const { setState } = bindActionCreators(zendeskActions, dispatch);

  const classes = useStyles();

  return (
    <>
      <FormSection text={t("smart-device")} />
      <Col sm={12}>
        <TextField
          value={deviceOs}
          onChange={(e) => setState({ [storeKey]: e.target.value })}
          inputProps={{ className: classes.input }}
          InputProps={{
            classes: {
              root: classes.root,
              notchedOutline: classes.notchedOutline,
            },
          }}
          variant="outlined"
          fullWidth
          select
          label={t("device-platform")}
          InputLabelProps={{ className: classes.label }}
        >
          <MenuItem value="">{t("device-platform")}</MenuItem>
          <MenuItem value={t("ios")}>{t("ios")}</MenuItem>
          <MenuItem value={t("android")}>{t("android")}</MenuItem>
          <MenuItem value={t("other-device")}>{t("other-device")}</MenuItem>
        </TextField>
      </Col>
      <Col sm={12}>
        <BasicInput storeKey={"deviceInfo"} label={t("device-model")} />
      </Col>
      <Col sm={12}>
        <BasicInput storeKey={"deviceAppVersion"} label={t("meater-app-version")} />
      </Col>
    </>
  );
};
