import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { zendeskActions } from "../redux/zendesk-form/actions";

import Col from "react-bootstrap/Col";
import { BasicInput } from "./Input";
import { FormSection } from "./FormSection";
import { CountrySelect } from "./CountrySelect";

import "../styles/shared.scss";

export const ResellerContactBlock = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { setState } = bindActionCreators(zendeskActions, dispatch);

  const setCustomerEmailAndConfirmEmail = ({ target: { value } }) => {
    setState({ email: value, confirmEmail: value });
  };

  return (
    <>
      <FormSection text={t("reseller-details")} />
      <Col sm={12}>
        <BasicInput storeKey={"resellerCompanyName"} label={t("reseller-company-name")} required />
      </Col>
      <Col sm={12}>
        <BasicInput storeKey={"resellerContactName"} label={t("reseller-contact-name")} required />
      </Col>
      <Col sm={12}>
        <BasicInput type="email" storeKey={"resellerEmail"} label={t("reseller-email")} required placeholder={t("example-email")} />
      </Col>
      <Col sm={12}>
        <BasicInput storeKey={"resellerPhone"} label={t("reseller-telephone")} required />
      </Col>
      <Col sm={12}>
        <BasicInput storeKey={"resellerAddress"} label={t("reseller-address")} required />
      </Col>
      <CountrySelect storeKey="resellerCountry" />

      <FormSection text={t("customer-details")} />
      <Col sm={12}>
        <BasicInput storeKey={"name"} label={t("customer-name")} />
      </Col>
      <Col sm={12}>
        <BasicInput
          type="email"
          storeKey={"email"}
          label={t("customer-email")}
          placeholder={t("example-email")}
          onChange={setCustomerEmailAndConfirmEmail}
        />
      </Col>
      <Col sm={12}>
        <BasicInput storeKey={"phone"} label={t("customer-telephone")} />
      </Col>
      <Col sm={12}>
        <BasicInput storeKey={"address"} label={t("customer-address")} />
      </Col>
    </>
  );
};
