import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import Leaf from "../leaf/Leaf";
import LeafChildren from "../leaf-children/LeafChildren";

import "./leaf-wrapper.styles.scss";

const LeafWrapper = ({ children, expandedBranch, data: { slug, leaves, siblingLeaves, branch, hasChildren, ...rest } }) => {
  const [leafChildren, setLeafChildren] = useState(children);
  const [leafChildrenExpanded, setLeafChildrenExpanded] = useState(false);

  const handleExpandLeafChildren = () => {
    setLeafChildrenExpanded(true);
  };

  const handleCollapseLeafPosterity = () => {
    setLeafChildrenExpanded(false);
  };

  useEffect(() => {
    setLeafChildren(children);
  }, [children]);

  useEffect(() => {
    if (expandedBranch !== "" && branch.indexOf(expandedBranch) === 0) {
      setLeafChildrenExpanded(true);
    }
  }, [expandedBranch]);

  return (
    <div className="leaf-wrapper">
      <Leaf
        data={{
          slug,
          leaves,
          siblingLeaves,
          branch,
          hasChildren,
          leafChildrenExpanded,
          ...rest,
        }}
        expandLeafChildren={handleExpandLeafChildren}
        collapseLeafPosterity={handleCollapseLeafPosterity}
      />
      {leafChildrenExpanded &&
        (!!leafChildren.props ? (
          <LeafChildren leafChildrenExpanded={leafChildrenExpanded}>{leafChildren.props.children}</LeafChildren>
        ) : (
          leafChildren
        ))}
    </div>
  );
};

const mapStateToProps = ({ cms: { expandedBranch } }) => ({
  expandedBranch,
});

export default connect(mapStateToProps)(LeafWrapper);
