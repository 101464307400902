import React, { Component } from "react";
import { connect } from "react-redux";
import axios from "axios";

import Button from "../common/Button";
import Alert from "../common/Alert";
import AddLeafForm from "./forms/AddLeafForm";
import ChangeLeafForm from "./forms/ChangeLeafForm";
import ChangeLeafPositionForm from "./forms/ChangeLeafPositionForm";

import { COLLECTION_TYPES } from "../../helpers/Cache";
import { isEmptyObject, generateSupportWizardTree, fetchDataFromStrapi } from "../utils";
import { cmsActions } from "../../redux/cms/actions";

import "./wizardCMS.styles.scss";

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      supportWizardTree: {},
    };
  }

  async componentDidMount() {
    await this.props.getSupportWizardTree();
    const { supportWizardTree } = this.props;
    this.setState({ supportWizardTree });
  }

  static getDerivedStateFromProps({ supportWizardTree: newSupportWizardTree }, { supportWizardTree: oldSupportWizardTree }) {
    return oldSupportWizardTree !== newSupportWizardTree ? { supportWizardTree: newSupportWizardTree } : null;
  }

  handleUpdateTree = async () => {
    Alert.display("info", "Updating support wizard tree");
    const { supportWizardTree } = this.state;
    const { QUESTIONS, ANSWERS } = COLLECTION_TYPES;
    const questionsData = await fetchDataFromStrapi(QUESTIONS);
    const questionsDataMap = questionsData.reduce((questionsDataMap, questionData) => {
      questionsDataMap[questionData.id] = questionData;
      return questionsDataMap;
    }, {});
    const answersData = await fetchDataFromStrapi(ANSWERS);
    const answersDataMap = answersData.reduce((answersDataMap, answerData) => {
      answersDataMap[answerData.id] = answerData;
      return answersDataMap;
    }, {});
    this.updateTreeRecursively(supportWizardTree.leaves, questionsDataMap, answersDataMap);

    this.updateTree(supportWizardTree)
      .then(({ data: message }) => {
        this.setState({ supportWizardTree });
        Alert.display("success", message);
      })
      .catch(() => {
        Alert.display("error", "Couldn't update support wizard tree");
      });
  };

  updateTree = (data) => axios.put("/cms/wizard/update-tree", data);

  updateTreeRecursively = (siblingLeaves, questionsDataMap, answersDataMap) => {
    if (!siblingLeaves) {
      return;
    }

    siblingLeaves.map((leaf) => {
      const [collectionType, id] = leaf.id.slice(1).split("/");
      let slug, title;
      if (collectionType === COLLECTION_TYPES.QUESTIONS) {
        ({ slug, title } = questionsDataMap[id]);
      }
      if (collectionType === COLLECTION_TYPES.ANSWERS) {
        ({ slug, title } = answersDataMap[id]);
      }
      if (collectionType !== COLLECTION_TYPES.FORMS) {
        leaf.slug = slug;
        leaf.title = title;
      }

      return this.updateTreeRecursively(leaf.leaves, questionsDataMap, answersDataMap);
    });
  };

  render() {
    const { supportWizardTree } = this.state;

    return (
      <div className="wizard-cms-container">
        <AddLeafForm />
        <ChangeLeafForm />
        <ChangeLeafPositionForm />
        <Button text="Update tree" type="update" handleClick={this.handleUpdateTree} />
        <ul className="support-wizard-tree-container">
          <li>{!isEmptyObject(supportWizardTree) && generateSupportWizardTree(supportWizardTree.leaves, false)}</li>
        </ul>
      </div>
    );
  }
}

const mapStateToProps = ({ cms: { supportWizardTree } }) => ({
  supportWizardTree,
});

const mapDispatchToProps = (dispatch) => ({
  getSupportWizardTree: () => dispatch(cmsActions.getSupportWizardTree()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
