import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

import { devMode } from "../config";

import { ZendeskForm } from ".";
import { ButtonAnswer, SlideAnswersContainer } from "../wizard/Answer";
import { ContentContainer } from "../content/ContentContainer";

export const ORDER_METHODS = {
  US: "meater-us",
  UK: "meater-uk",
  EU: "meater-eu",
  AU_NZ: "meater-au-nz",
  CA: "meater-ca",
  DE_AT: "meater-de-at",
  INTL: "meater-intl",
};

export const AfterShipReturnsForms = {
  [ORDER_METHODS.US]: () => (
    <AfterShipReturnsForm src="https://apptionlabs0t.returnscenter.com?mode=compat" orderMethod={ORDER_METHODS.US} />
  ),
  [ORDER_METHODS.UK]: () => <AfterShipReturnsForm src="" orderMethod={ORDER_METHODS.UK} />,
  [ORDER_METHODS.EU]: () => <AfterShipReturnsForm src="" orderMethod={ORDER_METHODS.EU} />,
  [ORDER_METHODS.AU_NZ]: () => <AfterShipReturnsForm src="" orderMethod={ORDER_METHODS.AU_NZ} />,
  [ORDER_METHODS.CA]: () => <AfterShipReturnsForm src="" orderMethod={ORDER_METHODS.CA} />,
  [ORDER_METHODS.DE_AT]: () => <AfterShipReturnsForm src="" orderMethod={ORDER_METHODS.DE_AT} />,
  [ORDER_METHODS.INTL]: () => <AfterShipReturnsForm src="" orderMethod={ORDER_METHODS.INTL} />,
};

const AfterShipReturnsForm = ({ src, orderMethod }) => {
  const { t } = useTranslation();
  const [displayContactForm, setDisplayContactForm] = useState(false);

  const handleButtonClick = () => {
    setDisplayContactForm(true);
  };

  useEffect(() => {
    return () => {
      const event = {
        category: "support-wizard",
        action: "aftership-returns-replacements",
        label: orderMethod,
      };
      !devMode && ReactGA.event(event);
    };
  }, []);

  if (displayContactForm) {
    return <ZendeskForm />;
  }

  return (
    <>
      <iframe id="returnscenter" src={src} title="shopper" width="100%" height="800px"></iframe>

      <ContentContainer contentCentred>
        <p className="content__text">
          {t("after-ship-1")}
          <a href="https://meater.com">meater.com</a>
          {t("after-ship-2")}
        </p>
        <SlideAnswersContainer>
          <ButtonAnswer title={t("order-help")} onClick={handleButtonClick} />
        </SlideAnswersContainer>
      </ContentContainer>
    </>
  );
};
