import React, { useState, useEffect, useCallback } from "react";
import isEqual from "lodash/isEqual";
import ReactGA from "react-ga";
import { useHistory, useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { wizardAnswersSelector, wizardTreeSelector } from "../redux/wizard/selectors";
import { FORMS, wizardPath, basePath, devMode } from "../config";
import { wizardActions, fetchSupportWizardTree } from "../redux/wizard/actions";
import { parseUrl, isNotWizardEndSlug, filterNotWizardEndSlug, stateToQueryString } from "../helpers/urlWiz";
import { Cache, useCache, CACHE, COLLECTION_TYPES } from "../helpers/Cache";
import { getQuestionFromQueryString, getQuestionBefore } from "./utils";

import NotFound from "../pages/NotFound";
import Loading from "../pages/Loading";
import HowCanWeHelp from "./HowCanWeHelp";
import { MultiAnswerSlide } from "./MultiAnswerSlide";
import { WizardSlides } from "./WizardSlides";

import "./styles.scss";

const useUrlState = () => {
  const dispatch = useDispatch();
  const wizardAnswers = useSelector(wizardAnswersSelector);
  const dispatchSetState = (state) => dispatch(wizardActions.setAnswers(state));
  const setState = useCallback(dispatchSetState, []);
  const location = useLocation();
  const currentState = wizardAnswers;
  const parsedState = parseUrl(location.search);
  const { wizardStore } = Cache;
  const { QUESTIONS, ANSWERS } = COLLECTION_TYPES;

  useEffect(() => {
    if (!isEqual(parsedState, currentState)) {
      setState(parsedState);
      const questions = new Set();
      const answers = new Set();
      parsedState.map((slug, index) => {
        if (index % 2 === 0) {
          if (isNotWizardEndSlug(parsedState[index])) {
            questions.add(slug);
          }
        } else {
          if (isNotWizardEndSlug(parsedState[index - 1])) {
            answers.add(slug);
          }
        }
      });

      const parsedStateWithoutWizardEndSlugs = filterNotWizardEndSlug(parsedState);
      Cache.getTitles(parsedStateWithoutWizardEndSlugs).then((titles) => {
        if (titles.includes(false)) {
          const questionsPromise = Cache.loadBySlug([...questions], wizardStore, QUESTIONS);
          const answersPromise = Cache.loadBySlug([...answers], wizardStore, ANSWERS);
          Promise.all([questionsPromise, answersPromise]).then(() =>
            Cache.getTitles(parsedStateWithoutWizardEndSlugs).then((titles) => {
              dispatch(wizardActions.setAnswerTitles(titles));
            }),
          );
        }
        dispatch(wizardActions.setAnswerTitles(titles));
      });
    }
  }, [currentState, parsedState, setState]);
};

export const WizardSlidesAPI = () => {
  useUrlState();
  const { wizardStore } = Cache;
  const { WIZARD_LAST_VISIT } = CACHE;
  const { QUESTIONS } = COLLECTION_TYPES;
  const [slide, setSlide] = useState(<Loading />);
  const [navigationBackClickHandler, setNavigationBackClickHandler] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const queryString = location.search;
  const wizardTree = useSelector(wizardTreeSelector);

  const handleNavigationBackClick = () => {
    if (typeof navigationBackClickHandler === "function") {
      return navigationBackClickHandler();
    }

    navigateToPreviousSlide();
  };

  const navigateToPreviousSlide = () => {
    const { pathname, search } = location;
    const currentState = parseUrl(search);

    const event = {
      category: "support-wizard",
      action: "previous",
      label: `${pathname}${search}`,
    };
    !devMode && ReactGA.event(event);

    if (currentState.length >= 2) {
      if (currentState.length % 2 === 1) {
        // if last query param has only key, but no value
        currentState.pop();
      }

      // remove last query param - a question and an answer
      currentState.pop();
      currentState.pop();
    }

    history.push(stateToQueryString(currentState));
  };

  const navigateToEntrySlide = () => {
    history.push(wizardPath);
  };

  const navigateHome = () => {
    history.push(basePath);
  };

  const getComponentBasedOnLeaf = (targetLeaf, questionBefore) => {
    const [collectionType, id] = targetLeaf.id.substring(1).split("/");

    if (collectionType === QUESTIONS) {
      return !queryString ? (
        <HowCanWeHelp question={targetLeaf} />
      ) : (
        <MultiAnswerSlide question={targetLeaf} questionBefore={questionBefore} />
      );
    }

    const ZendeskForm = FORMS[id].component;
    return <ZendeskForm setNavigationBackClickHandler={setNavigationBackClickHandler} />;
  };

  const handleFreshCache = async () => {
    if (Object.keys(wizardTree).length) {
      const targetLeaf = getQuestionFromQueryString(wizardTree, queryString);

      if (targetLeaf) {
        const questionBefore = getQuestionBefore(wizardTree, queryString);
        setSlide(getComponentBasedOnLeaf(targetLeaf, questionBefore));
      } else {
        setSlide(<NotFound />);
      }
    }
  };

  const handleExpiredOrNoCache = async () => {
    const supportWizardTree = await dispatch(fetchSupportWizardTree());
    const targetLeaf = getQuestionFromQueryString(supportWizardTree, queryString);

    if (targetLeaf) {
      const questionBefore = getQuestionBefore(supportWizardTree, queryString);
      setSlide(getComponentBasedOnLeaf(targetLeaf, questionBefore));
    } else {
      setSlide(<NotFound />);
    }
  };

  useCache(handleFreshCache, handleExpiredOrNoCache, wizardStore, WIZARD_LAST_VISIT, [location.search, wizardTree]);

  return (
    <WizardSlides
      onNavigationBackClick={handleNavigationBackClick}
      onNavigationHomeClick={navigateHome}
      onNavigationRestartClick={navigateToEntrySlide}
    >
      {slide}
    </WizardSlides>
  );
};
