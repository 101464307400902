import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory } from "react-router";
import { useMediaQuery } from "react-responsive";
import { createUseStyles } from "react-jss";

import { faqsPath, PRODUCT_LIST } from "../../config";
import { faqsActions } from "../../redux/faqs/actions";

import Col from "react-bootstrap/Col";
import Product from "./Product";
import CustomSelect from "../../common/CustomSelect";

import { mediaQueries, colors } from "../../styles/variables";
import { styles } from "./styles";

const useStyles = createUseStyles(styles);

const Products = () => {
  const tabletUp = useMediaQuery(mediaQueries.bootstrap.tablet);
  const { t } = useTranslation();
  const { activeProduct, allTopics } = useSelector((state) => state.faqs);
  const dispatch = useDispatch();
  const { setProduct, setTopic } = bindActionCreators(faqsActions, dispatch);
  const history = useHistory();
  const classes = useStyles({ activeProduct });

  const renderProductsList = () =>
    PRODUCT_LIST.map(({ name, image }, index) => {
      const handleClick = () => {
        if (history.location.pathname !== faqsPath) {
          history.push(faqsPath);
        }
        setProduct(name);
        !!allTopics.COMMON_QUESTIONS && setTopic(allTopics.COMMON_QUESTIONS.slug);
      };
      return (
        <li onClick={handleClick} className={classes.productWrapper} key={index}>
          <Product name={name} image={image} />
        </li>
      );
    });

  const tabletRender = () => {
    const productOptions = PRODUCT_LIST.map(({ name }) => ({
      text: t(name),
      value: name,
    }));

    const handleChange = (e) => {
      if (history.location.pathname !== faqsPath) {
        history.push(faqsPath);
      }
      setProduct(e.target.value);
      !!allTopics.COMMON_QUESTIONS && setTopic(allTopics.COMMON_QUESTIONS.slug);
    };

    return <CustomSelect text={t("product")} name={"product"} value={activeProduct} options={productOptions} handleChange={handleChange} />;
  };

  return tabletUp ? (
    <Col lg={{ span: 10, offset: 1 }}>
      <ul className={classes.productListWrapper}>{renderProductsList()}</ul>
    </Col>
  ) : (
    <Col xs={12}>{tabletRender()}</Col>
  );
};

export default Products;
