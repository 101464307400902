import { SHOW_MOBILE, SHOW_LOGIN } from "./types";

const initialState = { isSideOpen: false, isLoginOpen: false };

export const navbarReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_MOBILE:
      return { ...state, isSideOpen: payload };
    case SHOW_LOGIN:
      return { ...state, isLoginOpen: payload };
    default:
      return state;
  }
};
