import React, { useState } from "react";

import { MultiAnswerSlide } from "../wizard/MultiAnswerSlide";
import { ReviewOrderButton } from "./ReviewOrderButton";
import { ContactSupportButton } from "./ContactSupportButton";
import { RequestReshipmentButton } from "./RequestReshipmentButton";
import Loading from "../pages/Loading";

export const PotentialShipmentDelay = ({ question }) => {
  const [processingReshipmentRequest, setProcessingReshipmentRequest] = useState(false);

  return processingReshipmentRequest ? (
    <Loading />
  ) : (
    <MultiAnswerSlide question={question}>
      {(answersData) => (
        <>
          <RequestReshipmentButton
            answerData={answersData[0]}
            setProcessingReshipmentRequest={() => setProcessingReshipmentRequest(true)}
          />
          <ReviewOrderButton answerData={answersData[1]} />
          <ContactSupportButton answerData={answersData[2]} />
        </>
      )}
    </MultiAnswerSlide>
  );
};
