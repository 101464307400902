import React from "react";
import FAQ from "./FAQ";

const FAQList = ({ faqs }) => (
  <div className="content__faq-list">
    {faqs.map(({ faqRef, embedded }, index) => (
      <FAQ key={index} data={faqRef} embedded={embedded} />
    ))}
  </div>
);

export default FAQList;
