import React from "react";
import { useTranslation } from "react-i18next";

import { orderCheckerPath, cancellationChangesPath } from "../config";

import { ContentContainer } from "../content/ContentContainer";
import { ButtonAsAnchor } from "../common/ButtonAsAnchor";
import { StrongText } from "../common/StrongText";

export const DisclaimerOrderOther = () => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <p className="content__text">
        {t("order-other-disclaimer-1")}
        <ButtonAsAnchor text={t("order-other-disclaimer-link-1")} location={orderCheckerPath} />
        {t("order-other-disclaimer-2")}
        <ButtonAsAnchor text={t("order-other-disclaimer-link-2")} location={cancellationChangesPath} spaceAfter={false} />
        {t("order-other-disclaimer-3")}
      </p>
      <p className="content__text">
        <StrongText text={t("order-other-disclaimer-strong")} spaceBefore={false} />
        {t("order-other-disclaimer-4")}
      </p>
    </ContentContainer>
  );
};
