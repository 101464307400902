import React from "react";
import { Switch, Route } from "react-router-dom";

import {
  contactUsPath,
  businessContactUs,
  influencerContactUs,
  technicalContactUs,
  zendeskContactUs,
  orderCheckerContactUs,
  cancellationChangesContactUs,
} from "../config";

import { Home } from "./Home";
import {
  BusinessZendeskForm,
  PressZendeskForm,
  TechnicalZendeskForm,
  DisclaimerZendeskForm,
  OrderCheckerContactUsForm,
  CancellationChangesContactUsForm,
} from "../zendesk-form";
import NotFound from "../pages/NotFound";

export const ContactUs = () => (
  <Switch>
    <Route exact path={contactUsPath} component={Home} />
    <Route exact path={businessContactUs} component={BusinessZendeskForm} />
    <Route exact path={influencerContactUs} component={PressZendeskForm} />
    <Route exact path={zendeskContactUs} component={DisclaimerZendeskForm} />
    <Route exact path={technicalContactUs} component={TechnicalZendeskForm} />
    <Route exact path={orderCheckerContactUs} component={OrderCheckerContactUsForm} />
    <Route exact path={cancellationChangesContactUs} component={CancellationChangesContactUsForm} />
    <Route component={NotFound} />
  </Switch>
);
