import React from "react";

import { Col } from "react-bootstrap";

export const FormSection = ({ text, children }) => (
  <Col xs={12}>
    <p className="zendesk-subtitle">{text}</p>
    {children}
  </Col>
);
