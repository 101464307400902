import React from "react";

import "./styles.scss";

const Unauthorized = ({ setAuthorized }) => {
  const handleClick = () => {
    setAuthorized(2);
  };

  return (
    <div className="page">
      <h1 className="page__title">Unauthorized</h1>
      <p className="page__subtitle">Access is denied due to invalid credentials.</p>
      <button className="page__button" onClick={handleClick}>
        Try again
      </button>
    </div>
  );
};

export default Unauthorized;
