import React from "react";

import facebookIcon from "../../assets/social/white-circle-facebook.svg";
import instagramIcon from "../../assets/social/white-circle-instagram.svg";
import youtubeIcon from "../../assets/social/white-circle-youtube.svg";
import pinterestIcon from "../../assets/social/white-circle-pinterest.svg";
import twitterIcon from "../../assets/social/white-circle-twitter.svg";

import "./styles.scss";

export const SocialRow = () => {
  const socials = [
    {
      src: facebookIcon,
      alt: "Facebook icon",
      href: "https://www.facebook.com/meatermade",
      label: "MEATER on Facebook"
    },
    {
      src: instagramIcon,
      alt: "Instagram icon",
      href: "https://www.instagram.com/meatermade",
      label: "MEATER on Instagram"
    },
    {
      src: youtubeIcon,
      alt: "YouTube icon",
      href: "https://www.youtube.com/c/meater",
      label: "MEATER on YouTube"
    },
    {
      src: pinterestIcon,
      alt: "Pinterest icon",
      href: "https://www.pinterest.com/meatermade/",
      label: "MEATER on Pinterest"
    },
    {
      src: twitterIcon,
      alt: "Twitter icon",
      href: "https://twitter.com/MEATERmade",
      label: "MEATER on Twitter"
    },
  ];

  const Social = ({ src, alt, href, label }) => (
    <li className="social-row__item">
      <a href={href} className="social-row__link" aria-label={`Visit ${label}`}>
        <img className="social-row__image" src={src} height="32" width="32" alt={alt} />
        <span className="sr-only">{label}</span>
      </a>
    </li>
  );

  return (
    <ul className="social-row">
      {socials.map((props, index) => (
        <Social key={index} {...props} />
      ))}
    </ul>
  );
};
