import React, { useState, useEffect } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";

import { useUrlUpdater } from "../helpers/urlWiz";
import { Cache, useCache, CACHE, COLLECTION_TYPES } from "../helpers/Cache";
import { getContentByLanguage, getSlugs, mergeCachedAndUncachedElements, orderBySlug } from "../helpers/Cache/utils";
import { getQuestionFromQueryString } from "./utils";
import { fetchSupportWizardTree } from "../redux/wizard/actions";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Content from "../content";
import LanguageSelector from "../common/LanguageSelector";
import LanguageSwitcher from "../common/LanguageSwitcher";

import "./styles.scss";

export const HowCanWeHelp = ({ question }) => {
  const { wizardStore } = Cache;
  const { WIZARD_LAST_VISIT } = CACHE;
  const { QUESTIONS, ANSWERS } = COLLECTION_TYPES;
  const language = useSelector((state) => state.app.language);

  const dispatch = useDispatch();
  const location = useLocation();
  const queryString = location.search;

  const [questionData, setQuestionData] = useState(null);
  const [answersData, setAnswersData] = useState([]);
  const answers = question.leaves;
  const hasAnswers = !!answers; // has answers means it's not terminal question / form

  const handleFreshCache = async () => {
    const uncachedQuestions = await Cache.check(wizardStore, [question]);

    if (uncachedQuestions) {
      const [question] = await Cache.load(wizardStore, uncachedQuestions);
      setQuestionData(getContentByLanguage(question, QUESTIONS, language));
    } else {
      const [cachedQuestion] = await Cache.get(wizardStore, [question]);
      setQuestionData(getContentByLanguage(cachedQuestion, QUESTIONS, language));
    }

    if (hasAnswers) {
      const answerSlugs = getSlugs(answers);
      const uncachedAnswers = await Cache.check(wizardStore, answers);

      if (uncachedAnswers) {
        const cachedAnswers = await Cache.get(wizardStore, answers);
        const strapiAnswers = await Cache.load(wizardStore, uncachedAnswers);
        const orderedAnswers = orderBySlug(answerSlugs, strapiAnswers);

        setAnswersData(getContentByLanguage(mergeCachedAndUncachedElements(answerSlugs, orderedAnswers, cachedAnswers), ANSWERS, language));
      } else {
        const cachedAnswers = await Cache.get(wizardStore, answers);
        setAnswersData(getContentByLanguage(cachedAnswers, ANSWERS, language));
      }
    }
  };

  const handleExpiredOrNoCache = async () => {
    const supportWizardTree = await dispatch(fetchSupportWizardTree());
    const question = getQuestionFromQueryString(supportWizardTree, queryString);

    if (question) {
      const answers = question.leaves;
      const answerSlugs = getSlugs(answers);

      const [question] = await Cache.load(wizardStore, [question]);
      setQuestionData(getContentByLanguage(question, QUESTIONS, language));

      if (hasAnswers) {
        const answers = await Cache.load(wizardStore, answers);
        const orderedAnswers = orderBySlug(answerSlugs, answers);
        setAnswersData(getContentByLanguage(orderedAnswers, ANSWERS, language));
      }
    }
  };

  useCache(handleFreshCache, handleExpiredOrNoCache, wizardStore, WIZARD_LAST_VISIT, [language]);

  useEffect(() => {
    questionData && hasAnswers && Cache.preload(wizardStore, answers);
  }, [questionData]);

  return (
    <>
      {/* <LanguageSelector /> */}
      {/* <LanguageSwitcher /> */}
      <div className="wizard__slide--entry">
        {questionData && <Content data={questionData} />}
        {answersData && (
          <Row className="slide-answers-container--entry">
            {answersData.map((answerData, index) => (
              <Answer key={index} answerData={answerData} questionSlug={question.slug} />
            ))}
          </Row>
        )}
      </div>
    </>
  );
};

const Answer = ({ answerData, questionSlug }) => {
  const { slug: answerSlug, title, image } = answerData;
  const updateUrl = useUrlUpdater(questionSlug);

  return (
    <Col className="slide-answer-col-wrapper" sm={6} xs={12}>
      <div className="slide-answer-wrapper">
        <div className="slide-answer" onClick={() => updateUrl(answerSlug)}>
          <img className="slide-answer__image" src={image} alt={title} />
          <p className="slide-answer__title">{title}</p>
        </div>
      </div>
    </Col>
  );
};

export default HowCanWeHelp;
