import React from "react";
import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { SHOPS } from "../config.js";
import { navbarActions } from "../redux/navbar/actions.js";

import clostIcon from "../assets/close-hamburger.svg";

const AccountPopover = () => {
  const dispatch = useDispatch();
  const { showLoginChooser } = bindActionCreators(navbarActions, dispatch);
  const hideModal = () => showLoginChooser(false);

  const FlagWrapper = ({ icon, href, alt, text }) => (
    <a href={href} className="shop-modal__img-wrapper">
      <img className="shop-modal__img" src={icon} alt={alt} />
      <p className="shop-modal__img-label">{text}</p>
    </a>
  );

  const generateShops = () =>
    SHOPS.map((shopProps, index) => (
      <li className="shop-modal__list-item" key={index}>
        <FlagWrapper {...shopProps} />
      </li>
    ));

  return (
    <div className="shop-modal-wrapper" id="my-account-popover" onClick={hideModal}>
      <div className="shop-modal">
        <img
          className="shop-modal__close-icon"
          src={clostIcon}
          id="close-popover-button"
          alt="Close and continue shopping"
          onClick={hideModal}
        />
        <p className="shop-modal__body">
          To login and view your previous orders, please select the MEATER store you used to purchase your product:
        </p>
        <ul className="shop-modal__shop-list">{generateShops()}</ul>
      </div>
    </div>
  );
};

export default AccountPopover;
