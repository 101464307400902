import { filterNotWizardEndSlug } from "../helpers/urlWiz";

const queryStringToQuestionSlugs = (queryString) => filterNotWizardEndSlug(queryString.slice(1).split("&").join("=").split("="));

const findQuestionAfterSlugs = (questionSlugs, supportWizardTree) => {
  const startedWizard = !!questionSlugs[0];
  const entrySlide = supportWizardTree.leaves[0];

  if (startedWizard) {
    const questionCorrespondingToLastAnswer = questionSlugs.reduce(
      (supportWizardTree, questionSlug) =>
        !!supportWizardTree && supportWizardTree.leaves.find((question) => question.slug === questionSlug),
      supportWizardTree,
    );
    const nextQuestion =
      !!questionCorrespondingToLastAnswer && !!questionCorrespondingToLastAnswer.leaves && questionCorrespondingToLastAnswer.leaves[0];

    return nextQuestion;
  }

  return entrySlide;
};

export const findQuestionBySlug = (slug, leaves) => {
  const targetQuestion = leaves.find((question) => question.slug === slug);

  if (targetQuestion === undefined) {
    const nextLeaves = leaves.reduce((accumulator, question) => {
      !!question.leaves && accumulator.push(...question.leaves);
      return accumulator;
    }, []);
    return findQuestionBySlug(slug, nextLeaves);
  }

  return targetQuestion;
};

export const getQuestionBefore = (supportWizardTree, queryString) => {
  const questionSlugs = queryStringToQuestionSlugs(queryString);
  questionSlugs.pop();
  questionSlugs.pop();
  return findQuestionAfterSlugs(questionSlugs, supportWizardTree);
};

export const getQuestionFromQueryString = (supportWizardTree, queryString) => {
  const questionSlugs = queryStringToQuestionSlugs(queryString);
  return findQuestionAfterSlugs(questionSlugs, supportWizardTree);
};
