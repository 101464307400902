import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import axios from "axios";

import { cancellationChangesContactUs, ORDER_CHECKER_ENDPOINTS } from "../config";
import { zendeskFormSelector } from "../redux/zendesk-form/selector";

import OrderDetails from "./OrderDetailsContainer";
import { OrderStatusInformation } from "./OrderStatusInformation";
import { OrderNumberTip } from "./OrderNumberTip";

export const OrderCancellation = () => {
  const { t } = useTranslation();
  const [orderStatusUrl, setOrderStatusUrl] = useState("");
  const { orderNumber, email } = useSelector(zendeskFormSelector);

  const cancelOrder = async (recaptcha) => {
    const { data } = await axios.post(ORDER_CHECKER_ENDPOINTS.STATUS, { orderNumber, email, recaptcha });

    if (data.orderStatusUrl) {
      setOrderStatusUrl(data.orderStatusUrl);
    }
  };

  return (
    <>
      {orderStatusUrl ? (
        <OrderStatusInformation orderStatusUrl={orderStatusUrl} orderStatusButtonText={t("review-order")} />
      ) : (
        <OrderDetails onSubmit={cancelOrder} />
      )}
      <OrderNumberTip location={cancellationChangesContactUs} />
    </>
  );
};
