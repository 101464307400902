import React from "react";

import "./styles.scss";

const Button = ({ text, type, handleClick }) => (
  <button className={`button button--${type}`} onClick={handleClick}>
    {text}
  </button>
);

export default Button;
