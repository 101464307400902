import {
  SET_ACTIVE_TAGS,
  SET_ACTIVE_PRODUCT,
  SET_ACTIVE_TOPIC,
  ADD_VISIBLE_QUESTION,
  REMOVE_VISIBLE_QUESTION,
  SET_SEARCH_VALUE,
  SET_ALL_QUESTIONS,
  SET_ALL_TOPICS,
  SET_ALL_TAGS,
  SET_FAQ_STATE,
  SET_QUESTION,
  RESET_FAQS,
} from "./types";
import { PRODUCTS } from "../../config";

const initialState = {
  activeTags: [],
  activeProduct: PRODUCTS.MEATER_PLUS,
  activeTopic: "",
  visibleQuestions: [],
  allQuestions: [],
  allTopics: {},
  allTags: {},
  search: "",
};

export const faqsReducer = (state = initialState, { type, payload }) => {
  const { activeTags } = state;

  switch (type) {
    case SET_ACTIVE_TAGS:
      let newTags = [...activeTags];
      payload.forEach((tagToCheck) => {
        newTags = activeTags.includes(tagToCheck) ? activeTags.filter((item) => item !== tagToCheck) : [...newTags, tagToCheck];
      });
      return { ...state, activeTags: newTags };
    case SET_ACTIVE_PRODUCT:
      return {
        ...state,
        activeProduct: payload,
        activeTags: [],
        activeTopic: state.allTopics.COMMON_QUESTIONS,
        search: "",
      };
    case SET_ACTIVE_TOPIC:
      return { ...state, activeTopic: payload };
    case ADD_VISIBLE_QUESTION:
      const visibleQuestions = [...state.visibleQuestions.filter((q) => q.slug !== payload.slug), ...payload];
      return { ...state, visibleQuestions };
    case REMOVE_VISIBLE_QUESTION:
      return {
        ...state,
        visibleQuestions: state.visibleQuestions.filter((question) => question.slug !== payload.slug),
      };
    case SET_SEARCH_VALUE:
      return { ...state, search: payload };
    case SET_ALL_QUESTIONS:
      return { ...state, allQuestions: payload };
    case SET_ALL_TOPICS:
      return {
        ...state,
        allTopics: payload,
      };
    case SET_ALL_TAGS:
      return { ...state, allTags: payload };
    case SET_FAQ_STATE:
      return { ...state, ...payload };
    case SET_QUESTION:
      return { ...state, visibleQuestions: payload };
    case RESET_FAQS:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
