import React, { Component } from "react";
import { connect } from "react-redux";

import { FORMS } from "../../../config";
import { COLLECTION_TYPES } from "../../../helpers/Cache";
import { cmsActions } from "../../../redux/cms/actions";
import { findBySlug, capitalizeFirstLetter } from "../../utils";

import Modal from "../modal/Modal";
import Form from "./Form";

class AddLeafForm extends Component {
  static display(id, branch, leaves) {
    AddLeafForm.__singletonRef.__display(id, branch, leaves);
  }

  constructor(props) {
    super(props);
    this.state = {
      leafCollectionType: "",
      leafChildrenCollectionType: "",
      childrenCount: 0,
      siblingLeafSlugs: [],
      leafChildID: "",
      branch: "",
      slug: "",
      selectedCollectionType: "unselected",
      selectedOptionIndex: "unselected",
      selectedFormIndex: "unselected",
      title: "",
      options: [],
      collectionTypes: [COLLECTION_TYPES.QUESTIONS, COLLECTION_TYPES.ANSWERS, COLLECTION_TYPES.FORMS],
      visible: false,
      successMessage: "",
      errorMessage: "",
    };

    this.collectionTypeConstraintsAreMet = {
      questions: this.meetsQuestionConstraints,
      answers: this.meetsAnswerConstraints,
      forms: this.meetsFormConstraints,
    };

    AddLeafForm.__singletonRef = this;
  }

  __display = (id, branch, leaves) => {
    const siblingLeafSlugs = !!leaves ? leaves.map(({ slug }) => slug) : [];
    const childrenCount = !!leaves ? leaves.length : 0;
    const leafCollectionType = id.split("/")[1];
    const leafChildrenCollectionType = !!leaves ? leaves[0].id.split("/")[1] : "";
    const collectionTypes = this.getAvailableCollectionTypes(leafCollectionType);
    const limit = {
      answers: 1,
      forms: 0,
    };
    const childrenCountLimit = limit[leafCollectionType];

    const errorMessage =
      childrenCount === childrenCountLimit
        ? `${capitalizeFirstLetter(leafCollectionType)} can't have ${childrenCountLimit > 0 ? "more than" : ""} ${
            childrenCountLimit !== 0 ? childrenCountLimit : ""
          } ${childrenCountLimit !== 1 ? "children" : "child"}.`
        : "";

    if (collectionTypes.length === 1) {
      this.setState({ selectedCollectionType: collectionTypes[0] });
    }

    this.setState({
      leafCollectionType,
      leafChildrenCollectionType,
      childrenCount,
      siblingLeafSlugs,
      branch,
      collectionTypes,
      visible: true,
      errorMessage,
    });
  };

  __close = ({ target: { className } }) => {
    if (typeof className === "object" || className.split(" ")[0] === "modal") {
      this.setState({ visible: false });
      this.resetForm();
    }
  };

  componentDidUpdate(_, prevState) {
    const { selectedOptionIndex, selectedFormIndex, options } = this.state;
    const { selectedOptionIndex: prevSelectedOptionIndex, selectedFormIndex: prevSelectedFormIndex, selectedCollectionType } = prevState;

    if (options.length > 0 && prevSelectedOptionIndex !== selectedOptionIndex) {
      let { title, id: leafChildID } = options[Number(selectedOptionIndex)];
      leafChildID = `/${selectedCollectionType}/${leafChildID}`;

      this.setState({ leafChildID, title });
    }

    if (selectedFormIndex !== "unselected" && prevSelectedFormIndex !== selectedFormIndex) {
      let leafChildID = `/${selectedCollectionType}/${selectedFormIndex}`;
      this.setState({
        leafChildID,
        title: FORMS[selectedFormIndex].title,
      });
    }
  }

  handleChange = ({ target: { name: changedProperty, value: newValue } }) => {
    const { leafCollectionType, selectedCollectionType, siblingLeafSlugs, successMessage, errorMessage } = this.state;

    if (changedProperty === "slug") {
      this.clearSubmitInputs();
      const alreadyAdded = !!siblingLeafSlugs.find((slug) => slug === newValue);
      if (selectedCollectionType === "answers" && alreadyAdded) {
        this.setState({
          errorMessage: "Can't have two or more answers with the same slug",
        });
      }
    }

    if (changedProperty === "selectedCollectionType") {
      this.clearForm();
    }

    !!errorMessage && this.setState({ errorMessage: "" });
    !!successMessage && this.setState({ successMessage: "" });
    this.setState({ [changedProperty]: newValue.trim() }, () => {
      if (changedProperty === "selectedCollectionType") {
        this.collectionTypeConstraintsAreMet[leafCollectionType]();
      }

      const { selectedCollectionType, selectedFormIndex } = this.state;
      if (selectedCollectionType === "forms" && selectedFormIndex !== "unselected") {
        const slug = FORMS[selectedFormIndex].slug;
        this.setState({ slug });
      }
    });
  };

  handleClick = (e) => {
    e.preventDefault();
    if (this.validateSearchInputs()) {
      this.clearSubmitInputs();
      this.setState({ errorMessage: "" });
      const { slug, selectedCollectionType } = this.state;
      findBySlug(selectedCollectionType, slug)
        .then((options) => {
          this.setState({ options });
        })
        .catch(() => {
          this.setState({
            errorMessage: "No search results. Please make sure the slug is correct.",
          });
        });
    }
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { childrenCount, siblingLeafSlugs, leafChildID, title, branch, slug, successMessage } = this.state;
    const { addLeaf } = this.props;
    successMessage && this.setState({ successMessage: "" });

    if (this.validateFormInputs()) {
      addLeaf(branch, leafChildID, slug, title);
      siblingLeafSlugs.push(slug);
      this.setState({
        childrenCount: childrenCount + 1,
        siblingLeafSlugs,
        successMessage: "Successfully added!",
      });
      this.clearForm();
    }
  };

  getAvailableCollectionTypes = (collectionType) => {
    const { QUESTIONS, ANSWERS, FORMS } = COLLECTION_TYPES;

    if (collectionType === QUESTIONS) {
      return [ANSWERS];
    }

    if (collectionType === ANSWERS) {
      return [QUESTIONS, FORMS];
    }
  };

  validateSearchInputs = () => {
    const { leafCollectionType, slug, selectedCollectionType, errorMessage } = this.state;

    if (selectedCollectionType === "unselected") {
      this.setState({ errorMessage: "Please select collection type." });
      return false;
    }

    if (selectedCollectionType !== "forms") {
      if (!this.collectionTypeConstraintsAreMet[leafCollectionType]()) {
        return false;
      }

      if (!slug) {
        this.setState({ errorMessage: "Please enter a slug." });
        return false;
      }
    }

    if (errorMessage) {
      return false;
    }

    return true;
  };

  validateSubmitInputs = () => {
    const { selectedCollectionType, selectedOptionIndex, selectedFormIndex, options } = this.state;

    if (selectedCollectionType !== "forms") {
      if (!options.length) {
        this.setState({
          errorMessage: "No search results. Please make a search before submitting.",
        });
        return false;
      }

      if (selectedOptionIndex === "unselected") {
        this.setState({
          errorMessage: "Please select an option from the search results.",
        });
        return false;
      }
    } else {
      if (selectedFormIndex === "unselected") {
        this.setState({
          errorMessage: "Please select a form.",
        });
        return false;
      }
    }

    return true;
  };

  validateFormInputs = () => this.validateSearchInputs() && this.validateSubmitInputs();

  meetsQuestionConstraints = () => {
    const { selectedCollectionType } = this.state;

    if (selectedCollectionType !== "answers") {
      this.setState({
        errorMessage: "Questions can have only answers as children.",
      });
      return false;
    }

    return true;
  };

  meetsAnswerConstraints = () => {
    const { selectedCollectionType, childrenCount } = this.state;

    if (selectedCollectionType === "answers") {
      this.setState({
        errorMessage: "Answers can't have answers as children.",
      });
      return false;
    }

    if (childrenCount === 1) {
      this.setState({
        errorMessage: "Answers can't have more than 1 child.",
      });
      return false;
    }

    return true;
  };

  meetsFormConstraints = () => {
    const { childrenCount } = this.state;

    if (childrenCount === 0) {
      this.setState({ errorMessage: "Forms can't have children." });
      return false;
    }

    return true;
  };

  generateOptions = (options) => {
    if (options.length > 0) {
      const defaultOption = (
        <option key="-1" value="unselected" disabled hidden>
          Select an option
        </option>
      );

      const optionComponents = options.map(({ slug }, index) => (
        <option key={index} value={String(index)}>
          {slug}
        </option>
      ));

      return [defaultOption, ...optionComponents];
    } else {
      return null;
    }
  };

  clearSubmitInputs = () => {
    this.setState({
      selectedOptionIndex: "unselected",
      title: "",
      options: [],
    });
  };

  clearForm = () => {
    this.setState({
      leafChildID: "",
      slug: "",
      selectedOptionIndex: "unselected",
      selectedFormIndex: "unselected",
      title: "",
      options: [],
    });
  };

  resetForm = () => {
    this.setState({
      leafCollectionType: "",
      leafChildrenCollectionType: "",
      childrenCount: 0,
      siblingLeafSlugs: [],
      leafChildID: "",
      slug: "",
      selectedCollectionType: "unselected",
      selectedOptionIndex: "unselected",
      selectedFormIndex: "unselected",
      title: "",
      branch: "",
      options: [],
      successMessage: "",
      errorMessage: "",
    });
  };

  render() {
    const {
      slug,
      selectedCollectionType,
      selectedOptionIndex,
      selectedFormIndex,
      title,
      options,
      collectionTypes,
      visible,
      successMessage,
      errorMessage,
    } = this.state;

    return (
      <Modal visible={visible} close={this.__close}>
        <Form
          data={{
            formTitle: "ADD AN OPTION",
            buttonType: "add",
            buttonText: "Add option",
            handleSubmit: this.handleSubmit,
          }}
        >
          <div className="form__search-container">
            <div className="form__input">
              <label htmlFor="selectedCollectionType">Collection type:</label>
              <select name="selectedCollectionType" value={selectedCollectionType} onChange={this.handleChange}>
                <option key="-1" value="unselected" disabled hidden>
                  Select collection type
                </option>
                {collectionTypes.map((collectionType, index) => (
                  <option key={index} value={collectionType}>
                    {capitalizeFirstLetter(collectionType)}
                  </option>
                ))}
              </select>
            </div>
            {selectedCollectionType !== "forms" ? (
              <div className="form__input-wrapper">
                <div className="form__input">
                  <label htmlFor="slug">Slug:</label>
                  <input name="slug" type="text" value={slug} onChange={this.handleChange} />
                </div>
                <button onClick={this.handleClick}>Search</button>
              </div>
            ) : (
              <div className="form__input">
                <label htmlFor="selectedFormIndex">Form:</label>
                <select name="selectedFormIndex" value={selectedFormIndex} onChange={this.handleChange}>
                  <option key="-1" value="unselected" disabled hidden>
                    Select a form
                  </option>
                  {Object.values(FORMS).map(({ id, slug }) => (
                    <option key={id} value={id}>
                      {slug}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          {selectedCollectionType !== "forms" && (
            <div className="form__input">
              <label htmlFor="selectedOptionIndex">Search results:</label>
              <select name="selectedOptionIndex" value={selectedOptionIndex} onChange={this.handleChange}>
                {this.generateOptions(options)}
              </select>
            </div>
          )}
          <div className="form__input">
            <label htmlFor="title">Title:</label>
            <input name="title" type="text" value={title} onChange={this.handleChange} disabled />
          </div>
          {errorMessage && <span className="form__message form__message--error">{errorMessage}</span>}
          {successMessage && <span className="form__message form__message--success">{successMessage}</span>}
        </Form>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  addLeaf: (branch, leafChildID, slug, title) => dispatch(cmsActions.addLeaf(branch, leafChildID, slug, title)),
});

export default connect(null, mapDispatchToProps)(AddLeafForm);
