import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { SLUGS } from "../config";
import { Cache, useCache, CACHE } from "../helpers/Cache";
import { wizardTreeSelector } from "../redux/wizard/selectors";
import { fetchSupportWizardTree } from "../redux/wizard/actions";
import { findQuestionBySlug } from "../wizard/utils";

import { MultiAnswerSlide } from "../wizard/MultiAnswerSlide";
import Loading from "./Loading";
import NotFound from "./NotFound";

import "./styles.scss";

export const HappyCooking = () => {
  const { WIZARD_LAST_VISIT } = CACHE;
  const { wizardStore } = Cache;
  const [question, setQuestion] = useState(null);
  const dispatch = useDispatch();

  const language = useSelector((state) => state.app.language);
  const cachedSupportWizardTree = useSelector(wizardTreeSelector);

  const handleFreshCache = async () => {
    if (Object.keys(cachedSupportWizardTree).length) {
      setQuestion(findQuestionBySlug(SLUGS.HAPPY_COOKING, cachedSupportWizardTree.leaves));
    }
  };

  const handleExpiredOrNoCache = async () => {
    const supportWizardTree = await dispatch(fetchSupportWizardTree());
    setQuestion(findQuestionBySlug(SLUGS.HAPPY_COOKING, supportWizardTree.leaves));
  };

  useCache(handleFreshCache, handleExpiredOrNoCache, wizardStore, WIZARD_LAST_VISIT, [question, language]);

  return question === null ? (
    <Loading />
  ) : question === undefined ? (
    <NotFound />
  ) : (
    <div className={SLUGS.HAPPY_COOKING}>
      <MultiAnswerSlide question={question} />
    </div>
  );
};
