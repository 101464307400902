import { CUSTOMER_SUPPORT_SERVICES } from "../config";
import { useServiceAvailability } from "./useServiceAvailability";

export function useLiveChatServiceAvailability() {
  const { isAvailable, isLoading } = useServiceAvailability(CUSTOMER_SUPPORT_SERVICES.LIVE_CHAT);

  return {
    isLiveChatServiceAvailable: isAvailable,
    isLoadingLiveChatServiceAvailability: isLoading,
  };
}
