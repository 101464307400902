import React from "react";

import { redirectTo, useUrlUpdater } from "../helpers/urlWiz";

import Col from "react-bootstrap/Col";

export const Answer = ({ questionSlug, answers, answerData, answersCount, minimal = false, areOrderMethodAnswers = false }) => {
  const { slug: answerSlug, title, image, redirectLink } = answerData;

  const updateUrl = useUrlUpdater(questionSlug, answers);

  const handleClick = () => (redirectLink ? redirectTo(redirectLink) : updateUrl(answerSlug));

  if (image) {
    return (
      <TileAnswer
        title={title}
        image={image}
        answersCount={answersCount}
        areOrderMethodAnswers={areOrderMethodAnswers}
        onClick={handleClick}
      />
    );
  }

  if (minimal) {
    return <MinimalButtonAnswer title={title} onClick={handleClick} />;
  }

  return <ButtonAnswer title={title} onClick={handleClick} />;
};

export const ButtonAnswer = (props) => (
  // "flex-container" class is to stretch all buttons on a row to the same height as highest one
  <Col className="flex-container" md={4} sm={6} xs={12}>
    <MinimalButtonAnswer {...props} />
  </Col>
);

export const MinimalButtonAnswer = ({ title, onClick }) => (
  <div className="slide-answer--button" onClick={onClick}>
    {title}
  </div>
);

export const TileAnswer = ({ title, image, answersCount, areOrderMethodAnswers, onClick }) => {
  const colSize = answersCount === 4 ? { sm: 6, xs: 12 } : { md: 4, sm: 6, xs: 12 };

  let imageClassname = "slide-answer--tile__image";

  if (areOrderMethodAnswers) {
    imageClassname += " slide-answer--tile__image--order-method";
  }

  return (
    <Col {...colSize}>
      <div className="slide-answer--tile" onClick={onClick}>
        <img className={imageClassname} src={image} alt={title} />
        <p className="slide-answer--tile__title">{title}</p>
      </div>
    </Col>
  );
};

export const SlideAnswersContainer = ({ children, answersWithImages }) => {
  let slideAnswersContainerClassname = "slide-answers-container";

  if (answersWithImages) {
    slideAnswersContainerClassname += " slide-answers-container--with-images";
  }

  return <div className={slideAnswersContainerClassname}>{children}</div>;
};
