import { colors, fontSize } from "../../styles/variables";

const topicName = (props = {}) => {
  const active = props.activeTopic === props.slug;
  const activeAfter = active
    ? {
        left: "0%",
        right: "0%",
      }
    : {};
  return {
    display: "inline-block",
    opacity: active ? 1 : 0.75,
    fontSize: fontSize.titleSm,
    fontWeight: 400,
    position: "relative",
    marginBottom: "12px",
    marginTop: 0,
    "&:hover": {
      cursor: "pointer",
    },
    "&:after": {
      content: '""',
      height: "2px",
      backgroundColor: colors.meaterRed,
      display: "block",
      position: "absolute",
      left: "",
      right: "100%",
      transitionProperty: "left right",
      transitionDuration: "0.5s",
      ...activeAfter,
    },
  };
};

const topicTitle = {
  marginTop: 0,
  marginBottom: "20px",
  fontSize: fontSize.titleMd,
  fontWeight: 500,
};

const topicsSection = {
  height: "100%",
  borderRight: `1px solid ${colors.lightGray}`,
  paddingRight: "32px",
};

const topicSelect = {
  fontSize: fontSize.lg,
  fontWeight: 400,
  border: "none",
  borderRadius: "none",
  background: "transparent",
  width: "100%",
  padding: "12px 0",
  zIndex: 1,
  // removing dropdown arrows
  "-moz-appearance": "none",
  "-webkit-appearance": "none",
  "&:hover": {
    cursor: "pointer",
  },
};

const topicSelectWrapper = {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  background: colors.white,
  borderTop: `1px solid ${colors.darkGray}`,
  borderBottom: `1px solid ${colors.darkGray}`,
  position: "relative",
  padding: "8px 0",
  marginBottom: "24px",
};

const topicLabel = {
  textTransform: "uppercase",
  fontWeight: 500,
  margin: "auto 8px auto 2px",
  height: "100%",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  fontSize: fontSize.md,
};

const plusIcon = {
  height: "48px !important",
  width: "48px !important",
  position: "absolute",
  right: 0,
  top: "50%",
  transform: "translateY(-50%)",
};

const plusIconLabel = {
  margin: "auto",
};

export const styles = {
  topicName,
  topicTitle,
  topicsSection,
  topicSelect,
  topicSelectWrapper,
  topicLabel,
  plusIcon,
  plusIconLabel,
};
