import { colors, fontSize } from "../../styles/variables";

const tagsWrapper = {
  display: "flex",
  flexWrap: "wrap",
};

const wrapperLabel = {
  color: colors.darkGray,
  padding: "8px 4px 12px",
  fontSize: fontSize.md,
  fontWeight: 500,
};

const baseTag = {
  borderRadius: "8px",
  padding: `10px 10px`,
  margin: "8px 4px",
  border: `2px solid ${colors.black}`,
};

const tag = {
  ...baseTag,
  backgroundColor: colors.white,
  "&:hover": {
    cursor: "pointer",
  },
};

const tagActive = {
  backgroundColor: colors.lightGray,
};

const tagValue = {
  margin: 0,
  padding: 0,
  fontSize: fontSize.lg,
  fontWeight: 500,
  cursor: "pointer",
};
const icon = {
  width: "16px !important",
  height: "16px !important",
  alignSelf: "center",
};

const seeMore = {
  ...baseTag,
  backgroundColor: colors.black,
  color: colors.white,
};

const valueWrapper = {
  display: "flex",
  alignItems: "center",
};

export const styles = {
  tagsWrapper,
  wrapperLabel,
  tag,
  tagActive,
  seeMore,
  icon,
  tagValue,
  valueWrapper,
};
