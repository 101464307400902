import React from "react";
import { useTranslation } from "react-i18next";

import Col from "react-bootstrap/Col";
import { BasicInput } from "./Input";
import { FormSection } from "./FormSection";
import SerialTip from "./SerialTip";

import "../styles/shared.scss";

export const ProductInfo = () => {
  const { t } = useTranslation();

  return (
    <>
      <FormSection text={t("device-and-platform-details")} />
      <SerialNumber />
      <Col sm={12}>
        <BasicInput storeKey={"orderNumber"} label={t("order-number")} />
      </Col>
    </>
  );
};

export const SerialNumber = () => {
  const { t } = useTranslation();

  return (
    <Col sm={12} className="d-inline-flex">
      <BasicInput storeKey={"serialNumber"} label={t("serial-number")} />
      <SerialTip />
    </Col>
  );
};
