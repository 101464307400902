import React from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import { fontSize, colors, mediaQueries } from "../styles/variables";
import { useMediaQuery } from "react-responsive";

const styles = {
  center: ({ tabletUp }) => ({
    textAlign: "center",
    fontSize: tabletUp ? fontSize.lg : fontSize.mdLg,
  }),
  addressWrapper: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.lg : fontSize.mdLg,
    textAlign: "center",
    marginBottom: "16px",
    color: colors.buttonGrey,
  }),
  title: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.lg : fontSize.mdLg,
    margin: "1em 0",
  }),
  addressTitle: {
    margin: 0,
    fontWeight: 500,
  },
  address: {
    fontStyle: "normal",
    lineHeight: 1.5,
  },
  snailMail: {
    fontSize: 18,
    lineHeight: 32,
  },
};

const useStyles = createUseStyles(styles);

export const AltContact = () => {
  const { t } = useTranslation();
  const tabletUp = useMediaQuery(mediaQueries.bootstrap.tablet);
  const classes = useStyles({ tabletUp });

  return (
    <div id="snail-mail" className="py-4">
      <div className="container">

        <div className="row">
          <div className="col" style={{ textAlign: "center", color: colors.buttonGrey }}>
            <h3 className={classes.title}>Contact us by phone</h3>
          </div>
        </div>

        <table
          className="contact-us-table"
          style={{
            margin: "30px auto",
            borderCollapse: "collapse",
            width: "80%",
            fontSize: "16px",
            width:"auto",
          }}
        >
          <tr>
            <th
              style={{
                fontWeight: "bold",
                padding: "10px 15px",
                borderBottom: "2px solid #ddd",
                color:"rgb(85,85,85)",
                textAlign:"center"
              }}
            >
              Country
            </th>
            <th
              style={{
                fontWeight: "bold",
                padding: "10px 15px",
                borderBottom: "2px solid #ddd",
                color:"rgb(85,85,85)"
              }}
            >
              Phone Number
            </th>
          </tr>
          <tr style={{ backgroundColor: "#ffffff" }}>
            <td
              style={{
                padding: "12px 15px",
                paddingRight: "40px",
                
              }}
            >
              US
            </td>
            <td
              style={{
                padding: "12px 15px",
               
              }}
            >
              <a href="tel:+18884070303">+1 888-407-0303</a>
            </td>
          </tr>
          <tr style={{ backgroundColor: "#f2f2f2" }}>
            <td
              style={{
                padding: "12px 15px",
                paddingRight: "40px",
                
              }}
            >
              Netherlands
            </td>
            <td
              style={{
                padding: "12px 15px",
                
              }}
            >
              <a href="tel:+318002300470">+31 800 2300470</a>
            </td>
          </tr>
          <tr style={{ backgroundColor: "#ffffff" }}>
            <td
              style={{
                padding: "12px 15px",
                paddingRight: "40px",
                
              }}
            >
              France
            </td>
            <td
              style={{
                padding: "12px 15px",
                
              }}
            >
              <a href="tel:+33801841557">+33 801 84 15 57</a>
            </td>
          </tr>
          <tr style={{ backgroundColor: "#f2f2f2" }}>
            <td
              style={{
                padding: "12px 15px",
                paddingRight: "40px",
                
              }}
            >
              Spain
            </td>
            <td
              style={{
                padding: "12px 15px",
                
              }}
            >
              <a href="tel:+34900751989">+34 900 751 989</a>
            </td>
          </tr>
          <tr style={{ backgroundColor: "#ffffff" }}>
            <td
              style={{
                padding: "12px 15px",
                paddingRight: "40px",
                
              }}
            >
              Austria
            </td>
            <td
              style={{
                padding: "12px 15px",
                
              }}
            >
              <a href="tel:+43800018169">+43 800 018169</a>
            </td>
          </tr>
          <tr style={{ backgroundColor: "#f2f2f2" }}>
            <td
              style={{
                padding: "12px 15px",
                paddingRight: "40px",
                
              }}
            >
              Germany
            </td>
            <td
              style={{
                padding: "12px 15px",
                
              }}
            >
              <a href="tel:+4932212249385">+49 32 212249385</a>
            </td>
          </tr>
          <tr style={{ backgroundColor: "#ffffff" }}>
            <td
              style={{
                padding: "12px 15px",
                paddingRight: "40px",
                
              }}
            >
              UK
            </td>
            <td
              style={{
                padding: "12px 15px",
                
              }}
            >
              <a href="tel:+448081346635">+44 808 134 6635</a>
            </td>
          </tr>
        </table>


        <div className="row">
          <div className="col" style={{ textAlign: "center", color: colors.buttonGrey }}>
            <h3 className={classes.title}>{t("send-us-letter")}</h3>
          </div>
        </div>
        <div className="row justify-content-md-center text-size-lg">
          <div className={`${classes.addressWrapper} col-12 col-md-6`}>
            <p className={classes.addressTitle}>{t("usa-office")}</p>
            <address className={classes.address}>
              Apption Labs, Inc
              <br />
              3779 Cahuenga Blvd
              <br />
              Studio City, CA 91604
            </address>
          </div>
          <div className={`${classes.addressWrapper} col-12 col-md-6`}>
            <p className={classes.addressTitle}>{t("uk-office")}</p>
            <address className={classes.address}>
              Apption Labs Limited
              <br />
              66 Commercial Square
              <br />
              Leicester, LE2 7SR
            </address>
          </div>
        </div>
      </div>
    </div>
  );
};
