import React, { useEffect, useState } from "react";

import LoginForm from "./LoginForm";
import ForgotPasswordForm from "./ForgotPasswordForm";
import ResetPasswordForm from "./ResetPasswordForm";
import Unauthorized from "../../pages/Unauthorized";
import GenericError from "../../pages/GenericError";

import "./styles.scss";

const WithLogin = ({ children = null, apiEndpoints, updateStore }) => {
  const { LOGIN, FORGOT_PASSWORD, RESET_PASSWORD } = apiEndpoints;
  const [authorized, setAuthorized] = useState(2);
  const [userData, setUserData] = useState(null);
  const [loginEmail, setLoginEmail] = useState("");

  useEffect(() => {
    !!userData && updateStore(userData);
  }, [userData]);

  return authorized === 1 ? (
    children
  ) : authorized === 2 ? (
    <LoginForm setAuthorized={setAuthorized} setUserData={setUserData} loginEndpoint={LOGIN} />
  ) : authorized === 3 ? (
    <ForgotPasswordForm setAuthorized={setAuthorized} setLoginEmail={setLoginEmail} forgotPasswordEndpoint={FORGOT_PASSWORD} />
  ) : authorized === 4 ? (
    <ResetPasswordForm setAuthorized={setAuthorized} email={loginEmail} resetPasswordEndpoint={RESET_PASSWORD} />
  ) : authorized === 0 ? (
    <Unauthorized setAuthorized={setAuthorized} />
  ) : (
    <GenericError />
  );
};

export default WithLogin;

export const withLogin =
  (Component, apiEndpoints, updateStore, auth) =>
  ({ ...props }) =>
    (
      <WithLogin apiEndpoints={apiEndpoints} updateStore={updateStore} auth={auth}>
        <Component {...props} />
      </WithLogin>
    );
