import React from "react";

import "./styles.scss";

export const OutlinedButton = (props) => {
  const { onClick, rootClassName = "", wrapperClassName = "", children, ...buttonProps } = props;

  return (
    <div className={`outline-button-wrapper ${wrapperClassName}`}>
      <button className={`outline-button ${rootClassName}`} onClick={onClick} {...buttonProps}>
        {children}
      </button>
    </div>
  );
};

export default OutlinedButton;
