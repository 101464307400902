import { combineReducers } from "redux";
import { wizardReducer } from "./wizard/reducer";
import { appReducer } from "./app/reducer";
import { faqsReducer } from "./faqs/reducer";
import { navbarReducer } from "./navbar/reducer";
import { cmsReducer } from "./cms/reducer";
import { zendeskFormReducer } from "./zendesk-form/reducer";
import { orderCheckerReducer } from "./order-checker/reducer";

export default combineReducers({
  app: appReducer,
  navbar: navbarReducer,
  wizard: wizardReducer,
  faqs: faqsReducer,
  cms: cmsReducer,
  zendeskForm: zendeskFormReducer,
  orderChecker: orderCheckerReducer,
});
