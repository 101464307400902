import React, { useEffect, useRef } from "react";
import { useDispatch, connect } from "react-redux";

import EditIcon from "@mui/icons-material/Edit";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import FindReplaceIcon from "@mui/icons-material/FindReplace";
import ReorderIcon from "@mui/icons-material/Reorder";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import ContentPasteIcon from "@mui/icons-material/ContentPaste";
import CancelIcon from "@mui/icons-material/Clear";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import DeleteIcon from "@mui/icons-material/Delete";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import Alert from "../../common/Alert";
import Confirm from "../../common/Confirm";
import AddLeafForm from "../forms/AddLeafForm";
import ChangeLeafForm from "../forms/ChangeLeafForm";
import ChangeLeafPositionForm from "../forms/ChangeLeafPositionForm";

import { cmsActions } from "../../../redux/cms/actions";
import { COLLECTION_TYPES } from "../../../helpers/Cache";

import "./leaf-actions.styles.scss";

const LeafActions = ({
  editorToken,
  copyingBranch,
  copiedBranch,
  copiedBranchSlug,
  copiedBranchCollectionType,
  cuttingBranch,
  cutBranch,
  cutBranchSlug,
  cutBranchCollectionType,
  data: { id, slug, leaves, siblingLeaves, branch, hasChildren, leafChildrenExpanded },
  coordinates,
}) => {
  const ADD = "Add option";
  const CHANGE = "Change option";
  const CHANGE_POSITION = "Change option's position";
  const COPY = "Copy branch";
  const CUT = "Cut branch";
  const PASTE = "Paste branch";
  const EXPORT = "Export";
  const REMOVE = "Remove";
  const REMOVE_BRANCH = "Remove branch";
  const leafCollectionType = id.split("/")[1];
  const siblingLeavesCount = siblingLeaves.length;
  const childrenCount = hasChildren ? leaves.length : 0;

  const {
    startCopyingBranch,
    cancelCopyingBranch,
    startCuttingBranch,
    cancelCuttingBranch,
    copyBranch,
    cutBranch: cutBranchAction,
    removeLeaf,
  } = cmsActions;

  const collectionTypeConstraintsAreMet = {
    questions: () => meetsQuestionConstraints(),
    answers: () => meetsAnswerConstraints(),
    articles: () => meetsArticleConstraints(),
    forms: () => meetsFormConstraints(),
  };

  const leafActionsWrapperRef = useRef(null);
  const leafActionsContainerRef = useRef(null);
  const dispatch = useDispatch();

  const cancelPastingBranch = () => {
    copyingBranch && dispatch(cancelCopyingBranch());
    cuttingBranch && dispatch(cancelCuttingBranch());
  };

  const handleAddLeaf = () => {
    if (isAnswer(leafCollectionType) && hasChildren) {
      Alert.display("error", "Answers can't have more than 1 child.");
    } else {
      AddLeafForm.display(id, branch, leaves);
      cancelPastingBranch();
    }
  };

  const handleChangeLeaf = () => {
    ChangeLeafForm.display(id, branch, siblingLeaves, hasChildren);
    cancelPastingBranch();
  };

  const handleChangeLeafPosition = () => {
    ChangeLeafPositionForm.display(branch, siblingLeavesCount);
    cancelPastingBranch();
  };

  const handleCopyingBranch = () => {
    dispatch(
      startCopyingBranch({
        copiedBranch: branch,
        copiedBranchSlug: slug,
        copiedBranchCollectionType: leafCollectionType,
      }),
    );
  };

  const handleCuttingBranch = () => {
    dispatch(
      startCuttingBranch({
        cutBranch: branch,
        cutBranchSlug: slug,
        cutBranchCollectionType: leafCollectionType,
      }),
    );
  };

  const handlePastingBranch = ({ target: { className } }) => {
    if (!clickedCancel(className)) {
      const slugToFind = copyingBranch ? copiedBranchSlug : cutBranchSlug;
      const alreadyAdded = !!leaves && leaves.find(({ slug }) => slug === slugToFind);
      if (alreadyAdded) {
        Alert.display("error", "Can't have two or more answers with the same slug");
        return;
      }

      if (copyingBranch) {
        if (collectionTypeConstraintsAreMet[leafCollectionType]()) {
          Confirm.display({
            title: "Pasting branch",
            description: `Are you sure you want to paste branch starting with node <strong>${copiedBranchSlug}</strong> inside branch starting with node <strong>${slug}</strong>.`,
            callback: () => dispatch(copyBranch({ copiedBranch, targetLeaf: branch })),
          });
        }
      } else {
        if (allConditionsForPastingAreMet(cutBranch, branch) && collectionTypeConstraintsAreMet[leafCollectionType]()) {
          Confirm.display({
            title: "Pasting branch",
            description: `Are you sure you want to paste branch starting with node <strong>${cutBranchSlug}</strong> inside branch starting with node <strong>${slug}</strong>.`,
            callback: () => dispatch(cutBranchAction({ cutBranch, targetLeaf: branch })),
          });
        }
      }
    }
  };

  const handleCancelPastingBranch = () => {
    copyingBranch ? dispatch(cancelCopyingBranch()) : dispatch(cancelCuttingBranch());
  };

  const handleExportingBranch = () => {
    if (!leafChildrenExpanded) {
      Alert.display("error", "Expand (double click) branch  before exporting.");
      return;
    }

    let startExporting = false;
    let setInvis = false;

    const intervalClose = setInterval(() => {
      if (!!leafActionsWrapperRef.current) {
        if (!setInvis) {
          leafActionsWrapperRef.current.style.display = "none";
          setInvis = true;
        }
      } else {
        startExporting = true;
        clearInterval(intervalClose);
      }
    }, 50);

    const intervalExport = setInterval(() => {
      if (startExporting) {
        window.print();
        clearInterval(intervalExport);
      }
    }, 50);
  };

  const handleRemoveLeaf = () => {
    hasChildren
      ? Alert.display("error", "Can't remove a node with children.")
      : Confirm.display({
          title: "Removing node",
          description: `Are you sure you want to remove node <strong>${slug}</strong>.`,
          callback: () => {
            dispatch(removeLeaf(branch));
            copyingBranch && dispatch(cancelCopyingBranch());
            cuttingBranch && dispatch(cancelCuttingBranch());
          },
        });
  };

  const handleRemoveBranch = () => {
    Confirm.display({
      title: "Removing branch",
      description: `Are you sure you want to remove branch starting with node <strong>${slug}</strong>.`,
      callback: () => {
        dispatch(removeLeaf(branch));
        copyingBranch && dispatch(cancelCopyingBranch());
        cuttingBranch && dispatch(cancelCuttingBranch());
      },
    });
  };

  // material icons return SVGAnimatedString                 object,
  // other elements (of leafActions components) return string
  const clickedCancel = (className) => typeof className === "object";

  const allConditionsForPastingAreMet = (cutBranch, branch) => {
    const isParentBoolean = isParent(branch, cutBranch);
    const isTheSameLeafBoolean = isTheSameLeaf(branch, cutBranch);
    const isSubBranchBoolean = isSubBranch(branch, cutBranch);

    if (isParentBoolean) {
      Alert.display("error", "Can't cut a branch and paste it inside its direct parent.\nUse copy instead.");
      return false;
    }

    if (isTheSameLeafBoolean) {
      Alert.display("error", "Can't cut and paste a branch inside itself.");
      return false;
    }

    if (isSubBranchBoolean) {
      Alert.display("error", "Can't cut and paste a branch down itself.");
      return false;
    }

    return !(isParentBoolean && isTheSameLeafBoolean && isSubBranchBoolean);
  };

  const isParent = (branchToPasteInto, cutBranch) => {
    const indexes = cutBranch.split("_");
    indexes.pop();
    const directParentOfCutBranch = indexes.join("_");

    return branchToPasteInto === directParentOfCutBranch;
  };

  const isTheSameLeaf = (branchToPasteInto, cutBranch) => branchToPasteInto === cutBranch;

  const isSubBranch = (branchToPasteInto, cutBranch) => branchToPasteInto.indexOf(cutBranch) === 0;

  const meetsQuestionConstraints = () => {
    if (
      (copiedBranchCollectionType && copiedBranchCollectionType !== "answers") ||
      (cutBranchCollectionType && cutBranchCollectionType !== "answers")
    ) {
      Alert.display("error", "Questions can have only answers as children.");
      return false;
    }

    return true;
  };

  const meetsAnswerConstraints = () => {
    if (
      (copiedBranchCollectionType && copiedBranchCollectionType === "answers") ||
      (cutBranchCollectionType && cutBranchCollectionType === "answers")
    ) {
      Alert.display("error", "Answers can't have answers as children.");
      return false;
    }

    if (childrenCount === 1) {
      Alert.display("error", "Answers can't have more than 1 child.");
      return false;
    }

    return true;
  };

  const meetsArticleConstraints = () => {
    if (
      (copiedBranchCollectionType && copiedBranchCollectionType !== "answers") ||
      (cutBranchCollectionType && cutBranchCollectionType !== "answers")
    ) {
      Alert.display("error", "Articles can have only answers as children.");
      return false;
    }

    if (childrenCount === 2) {
      Alert.display("error", "Articles can't have more than 2 children.");
      return false;
    }

    return true;
  };

  const meetsFormConstraints = () => {
    if (childrenCount === 0) {
      Alert.display("error", "Forms can't have children.");
      return false;
    }

    return true;
  };

  const isNotForm = (collectionType) => collectionType !== COLLECTION_TYPES.FORMS;

  const isAnswer = (collectionType) => collectionType === COLLECTION_TYPES.ANSWERS;

  const hasSiblings = () => siblingLeavesCount > 1;

  const setLeafActionsContainerPosition = (coordinates) => {
    leafActionsContainerRef.current.style = `top: ${coordinates.y}px; left: ${coordinates.x}px; display: block;`;
  };

  const resetLeafActionsContainerPosition = () => {
    leafActionsContainerRef.current.style = "";
  };

  useEffect(() => {
    !!coordinates ? setLeafActionsContainerPosition(coordinates) : resetLeafActionsContainerPosition();
  }, [coordinates]);

  return (
    <div className="leaf__actions-wrapper" ref={leafActionsWrapperRef}>
      <EditIcon className="leaf__edit-icon" onMouseEnter={resetLeafActionsContainerPosition} />
      <div className="leaf__actions-container" ref={leafActionsContainerRef}>
        {isNotForm(leafCollectionType) && (
          <div className="leaf__action" onClick={handleAddLeaf}>
            <AddCircleOutlineIcon className="leaf__action__icon leaf__action__icon--add" />
            {ADD}
          </div>
        )}
        <div className="leaf__action" onClick={handleChangeLeaf}>
          <FindReplaceIcon className="leaf__action__icon leaf__action__icon--change" />
          {CHANGE}
        </div>
        {hasSiblings() && (
          <div className="leaf__action" onClick={handleChangeLeafPosition}>
            <ReorderIcon className="leaf__action__icon leaf__action__icon--change-index" />
            {CHANGE_POSITION}
          </div>
        )}
        {!copyingBranch && !cuttingBranch && (
          <div className="leaf__action" onClick={handleCopyingBranch}>
            <ContentCopyIcon className="leaf__action__icon leaf__action__icon--copy" />
            {COPY}
          </div>
        )}
        {!cuttingBranch && !copyingBranch && (
          <div className="leaf__action" onClick={handleCuttingBranch}>
            <ContentCutIcon className="leaf__action__icon leaf__action__icon--cut" />
            {CUT}
          </div>
        )}
        {(copyingBranch || cuttingBranch) && (
          <div className="leaf__action" onClick={handlePastingBranch}>
            <ContentPasteIcon className="leaf__action__icon leaf__action__icon--paste" />
            {PASTE}
            <CancelIcon className="leaf__action__icon leaf__action__icon--cancel" onClick={handleCancelPastingBranch} />
          </div>
        )}
        <div className="leaf__action" onClick={handleExportingBranch}>
          <SaveAltIcon className="leaf__action__icon leaf__action__icon--export" />
          {EXPORT}
        </div>
        {hasChildren ? (
          <div className="leaf__action" onClick={handleRemoveBranch}>
            <DeleteForeverIcon className="leaf__action__icon leaf__action__icon--remove" />
            {REMOVE_BRANCH}
          </div>
        ) : (
          <div className="leaf__action" onClick={handleRemoveLeaf}>
            <DeleteIcon className="leaf__action__icon leaf__action__icon--remove" />
            {REMOVE}
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({
  cms: {
    editorToken,
    copyingBranch,
    copiedBranch,
    copiedBranchSlug,
    copiedBranchCollectionType,
    cuttingBranch,
    cutBranch,
    cutBranchSlug,
    cutBranchCollectionType,
  },
}) => ({
  editorToken,
  copyingBranch,
  copiedBranch,
  copiedBranchSlug,
  copiedBranchCollectionType,
  cuttingBranch,
  cutBranch,
  cutBranchSlug,
  cutBranchCollectionType,
});

export default connect(mapStateToProps)(LeafActions);
