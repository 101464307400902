import { SHOW_MOBILE, SHOW_LOGIN } from "./types";

export const showMobileNav = (payload) => ({
  type: SHOW_MOBILE,
  payload,
});

export const showLoginChooser = (payload) => ({
  type: SHOW_LOGIN,
  payload,
});

export const navbarActions = { showMobileNav, showLoginChooser };
