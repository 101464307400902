import React from "react";
import ReactGA from "react-ga";

import { devMode } from "../config";

import Navbar from "../navbar";
import Footer from "../footer";
import Wizard from "../wizard";
import { ThemeProvider } from "@material-ui/styles";
import { unstable_createMuiStrictModeTheme as createTheme } from "@material-ui/core/styles";

import { colors } from "../styles/variables";
import "../styles/styles.scss";

ReactGA.initialize("UA-67887322-1", { testMode: devMode });

const theme = createTheme({
  typography: {
    fontFamily: ["raleway", "sans-serif"].join(","),
  },
  overrides: {
    MuiFormLabel: {
      asterisk: {
        color: colors.lightRed,
        "&$error": {
          color: colors.lightRed,
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        color: colors.lightRed,
        "&$checked": {
          color: colors.lightRed,
        },
      },
    },
  },
});

// TODO: wizard articles json should be able to use the product that is active in that point of the wizard, add a way to pull that into context
// possible way, add optional property productSpecific
// read that in wizardArticle, if thats the case loop over value and add context for where we are.

const App = () => (
  <ThemeProvider theme={theme}>
    <div className="App-container">
      <div className="App">
        <header className="App__header">
          <Navbar />
        </header>
        <main className="App__body">
          <Wizard />
        </main>
        <footer className="App__footer">
          <Footer />
        </footer>
      </div>
    </div>
  </ThemeProvider>
);

export default App;
