import { SET_ZENDESK_FORM_STATE, RESET_ZENDESK_FORM_STATE } from "./types";

const setState = (payload) => ({
  type: SET_ZENDESK_FORM_STATE,
  payload,
});

const resetState = () => ({
  type: RESET_ZENDESK_FORM_STATE,
});

export const zendeskActions = { setState, resetState };
