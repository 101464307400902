import React from "react";
import { strapiURL } from "../config";

const ImageBlock = ({ data: { imageSource, alternativeText, redirectLink } }) => {
  const image = `<img class="content__image" src="${strapiURL}${imageSource}" alt="${alternativeText}"/>`;
  const string = !!redirectLink ? `<a href="${redirectLink}">${image}</a>` : image;

  return <div className="content__image-container" dangerouslySetInnerHTML={{ __html: string }}></div>;
};

export default ImageBlock;
