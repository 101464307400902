import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { SHOPS } from "../config.js";
import { navbarActions } from "../redux/navbar/actions.js";

import { SocialRow } from "../common/SocialRow";

import logo from "../assets/logo.svg";
import hamburger from "../assets/hamburger.svg";
import XIcon from "../assets/close-hamburger.svg";

const DesktopMenu = () => {
  const dispatch = useDispatch();
  const { isSideOpen } = useSelector((state) => state.navbar);
  const { showMobileNav } = bindActionCreators(navbarActions, dispatch);

  const generateShops = () =>
    SHOPS.map(({ icon, href, alt, text }, index) => (
      <a key={index} className="navbar__dropdown-wrapper__child" href={href}>
        <img src={icon} alt={alt}></img>
        {text}
      </a>
    ));

  return (
    <div id="header" className="navbar">
      <a href="#header" onClick={() => showMobileNav(!isSideOpen)} className="navbar__menu-icon">
        <img src={isSideOpen ? XIcon : hamburger} alt="Menu" id="mobile-menu-button" />
      </a>
      <a href="https://meater.com" className="navbar__meater-logo-wrapper" id="logo">
        <img className="navbar__meater-logo" src={logo} alt="MEATER" />
      </a>
      <ul id="nav" className="navbar__menu">
        <li className="navbar__menu-item">
          <div className="navbar__menu-item__child-wrapper">
            <a className="navbar__menu-item__child" href="https://meater.com/blog/">
              BLOG
            </a>
          </div>
        </li>
        <li className="navbar__menu-item navbar__menu-item--support">
          <div className="navbar__menu-item__child-wrapper">
            <a className="navbar__menu-item__child" href="/">
              SUPPORT
            </a>
          </div>
          <div className="navbar__dropdown-wrapper">
            <a href="/faqs" className="navbar__dropdown-wrapper__child">
              FAQs
            </a>
            <a href="/helper" className="navbar__dropdown-wrapper__child">
              Support
              <br />
              Wizard
            </a>
            <a href="/academy" className="navbar__dropdown-wrapper__child">
              Videos
            </a>
            <a href="/contact-us/" className="navbar__dropdown-wrapper__child">
              Contact us
            </a>
          </div>
        </li>
        <li className="navbar__menu-item navbar__menu-item--account">
          <div className="navbar__menu-item__child-wrapper">
            <div className="navbar__menu-item__child">MY ACCOUNT</div>
          </div>
          <div className="navbar__dropdown-wrapper navbar__dropdown-wrapper--my-account">{generateShops()}</div>
        </li>
        <li className="navbar__menu-item">
          <div className="navbar__menu-item__child-wrapper">
            <a href="https://meater.com/shop/" className="navbar__menu-item__child">
              SHOP
            </a>
          </div>
        </li>
        <li>
          <SocialRow />
        </li>
      </ul>
    </div>
  );
};

export default DesktopMenu;
