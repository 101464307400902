import React, { Component } from "react";

import Modal from "../../wizard/modal/Modal";

import "./styles.scss";

class Confirm extends Component {
  static display(data) {
    Confirm.__singletonRef.__display(data);
  }

  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      title: "",
      description: "",
      callback: null,
    };
    Confirm.__singletonRef = this;
  }

  __display = ({ title, description, callback }) => {
    this.setState({
      visible: true,
      title,
      description,
      callback,
    });
  };

  __close = () => {
    this.setState({
      visible: false,
      title: "",
      description: "",
      callback: null,
    });
  };

  __confirm = () => {
    this.state.callback();
    this.__close();
  };

  clickedOnModal = (e) => e.target.className.includes("confirm");

  render() {
    const { visible, title, description } = this.state;

    return (
      <Modal visible={visible} close={this.__close}>
        <div className="confirm">
          <h2 className="confirm__title">{title}</h2>
          <p dangerouslySetInnerHTML={{ __html: description }}></p>
          <button className="confirm__button confirm__button--cancel" onClick={this.__close}>
            Cancel
          </button>
          <button className="confirm__button confirm__button--ok" onClick={this.__confirm}>
            OK
          </button>
        </div>
      </Modal>
    );
  }
}

export default Confirm;
