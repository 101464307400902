import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core";

import { zendeskActions } from "../redux/zendesk-form/actions";

import Col from "react-bootstrap/Col";
import { TextField, MenuItem } from "@material-ui/core";

import { styles } from "./CountrySelect";
import "../styles/shared.scss";

const useStyles = makeStyles(styles);

export const CanBeCalled = () => {
  const { t } = useTranslation();
  const storeKey = "canBeCalled";
  const canBeCalled = useSelector((state) => state.zendeskForm[storeKey]);

  const classes = useStyles();
  const dispatch = useDispatch();
  const setState = bindActionCreators(zendeskActions.setState, dispatch);

  return (
    <Col sm={12}>
      <TextField
        required
        value={canBeCalled}
        onChange={(e) => setState({ [storeKey]: e.target.value })}
        inputProps={{ className: classes.input }}
        InputProps={{
          classes: {
            root: classes.root,
            notchedOutline: classes.notchedOutline,
          },
        }}
        variant="outlined"
        fullWidth
        select
        label={t("happy-to-receive-telephone-call")}
        margin="normal"
        InputLabelProps={{ className: `${classes.label} form-label--small` }}
      >
        <MenuItem value="yes">{t("yes")}</MenuItem>
        <MenuItem value="no">{t("no")}</MenuItem>
      </TextField>
    </Col>
  );
};
