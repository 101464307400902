import React, { useState } from "react";
import { createUseStyles } from "react-jss";
import { useTranslation } from "react-i18next";

import questionMark from "../assets/question-mark.svg";

const styles = {
  center: {
    height: "1.75rem",
    marginTop: "1.2rem",
    paddingLeft: "1rem",
  },
  tooltip: {
    background: "white",
    zIndex: "1",
    position: "absolute",
    bottom: "4.5rem",
    width: "12rem",
    border: "1px solid grey",
    borderRadius: "5px",
    padding: "5px",
    left: "21.5rem",
  },
  tooltipContainer: {},
  "@media (max-width: 767px)": {
    tooltip: {
      left: "9rem",
    },
  },
};
const useStyles = createUseStyles(styles);

const SerialTip = () => {
  const [isShown, setIsShown] = useState(false);
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.tooltipContainer}>
      <img
        src={questionMark}
        alt={t("question-mark-alt")}
        className={classes.center}
        onMouseEnter={() => setIsShown(true)}
        onMouseLeave={() => setIsShown(false)}
        onClick={() => setIsShown(!isShown)}
      />
      {isShown && <div className={classes.tooltip}>{t("find-probe-serial-number")}</div>}
    </div>
  );
};

export default SerialTip;
