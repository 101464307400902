import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { basePath, faqsPath, wizardPath } from "../config";

import "./styles.scss";

export const Hero = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const getNameAndRoute = () => {
    const topLevelRoute = location.pathname.split("/")[1];
    switch (`/${topLevelRoute}`) {
      case faqsPath:
        return { name: t("faq"), route: faqsPath };
      case wizardPath:
        return { name: t("support-wizard"), route: wizardPath };
      default:
        return { name: t("support"), route: basePath };
    }
  };

  const { route, name } = getNameAndRoute();

  const handleClick = () => history.push(route);

  return (
    <div className="hero-wrapper">
      <h1 className="hero__title" onClick={handleClick}>
        {name}
      </h1>
    </div>
  );
};
