import React from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

import { orderCheckerContactUs, ORDER_CHECKER_ENDPOINTS } from "../config";
import { orderCheckerActions } from "../redux/order-checker/actions";
import { zendeskActions } from "../redux/zendesk-form/actions";
import { zendeskFormSelector } from "../redux/zendesk-form/selector";

import OrderDetails from "./OrderDetailsContainer";
import { OrderNumberTip } from "./OrderNumberTip";

export const OrderShipmentStatus = () => {
  const dispatch = useDispatch();
  const { orderNumber, email } = useSelector(zendeskFormSelector);

  const trackOrder = async (recaptcha) => {
    const { data } = await axios.post(ORDER_CHECKER_ENDPOINTS.SHIPMENT_STATUS, { orderNumber, email, recaptcha });
    dispatch(zendeskActions.setState({ confirmEmail: email }));
    dispatch(orderCheckerActions.setState(data));
  };

  return (
    <>
      <OrderDetails onSubmit={trackOrder} />
      <OrderNumberTip location={orderCheckerContactUs} />
    </>
  );
};
