import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { makeStyles } from "@material-ui/core";

import { zendeskActions } from "../redux/zendesk-form/actions";
import countries from "../i18n/countries.json";

import Col from "react-bootstrap/Col";
import MenuItem from "@material-ui/core/MenuItem";
import { TextField } from "@material-ui/core";

import { fontSize, colors } from "../styles/variables";

export const styles = {
  root: {
    overflow: "hidden",
  },
  input: {
    fontSize: fontSize.lg,
    lineHeight: "normal",
    background: colors.zenFormInputBackground,
  },
  label: {
    fontSize: fontSize.lg,
    width: "calc(100% - 28px)",
  },
  notchedOutline: {
    border: 0,
  },
  radioGroup: {
    flexDirection: "row",
    justifyContent: "center",
  },
  noRightMargin: {
    marginRight: 0,
  },
};
const useStyles = makeStyles(styles);

export const CountrySelect = ({ storeKey = "country", requierd = true }) => {
  const placeholder = "please-select-your-country";
  const { t } = useTranslation();
  const country = useSelector((state) => state.zendeskForm[storeKey]);
  const language = useSelector((state) => state.app.language);

  const dispatch = useDispatch();
  const { setState } = bindActionCreators(zendeskActions, dispatch);
  const classes = useStyles();

  const generateCountryOptions = () =>
    countries.map(({ code, [language]: name }, index) => (
      <MenuItem key={index} value={code}>
        {name}
      </MenuItem>
    ));

  return (
    <Col sm={12}>
      <TextField
        required={requierd}
        value={country}
        onChange={(e) => setState({ [storeKey]: e.target.value })}
        inputProps={{ className: classes.input }}
        InputProps={{
          classes: {
            root: classes.root,
            notchedOutline: classes.notchedOutline,
          },
        }}
        variant="outlined"
        fullWidth
        select
        label={t(placeholder)}
        margin="normal"
        InputLabelProps={{ className: classes.label }}
      >
        <MenuItem value="">{t(placeholder)}</MenuItem>
        {generateCountryOptions()}
      </TextField>
    </Col>
  );
};
