import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { influencerContactUs, businessContactUs, wizardPath, faqsPath } from "../config";

import { Container, Col, Row } from "react-bootstrap";
import PhoneSupport from "../common/PhoneSupport";

import influencer from "../assets/influencer.svg";
import business from "../assets/business.svg";
import helperImage from "../assets/helper.svg";

import "./styles.scss";

export const Home = () => {
  const history = useHistory();
  const { t } = useTranslation();

  const goToFAQsPage = () => history.push(faqsPath);

  const Tile = ({ src, alt, heading, path }) => {
    return (
      <Col md={4} xs={6}>
        <div className="tile" onClick={() => history.push(path)}>
          <img className="tile__image" src={src} alt={alt} />
          <h2 className="tile__heading">{heading}</h2>
        </div>
      </Col>
    );
  };

  return (
    <Container className="contact-us">
      <h1 className="contact-us__title">{t("how-can-we-help")}</h1>
      <Row className="contact-us__options">
        <Tile src={helperImage} alt={t("support-wizard-alt")} heading={t("support-wizard")} path={wizardPath} />
        <Tile src={business} alt={t("business-enquiries-alt")} heading={t("business-enquiries")} path={businessContactUs} />
        <Tile src={influencer} alt={t("press-and-influencers-alt")} heading={t("press-and-influencers")} path={influencerContactUs} />
      </Row>

      
      <div className="table-div">
        <h2>Contact us by phone</h2>

        <table className="contact-us-table">
          <tr>
            <th>Country</th>
            <th>Phone Number</th>
          </tr>
          <tr>
            <td>US</td>
            <td><a href="tel:+18884070303">+1 888-407-0303</a></td>
          </tr>
          <tr>
            <td>Netherlands</td>
            <td><a href="tel:+318002300470">+31 800 2300470</a></td>
          </tr>
          <tr>
            <td>France</td>
            <td><a href="tel:+33801841557">+33 801 84 15 57</a></td>
          </tr>
          <tr>
            <td>Spain</td>
            <td><a href="tel:+34900751989">+34 900 751 989</a></td>
          </tr>
          <tr>
            <td>Austria</td>
            <td><a href="tel:+43800018169">+43 800 018169</a></td>
          </tr>
          <tr>
            <td>Germany</td>
            <td><a href="tel:+4932212249385">+49 32 212249385</a></td>
          </tr>
          <tr>
            <td>UK</td>
            <td><a href="tel:+448081346635">+44 808 134 6635</a></td>
          </tr>
        </table>
      </div>

      
      <Col xs={12}>
        <h3 className="contact-us__subtitle">{t("why-not-check-faqs")}</h3>
        <div className="faqs-title" onClick={goToFAQsPage}>
          {t("faqs")}
        </div>
      </Col>
      {/* <PhoneSupport /> */}
    </Container>
  );
};
