import axios from "axios";
import { SET_TREE, SET_ANSWERS, SET_ANSWER_TITLES, RESET_WIZARD } from "./types";

export const fetchSupportWizardTree = () => (dispatch) =>
  axios.get("/tree-json").then(({ data }) => {
    dispatch(setTree(data));
    return Promise.resolve(data);
  });

const setTree = (tree) => ({
  type: SET_TREE,
  payload: tree,
});

const setAnswers = (answers) => ({
  type: SET_ANSWERS,
  payload: answers,
});

const setAnswerTitles = (answerTitles) => ({
  type: SET_ANSWER_TITLES,
  payload: answerTitles,
});

const resetWizard = () => ({
  type: RESET_WIZARD,
});

export const wizardActions = {
  setTree,
  setAnswers,
  setAnswerTitles,
  resetWizard,
};
