import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";

import { faqsPath, wizardPath, contactUsPath } from "../config";
import { fetchSupportWizardTree } from "../redux/wizard/actions";
import { fetchFAQs } from "../redux/faqs/actions";
import { Cache, useCache, CACHE } from "../helpers/Cache";
import { wizardTreeSelector } from "../redux/wizard/selectors";

import { Container, Col, Row } from "react-bootstrap";

import helperImage from "../assets/helper.svg";
import contactUs from "../assets/contact-us.svg";
import qA from "../assets/q&a.svg";

import { fontSize, colors, mediaQueries } from "../styles/variables";
import PhoneSupport from "../common/PhoneSupport";

const styles = {
  title: ({ tabletUp }) => ({
    fontWeight: "bold",
    fontSize: tabletUp ? fontSize.titleMd : fontSize.xlg,
    textAlign: "center",
    marginTop: "0.67em",
    marginBottom: tabletUp ? "80px" : "24px",
  }),
  tileWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    padding: "0 16px",
    color: colors.black,
    textDecoration: "none",
    paddingTop: "32px",
  },
  tileImage: {
    width: "75%",
    margin: "0 auto",
    padding: "0 16px",
  },
  heading: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.titleSm : fontSize.lg,
    margin: "0.83em 0 16px 0",
    textTransform: "uppercase",
    fontWeight: "bold",
  }),
  subHeading: ({ tabletUp }) => ({
    margin: 0,
    color: colors.lightDarkGrey,
    fontSize: tabletUp ? fontSize.xlg : fontSize.md,
  }),
  container: ({ tabletUp }) => ({
    paddingBottom: tabletUp ? "128px" : "64px",
  }),
  row: {
    justifyContent: "center",
  },
};

const useStyles = createUseStyles(styles);

export const Home = () => {
  const { wizardStore, faqsStore } = Cache;
  const { WIZARD_LAST_VISIT, FAQS_LAST_VISIT } = CACHE;
  const tabletUp = useMediaQuery(mediaQueries.bootstrap.tablet);
  const classes = useStyles({ tabletUp });
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const cachedSupportWizardTree = useSelector(wizardTreeSelector);

  const handleWizardFreshCache = () => {
    if (Object.keys(cachedSupportWizardTree).length) {
      const entryQuestion = cachedSupportWizardTree.leaves[0];
      const entryAnswers = entryQuestion.leaves;

      Cache.check(wizardStore, [entryQuestion]).then(
        (uncachedQuestions) => uncachedQuestions && Cache.load(wizardStore, uncachedQuestions),
      );

      Cache.check(wizardStore, entryAnswers).then((uncachedAnswers) => uncachedAnswers && Cache.load(wizardStore, uncachedAnswers));
    }
  };

  const handleWizardExpiredOrNoCache = () => {
    dispatch(fetchSupportWizardTree()).then((supportWizardTree) => {
      const entryQuestion = supportWizardTree.leaves[0];
      const entryAnswers = entryQuestion.leaves;

      Cache.load(wizardStore, [entryQuestion]);
      Cache.load(wizardStore, entryAnswers);
    });
  };

  useCache(handleWizardFreshCache, handleWizardExpiredOrNoCache, wizardStore, WIZARD_LAST_VISIT);

  const handleFAQsFreshCache = () => {};
  const handleFAQsExpiredOrNoCache = () => dispatch(fetchFAQs());

  useCache(handleFAQsFreshCache, handleFAQsExpiredOrNoCache, faqsStore, FAQS_LAST_VISIT);

  return (
    <Container>
      <div className={classes.container}>
        <h1 className={classes.title}>{t("how-can-we-help")}</h1>
        <Row className={classes.row}>
          <Col md={4} xs={6}>
            <a className={classes.tileWrapper} href={wizardPath}>
              <img className={classes.tileImage} src={helperImage} alt="" />
              <h2 className={classes.heading}>{t("support")}</h2>
              <p className={classes.subHeading}>{t("troubleshooting-and-customer-support")}</p>
            </a>
          </Col>
          <Col md={4} xs={6}>
            <a className={classes.tileWrapper} href={faqsPath}>
              <img className={classes.tileImage} src={qA} alt="" />
              <h2 className={classes.heading}>{t("faq")}</h2>
              <p className={classes.subHeading}>{t("answers-to-most-common-concerns")}</p>
            </a>
          </Col>
          <Col md={4} xs={6}>
            <a className={classes.tileWrapper} href={contactUsPath}>
              <img className={classes.tileImage} src={contactUs} alt="" />
              <h2 className={classes.heading}>{t("contact-us")}</h2>
              <p className={classes.subHeading}>{t("business-enquires-press-and-influencers")}</p>
            </a>
          </Col>
        </Row>
        {/* <PhoneSupport /> */}
      </div>
    </Container>
  );
};
