import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ReactGA from "react-ga";

import { devMode, SLUGS } from "../config";
import { Cache, useCache, CACHE, COLLECTION_TYPES } from "../helpers/Cache";
import { getContentByLanguage, getSlugs, mergeCachedAndUncachedElements, orderBySlug } from "../helpers/Cache/utils";
import { getQuestionFromQueryString, getQuestionBefore } from "./utils";
import { fetchSupportWizardTree } from "../redux/wizard/actions";

import Content from "../content";
import { Answer, SlideAnswersContainer } from "./Answer";

export const MultiAnswerSlide = ({ children, question, questionBefore }) => {
  const { wizardStore } = Cache;
  const { WIZARD_LAST_VISIT } = CACHE;
  const { QUESTIONS, ANSWERS } = COLLECTION_TYPES;
  const language = useSelector((state) => state.app.language);
  const dispatch = useDispatch();
  const slideRef = useRef(null);
  const location = useLocation();
  const queryString = location.search;

  let isMounted = true;
  const [questionData, setQuestionData] = useState(null);
  const [answersData, setAnswersData] = useState([]);
  const answers = question.leaves;
  const hasAnswers = !!answers; // has answers means it's not terminal question / form
  const siblingAnswers = !!questionBefore && questionBefore.leaves;
  const hasSiblingAnswers = !!siblingAnswers;

  const handleFreshCache = async () => {
    const uncachedQuestions = await Cache.check(wizardStore, [question]);

    if (uncachedQuestions) {
      const questions = await Cache.load(wizardStore, uncachedQuestions);
      const [questionWithEmbeddedFAQData] = questions.length ? await Cache.loadEmbeddedFAQs(questions) : questions;

      if (isMounted) {
        setQuestionData(getContentByLanguage(questionWithEmbeddedFAQData, QUESTIONS, language));
      }
    } else {
      const [cachedQuestion] = await Cache.get(wizardStore, [question]);

      if (isMounted) {
        setQuestionData(getContentByLanguage(cachedQuestion, QUESTIONS, language));
      }
    }

    if (hasAnswers) {
      const answerSlugs = getSlugs(answers);
      const uncachedAnswers = await Cache.check(wizardStore, answers);

      if (uncachedAnswers) {
        const cachedAnswers = await Cache.get(wizardStore, answers);
        const strapiAnswers = await Cache.load(wizardStore, uncachedAnswers);
        const orderedAnswers = orderBySlug(answerSlugs, strapiAnswers);

        if (isMounted) {
          setAnswersData(
            getContentByLanguage(mergeCachedAndUncachedElements(answerSlugs, orderedAnswers, cachedAnswers), ANSWERS, language),
          );
        }
      } else {
        const cachedAnswers = await Cache.get(wizardStore, answers);

        if (isMounted) {
          setAnswersData(getContentByLanguage(cachedAnswers, ANSWERS, language));
        }
      }
    }

    if (!!questionBefore) {
      const uncachedQuestions = await Cache.check(wizardStore, [questionBefore]);

      if (uncachedQuestions) {
        const questions = await Cache.load(wizardStore, uncachedQuestions);

        if (questions.length) {
          await Cache.loadEmbeddedFAQs(questions);
        }
      }

      if (hasSiblingAnswers) {
        const uncachedSiblingAnswers = await Cache.check(wizardStore, siblingAnswers);

        if (uncachedSiblingAnswers) {
          await Cache.load(wizardStore, uncachedSiblingAnswers);
        }
      }
    }
  };

  const handleExpiredOrNoCache = async () => {
    const supportWizardTree = await dispatch(fetchSupportWizardTree());
    const question = getQuestionFromQueryString(supportWizardTree, queryString);

    if (question) {
      const answers = question.leaves;
      const answerSlugs = getSlugs(answers);
      const questions = await Cache.load(wizardStore, [question]);
      const [questionWithEmbeddedFAQData] = questions.length ? await Cache.loadEmbeddedFAQs(questions) : questions;

      if (isMounted) {
        setQuestionData(getContentByLanguage(questionWithEmbeddedFAQData, QUESTIONS, language));
      }

      if (hasAnswers) {
        const answers = await Cache.load(wizardStore, answers);
        const orderedAnswers = orderBySlug(answerSlugs, answers);

        if (isMounted) {
          setAnswersData(getContentByLanguage(orderedAnswers, ANSWERS, language));
        }
      }

      const questionBefore = getQuestionBefore(supportWizardTree, queryString);

      if (!!questionBefore) {
        const siblingAnswers = questionBefore.leaves;
        const questions = await Cache.load(wizardStore, [questionBefore]);

        if (questions.length) {
          await Cache.loadEmbeddedFAQs(questions);
        }

        if (hasSiblingAnswers) {
          await Cache.load(wizardStore, siblingAnswers);
        }
      }
    }
  };

  useCache(handleFreshCache, handleExpiredOrNoCache, wizardStore, WIZARD_LAST_VISIT, [question, language]);

  useEffect(() => {
    if (!!questionData) {
      hasAnswers && Cache.preload(wizardStore, answers);
      if (!hasAnswers && !questionData.escalation) {
        //is terminal question / form
        isMounted && setAnswersData([]);
        //add extra padding to happy cooking question
        const img = slideRef.current.querySelector(".content__image");
        !!img && img.classList.add("content__image--extra-padding");
      }
    }

    return () => {
      const event = {
        action: SLUGS.HAPPY_COOKING,
        label: `${location.search}`,
        category: "support-wizard",
      };
      if (!devMode && !!questionData && questionData.slug === SLUGS.HAPPY_COOKING) {
        ReactGA.event(event);
      }
    };
  }, [questionData]);

  useEffect(() => {
    isMounted = true;

    return () => {
      isMounted = false;
    };
  }, []);

  const answersWithImages = answersData[0] && answersData[0].image;
  const areOrderMethodAnswers =
    answersData[0] && answersData[0].image && answersData[0].image.split("/uploads/")[1].startsWith("order_method");

  const generateLocalAnswerComponents = () =>
    answersData.map((answerData, index) => (
      <Answer
        key={index}
        questionSlug={question.slug}
        answers={answers}
        answerData={answerData}
        answersCount={answersData.length}
        areOrderMethodAnswers={areOrderMethodAnswers}
      />
    ));

  if (!question || !question.id.includes("/")) return null;

  return (
    !!questionData && (
      <div ref={slideRef} className={!questionData.escalation && answersData.length ? "wizard__slide--not-escalation" : ""}>
        <Content data={questionData} />
        {!questionData.escalation ? (
          answersData.length ? (
            <SlideAnswersContainer answersWithImages={answersWithImages}>
              {children ? children(answersData) : generateLocalAnswerComponents()}
            </SlideAnswersContainer>
          ) : null
        ) : (
          <EscalationBlock questionSlug={question.slug} answersData={answersData} answers={answers} />
        )}
      </div>
    )
  );
};

export const EscalationBlock = ({ questionSlug, answersData, answers }) => {
  const { t } = useTranslation();

  return (
    <div className="escalation-block__row">
      <div className="escalation-block__title-container">
        <p className="escalation-block__title">{t("did-this-resolve-your-issue")}</p>
      </div>
      <SlideAnswersContainer>
        {answersData.map((answerData, index) => (
          <Answer
            key={index}
            questionSlug={questionSlug}
            answers={answers}
            answerData={answerData}
            answersCount={answersData.length}
            minimal
          />
        ))}
      </SlideAnswersContainer>
    </div>
  );
};
