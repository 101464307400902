import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { cmsActions } from "../../../redux/cms/actions";

import LeafActions from "../leaf-actions/LeafActions";
import Alert from "../../common/Alert";

import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

import "./leaf.styles.scss";

const Leaf = ({
  data: { id, slug, title, leaves, siblingLeaves, branch, hasChildren, leafChildrenExpanded },
  expandLeafChildren,
  collapseLeafPosterity,
}) => {
  const dispatch = useDispatch();
  const [leafActionsCoordinates, setLeafActionsCoordinates] = useState(null);
  const [hoveringOverLeaf, setHoveringOverLeaf] = useState(false);
  const [hoveringOverLeafDetails, setHoveringOverLeafDetails] = useState(false);
  const leafCollectionType = id.split("/")[1];
  const leafID = id.split("/")[2];

  const handleToggleExpanded = () => {
    !leafChildrenExpanded ? expandLeafChildren() : collapseLeafPosterity();
    dispatch(cmsActions.setExpandedBranch(""));
  };

  const handleRightClick = (e) => {
    e.preventDefault();

    const clickedOnEditOrArrowIcons = () => typeof e.target.className === "object";

    const clickedOnLeafDetails = () => e.target.className.includes("leaf__detail");

    if (!(clickedOnEditOrArrowIcons() || clickedOnLeafDetails())) {
      const leaf = e.target.closest(".leaf");
      const leafActionsWrapperRect = leaf.querySelector(".leaf__actions-wrapper").getBoundingClientRect();
      const x = e.clientX - leafActionsWrapperRect.left - 5;
      const y = e.clientY - leafActionsWrapperRect.top - 5;
      setLeafActionsCoordinates({ x, y });
    }
  };

  const handleExpandLeafPosterity = (e) => {
    e.preventDefault();
    dispatch(cmsActions.setExpandedBranch(branch));
  };

  const handleMouseEnterLeaf = () => {
    setHoveringOverLeaf(true);
  };

  const handleMouseLeaveLeaf = () => {
    setHoveringOverLeaf(false);
    setLeafActionsCoordinates(null);
  };

  const handleMouseEnterLeafDetails = () => {
    setHoveringOverLeafDetails(true);
  };

  const handleMouseLeaveLeafDetails = () => {
    setHoveringOverLeafDetails(false);
  };

  const copyToClipboard = (detail, value) => {
    navigator.clipboard.writeText(value);
    Alert.display("success", `${detail} copied to clipboard.`);
  };

  return (
    <div
      className={"leaf" + (leafChildrenExpanded ? " leaf--expanded" : "")}
      onMouseEnter={handleMouseEnterLeaf}
      onMouseLeave={handleMouseLeaveLeaf}
      onDoubleClick={handleExpandLeafPosterity}
      onContextMenu={handleRightClick}
    >
      <div className="leaf__title-container">
        <h2 className="leaf__title" onClick={handleToggleExpanded}>
          {title}
        </h2>
        {hoveringOverLeaf && (
          <LeafActions
            coordinates={leafActionsCoordinates}
            data={{
              id,
              slug,
              leaves,
              siblingLeaves,
              branch,
              hasChildren,
              leafChildrenExpanded,
            }}
          />
        )}
        {hoveringOverLeaf && hasChildren && !leafChildrenExpanded && (
          <ArrowDropDownIcon className="leaf__arrow-drop-down" onClick={expandLeafChildren} />
        )}
        {hoveringOverLeaf && hasChildren && leafChildrenExpanded && (
          <ArrowDropUpIcon className="leaf__arrow-drop-up" onClick={collapseLeafPosterity} />
        )}
      </div>
      {hoveringOverLeaf && (
        <div
          className={"leaf__details-wrapper" + (hoveringOverLeafDetails ? " leaf__details-wrapper--expanded" : "")}
          onMouseEnter={handleMouseEnterLeafDetails}
          onMouseLeave={handleMouseLeaveLeafDetails}
        >
          <ArrowBackIosNewIcon className="leaf__details__icon" />
          <div className="leaf__details-container">
            <p className="leaf__detail" onClick={() => copyToClipboard("Slug", slug)}>
              <strong className="leaf__detail--bold">Slug: </strong>
              {slug}
            </p>
            <p className="leaf__detail" onClick={() => copyToClipboard("Collection type", leafCollectionType)}>
              <strong className="leaf__detail--bold">Collection type: </strong>
              {leafCollectionType}
            </p>
            <p className="leaf__detail" onClick={() => copyToClipboard("ID", leafID)}>
              <strong className="leaf__detail--bold">ID: </strong>
              {leafID}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default Leaf;
