import React from "react";
import { openInNewTab, redirectTo } from "../helpers/urlWiz";

export const FooterLinkList = ({ items }) => {
  const handleClick = (link, newTab) => {
    newTab ? openInNewTab(link) : redirectTo(link);
  };

  return (
    <ul className="footer-column">
      {items.map(({ link, text, newTab }, index) => (
        <li key={index} className="footer-column__item" onClick={() => handleClick(link, newTab)}>
          {text}
        </li>
      ))}
    </ul>
  );
};

export default FooterLinkList;
