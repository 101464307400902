import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { navbarActions } from "../redux/navbar/actions";
import { debounce } from "../helpers/debounce";
import { useHandleWindowResize } from "../hooks";

import { SocialRow } from "../common/SocialRow";
import AccountPopover from "./AccountPopover";

const MobileMenu = () => {
  const dispatch = useDispatch();
  const { isSideOpen, isLoginOpen } = useSelector((state) => state.navbar);
  const { showMobileNav, showLoginChooser } = bindActionCreators(navbarActions, dispatch);

  const hideSideMenu = () => {
    showMobileNav(false);
  };

  const debouncedHideSideMenu = debounce(hideSideMenu);

  useEffect(() => {
    hideSideMenu();
  }, []);

  useHandleWindowResize(debouncedHideSideMenu);

  return (
    <>
      <div className={`navbar__side-panel ${isSideOpen ? "navbar__side-panel--show" : "navbar__side-panel--hide"}`} id="mobile-side-panel">
        <ul className="navbar__side-panel__group">
          <li className="navbar__side-panel__child">
            <a className="navbar__side-panel__child-link" href="https://meater.com/">
              HOME
            </a>
          </li>
          <li className="navbar__side-panel__child">
            <a className="navbar__side-panel__child-link" href="https://meater.com/blog/">
              BLOG
            </a>
          </li>
          <li className="navbar__side-panel__child">
            <a className="navbar__side-panel__child-link" onClick={hideSideMenu} href="/">
              SUPPORT
            </a>
          </li>
          <li className="navbar__side-panel__child">
            <a className="navbar__side-panel__child-link" href="https://meater.com/shop/">
              SHOP
            </a>
          </li>
        </ul>
        <ul className="navbar__side-panel__group">
          <li className="navbar__side-panel__child">
            <a className="navbar__side-panel__child-link" href="/faqs" onClick={hideSideMenu}>
              FAQS
            </a>
          </li>
          <li className="navbar__side-panel__child">
            <a className="navbar__side-panel__child-link" href="/academy" onClick={hideSideMenu}>
              VIDEOS
            </a>
          </li>
          <li className="navbar__side-panel__child">
            <a
              className="navbar__side-panel__child-link"
              onClick={() => {
                hideSideMenu();
                showLoginChooser(true);
              }}
            >
              MY ACCOUNT
            </a>
          </li>
          <li className="navbar__side-panel__child">
            <a className="navbar__side-panel__child-link" href="/contact-us/">
              CONTACT US
            </a>
          </li>
        </ul>
        <ul className="navbar__side-panel__group">
          <li className="navbar__side-panel__child">
            <a className="navbar__side-panel__child-link" href="https://meater.com/about-us/">
              ABOUT US
            </a>
          </li>
        </ul>
        <ul className="navbar__side-panel__group">
          <li className="navbar__side-panel__child">
            <a className="navbar__side-panel__child-link" href="https://meater.com/warranty-returns/">
              WARRANTY
            </a>
          </li>
          <li className="navbar__side-panel__child">
            <a className="navbar__side-panel__child-link" href="https://meater.com/privacy-policy/">
              PRIVACY POLICY
            </a>
          </li>
          <li className="navbar__side-panel__child">
            <a className="navbar__side-panel__child-link" href="https://meater.com/terms-of-use/">
              TERMS OF USE
            </a>
          </li>
        </ul>
        <ul className="navbar__side-panel__social-group" id="side-panel-social">
          <li>
            <SocialRow />
          </li>
        </ul>
      </div>
      {isLoginOpen && <AccountPopover />}
    </>
  );
};

export default MobileMenu;
