import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core";

import Col from "react-bootstrap/Col";
import Button from "@material-ui/core/Button";
import { FormSection } from "./FormSection";

import { styles } from "./OrderDetails";
import "../styles/shared.scss";

const useStyles = makeStyles(styles);

export const OrderStatusInformation = ({ orderStatusUrl, orderStatusButtonText }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <>
      <FormSection text={t("order-found")} />
      <Col xs={12} className={classes.submit} style={{ flexWrap: "wrap" }}>
        <Button classes={{ root: classes.orderStatusButton }}>
          <a href={orderStatusUrl} target="_blank" rel="noopener noreferrer" style={{ textDecoration: "none", color: "white" }}>
            {orderStatusButtonText}
          </a>
        </Button>
      </Col>
      <FormSection text={t("contact-courier")} />
      <hr />
    </>
  );
};
