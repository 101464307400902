import React from "react";

import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import "./styles.scss";

const ScrollToTop = () => {
  const handleClick = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="scroll-to-top" onClick={handleClick}>
      <ArrowUpwardIcon className="scroll-to-top__icon" />
    </div>
  );
};

export default ScrollToTop;
