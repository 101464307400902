import React from "react";

import { SocialRow } from "../common/SocialRow";
import OutlinedButton from "../common/OutlinedButton";
import FooterLinkList from "./FooterLinkList";

import "./styles.scss";

const Newsletter = () => {
  const handleClick = () => {
    window.location.href = "https://meater.com/newsletter-subscribe";
  };

  return (
    <div className="footer-newsletter">
      <label className="footer-newsletter__label" htmlFor="newsletter-input">
        Keep up to date with our newsletter
      </label>
      <form className="footer-newsletter__row" action="https://meater.com/newsletter-subscribe" method="post" onSubmit={handleClick}>
        <input className="footer-newsletter__input" id="newsletter-input" type="email" name="email"></input>
        <OutlinedButton type="submit">Subscribe</OutlinedButton>
      </form>
    </div>
  );
};

const Copyright = () => {
  const year = new Date().getFullYear();

  return (
    <div className="footer-copyright">
      <p className="footer-copyright__body">
        The Bluetooth® word mark and logos are registered trademarks owned by Bluetooth SIG, Inc., and any use of such marks by Apption Labs
        is under license. Other trademarks and trade names are those of their respective owners.
        <br />
        <br />© {year}. All rights reserved. MEATER® is a trademark of Apption Labs™ Limited.&nbsp;
        <a href="https://www.traeger.com/">A Traeger Company</a>.&nbsp;
        <a href="https://meater.com/patents">Patented</a>.&nbsp;
        <button
            onClick={() => window?.OneTrust?.ToggleInfoDisplay()}
            className="one-trust-cookie-button"
          >
            Cookie Policy and Preferences
        </button>
      </p>
    </div>
  );
};

const Footer = () => {
  const col1 = [
    {
      link: "https://meater.com/blog/?_ga=2.264033804.1696460342.1569230047-1793476003.1566894337",
      text: "Blog",
    },
    { link: "https://meater.com/support/", text: "Support" },
    { link: "https://meater.com/shop/", text: "Shop" },
    { link: "https://meater.com/dealers/", text: "Dealer Locator" },
    { link: "https://meater.com/about-us/", text: "About Us" },
    {
      link: "https://status.cloud.meater.com",
      text: "Cloud Status",
      newTab: true,
    },
  ];

  const col2 = [
    { link: "https://meater.com/address/", text: "Addresses" },
    { link: "https://meater.com/manual/", text: "User Manual" },
    { link: "https://meater.com/ce-declaration/", text: "Declarations" },
    {
      link: "https://meater.com/warranty-returns/",
      text: "Warranty & Returns",
    },
    { link: "https://meater.com/privacy-policy/", text: "Privacy Policy" },
    { link: "https://meater.com/terms-of-use/", text: "Terms of Use" },
  ];

  return (
    <div className="footer-row">
      <div className="footer-linkList-container col-6 col-md-2 ">
        <FooterLinkList items={col1} />
      </div>
      <div className="footer-linkList-container col-6 col-md-2 ">
        <FooterLinkList items={col2} />
      </div>
      <div className="footer-socials-container col-10 col-md-3">
        <div className="footer-socials">
          <p>Let's socialize:</p>
          <SocialRow />
        </div>
      </div>
      <div className="footer-newsletter-container col-md-5">
        <Newsletter />
      </div>
      <Copyright />
    </div>
  );
};

export default Footer;
