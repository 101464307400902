import React from "react";
import { useTranslation } from "react-i18next";

import "./styles.scss";

const GenericError = () => {
  const { t } = useTranslation();

  return (
    <div className="page">
      <h1 className="page__title">{t("generic-error-title")}</h1>
      <p className="page__subtitle">{t("generic-error-subtitle")}</p>
    </div>
  );
};

export default GenericError;
