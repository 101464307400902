import React, { useState } from "react";
import axios from "axios";

import { isPassword } from "../../helpers/regex";

const ResetPasswordForm = ({ setAuthorized, email, resetPasswordEndpoint }) => {
  const [resetPasswordToken, setResetPasswordToken] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");

  const clearMessages = () => {
    errorMessage && setErrorMessage("");
    successMessage && setSuccessMessage("");
  };

  const handleResetPasswordTokenChange = ({ target: { value } }) => {
    setResetPasswordToken(value);
    clearMessages();
  };

  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value);
    clearMessages();
  };

  const handleConfrimPasswordChange = ({ target: { value } }) => {
    setConfirmPassword(value);
    clearMessages();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    successMessage && setSuccessMessage("");

    if (!resetPasswordToken) {
      setErrorMessage("Reset password token not specified");
      return;
    }

    if (!isPassword(password)) {
      setErrorMessage("Password must contain only letters in lowercase, uppercase and numbers");
      return;
    }

    if (password.length < 10) {
      setErrorMessage("Passwords must be at least 10 characters long");
      return;
    }

    if (password !== confirmPassword) {
      setErrorMessage("Passwords do not match");
      return;
    }

    !errorMessage && changePassword({ email, newPassword: password, resetPasswordToken });
  };

  const changePassword = (credentials) =>
    axios
      .post(resetPasswordEndpoint, credentials)
      .then(({ status, data }) => {
        if (status === 200) {
          setSuccessMessage(data);
          setResetPasswordToken("");
          setPassword("");
          setConfirmPassword("");
        }
      })
      .catch(({ response: { data } }) => {
        successMessage && setSuccessMessage("");
        setErrorMessage(data);
      });

  const showLoginForm = () => setAuthorized(2);

  return (
    <div className="with-login">
      <h1 className="with-login__title">Reset Password</h1>
      <div className="with-login__error-message">
        <span>{errorMessage}</span>
      </div>
      <div className="with-login__success-message">
        <span>{successMessage}</span>
      </div>
      <form className="with-login__form">
        <input
          className="with-login__form__input"
          name="resetPasswordToken"
          value={resetPasswordToken}
          required
          placeholder="Paste reset password token here"
          onChange={handleResetPasswordTokenChange}
        />
        <input
          className="with-login__form__input"
          type="password"
          name="password"
          value={password}
          required
          placeholder="Create new password"
          onChange={handlePasswordChange}
        />
        <input
          className="with-login__form__input"
          type="password"
          name="confirmPassword"
          value={confirmPassword}
          required
          placeholder="Confirm new password"
          onChange={handleConfrimPasswordChange}
        />
      </form>
      <button className="with-login__button" type="submit" onClick={handleSubmit}>
        Change
      </button>
      <div className="with-login__link">
        <span onClick={showLoginForm}>Return to Login</span>
      </div>
    </div>
  );
};

export default ResetPasswordForm;
