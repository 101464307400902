import React from "react";
import { useSelector } from "react-redux";
import { PRODUCT_NAMES } from "../config";

import { getProductFromState } from "../helpers/urlWiz";
import { wizardAnswersSelector } from "../redux/wizard/selectors";
import {
  onFAQsPage,
  addClassToElement,
  wrapListItem,
  wrapImage,
  replacePlaceholderWithProductCapitalized,
  replacePlaceholderWithProduct,
} from "./utils";

const TextBlock = ({ data }) => {
  const wizardAnswers = useSelector(wizardAnswersSelector);
  const { activeProduct } = useSelector((state) => state.faqs);

  const product = onFAQsPage() ? activeProduct : getProductFromState(wizardAnswers);

  let text = replacePlaceholderWithProductCapitalized(data, PRODUCT_NAMES[product]);
  text = replacePlaceholderWithProduct(text, product);

  return (
    <div
      className="content__text-wrapper"
      dangerouslySetInnerHTML={{
        __html: proccessText(text),
      }}
    />
  );
};

const proccessText = (text) => {
  text = wrapListItem(text);
  text = wrapImage(text);
  text = addClassToElement(text, "h1", "content__title");
  text = addClassToElement(text, "h2", "content__subtitle");
  text = addClassToElement(text, "p", "content__text");
  text = addClassToElement(text, "ol", "content__ordered-list");
  text = addClassToElement(text, "ul", "content__unordered-list");
  text = addClassToElement(text, "li", "content__list-item");

  return text;
};

export default TextBlock;
