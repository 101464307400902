import axios from "axios";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { ORDER_CHECKER_ENDPOINTS } from "../config";
import { zendeskFormSelector } from "../redux/zendesk-form/selector";
import { orderCheckerActions } from "../redux/order-checker/actions";

import { ButtonAnswer } from "../wizard/Answer";
import { orderCheckerSelector } from "../redux/order-checker/selectors";

export const RequestReshipmentButton = ({ answerData: { title }, setProcessingReshipmentRequest }) => {
  const dispatch = useDispatch();
  const { email, orderNumber } = useSelector(zendeskFormSelector);
  const { reshipmentRequestToken } = useSelector(orderCheckerSelector);

  const requestShipment = async () => {
    setProcessingReshipmentRequest();

    const { data } = await axios.post(ORDER_CHECKER_ENDPOINTS.RESHIPMENT, {
      email,
      orderNumber,
      reshipmentRequestToken,
    });

    dispatch(orderCheckerActions.setState(data));
  };

  return <ButtonAnswer title={title} onClick={requestShipment} />;
};
