import React, { Component } from "react";
import { connect } from "react-redux";

import { cmsActions } from "../../../redux/cms/actions";

import Modal from "../modal/Modal";
import Form from "./Form";

class ChangeLeafPositionForm extends Component {
  static display(branch, siblingLeavesCount) {
    ChangeLeafPositionForm.__singletonRef.__display(branch, siblingLeavesCount);
  }

  constructor(props) {
    super(props);
    this.state = {
      index: "unselected",
      branch: "",
      siblingLeavesCount: -1,
      selectInputOptions: [],
      visible: false,
      successMessage: "",
      errorMessage: "",
    };

    ChangeLeafPositionForm.__singletonRef = this;
  }

  __display = (branch, siblingLeavesCount) => {
    this.setState({
      branch,
      siblingLeavesCount,
      selectInputOptions: this.generatePositionOptions(this.getLastIndex(branch), siblingLeavesCount),
      visible: true,
    });
  };

  __close = ({ target: { className } }) => {
    if (typeof className === "object" || className.split(" ")[0] === "modal") {
      this.setState({ visible: false });
      this.resetForm();
    }
  };

  handleChange = ({ target: { name: changedProperty, value: newValue } }) => {
    const { successMessage, errorMessage } = this.state;

    !!errorMessage && this.setState({ errorMessage: "" });
    !!successMessage && this.setState({ successMessage: "" });

    this.setState({ [changedProperty]: newValue });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const { index, branch, siblingLeavesCount, successMessage } = this.state;
    const { changeLeafIndex } = this.props;
    successMessage && this.setState({ successMessage: "" });

    if (this.validateFormInputs()) {
      changeLeafIndex(branch, index);
      this.setState({
        index: "unselected",
        branch: this.updateBranch(branch, index), // Probably better to move to store
        successMessage: "Successfully changed position!",
        selectInputOptions: this.generatePositionOptions(Number(index), siblingLeavesCount),
      });
    }
  };

  validateFormInputs = () => {
    const { index } = this.state;

    if (index === "unselected") {
      this.setState({
        errorMessage: "Plese select a new index before submitting.",
      });
      return false;
    }

    return true;
  };

  generatePositionOptions = (index, siblingLeavesCount) => {
    let currentIndex = 1;
    const options = [];

    while (currentIndex <= siblingLeavesCount) {
      options.push(
        currentIndex - 1 === index ? (
          <option key={currentIndex - 1} value={`${currentIndex - 1}`} disabled>
            {`${currentIndex} - current position`}
          </option>
        ) : (
          <option key={currentIndex - 1} value={`${currentIndex - 1}`}>
            {currentIndex}
          </option>
        ),
      );

      currentIndex++;
    }

    return options;
  };

  getLastIndex = (branch) => Number(branch.split("_").pop());

  updateBranch = (branch, index) => {
    branch = branch.split("_");
    branch.pop();
    branch.push(String(index));

    return branch.join("_");
  };

  resetForm = () => {
    this.setState({
      index: "unselected",
      branch: "",
      siblingLeavesCount: -1,
      selectInputOptions: [],
      successMessage: "",
      errorMessage: "",
    });
  };

  render() {
    const { index, visible, selectInputOptions, successMessage, errorMessage } = this.state;

    return (
      <Modal visible={visible} close={this.__close}>
        <Form
          data={{
            formTitle: "CHANGE AN OPTION'S POSITION",
            buttonType: "change-index",
            buttonText: "Change option's position",
            handleSubmit: this.handleSubmit,
          }}
        >
          <div className="form__input">
            <label htmlFor="index">Position:</label>
            <select name="index" value={index} onChange={this.handleChange}>
              <option key={"unselected"} value="unselected" disabled hidden>
                Select an option
              </option>
              {selectInputOptions}
            </select>
          </div>
          {errorMessage && <span className="form__message form__message--error">{errorMessage}</span>}
          {successMessage && <span className="form__message form__message--success">{successMessage}</span>}
        </Form>
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  changeLeafIndex: (branch, index) => dispatch(cmsActions.changeLeafIndex(branch, index)),
});

export default connect(null, mapDispatchToProps)(ChangeLeafPositionForm);
