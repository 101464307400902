import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { createUseStyles } from "react-jss";
import { useMediaQuery } from "react-responsive";

import { faqsActions } from "../redux/faqs/actions";
import { faqsPath } from "../config";
import isEqual from "lodash/isEqual";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Search from "./Search";
import Tags from "./Tags";
import Products from "./Products";
import Topics from "./Topics";
import Questions from "./Questions";
import LanguageSelector from "../common/LanguageSelector";
import { FullQuestionFromSlug } from "./FaqFullQuestion";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

import { mediaQueries } from "../styles/variables";
import { styles } from "./styles";

const useVisibleFAQFilter = () => {
  const { activeProduct, activeTags, search, allQuestions, visibleQuestions } = useSelector((state) => state.faqs);

  const questionShouldBeVisible = ({ tags, keywords }) => {
    // product filter
    if (!tags.includes(activeProduct)) {
      return 0;
    }

    // tags filter
    const tagsSelected = activeTags.length > 0;
    // if no active tags are selected show, if there are tags, filter on tags.
    if (tagsSelected && activeTags.map((tag) => tags.includes(tag)).includes(false)) {
      return 0;
    }

    // search filter
    const wordsToFind = search.toLowerCase().split(" ");
    const keywordsFound = wordsToFind.reduce((accumulator, wordToFind) => {
      keywords.map((keyword) => {
        if (keyword.includes(wordToFind)) {
          accumulator = accumulator + 1;
        }
      });
      return accumulator;
    }, 0);
    return keywordsFound;
  };

  const dispatch = useDispatch();

  const descendingOrder = (property) => (a, b) => {
    if (a[property] < b[property]) {
      return 1;
    }
    if (a[property] > b[property]) {
      return -1;
    }
    return 0;
  };

  useEffect(() => {
    if (search.length > 0 && search.length < 3) return;

    const newVisibleQuestions = allQuestions.filter((question) => {
      const keywordsFound = questionShouldBeVisible(question);
      question.keywordsFound = keywordsFound;
      return !!keywordsFound;
    });

    newVisibleQuestions.sort(descendingOrder("keywordsFound"));
    const mostRelevantQuestions = !!search ? newVisibleQuestions.slice(0, 20) : newVisibleQuestions;

    if (!isEqual(visibleQuestions, mostRelevantQuestions)) {
      dispatch(faqsActions.setQuestions(mostRelevantQuestions));
    }
  });
};

const useStyles = createUseStyles(styles);

const FAQs = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const tabletUp = useMediaQuery(mediaQueries.bootstrap.tablet);

  useVisibleFAQFilter();

  return (
    <div className={classes.pageContainer}>
      <Container>
        {/* <LanguageSelector /> */}
        <Row>
          <Products />
        </Row>
        <Row>
          <Col md={{ offset: 1, span: 10 }}>
            <Search />
          </Col>
        </Row>
        <Row>
          <Col sm={{ offset: 1, span: 10 }}>
            <Tags />
          </Col>
        </Row>
        <div className={classes.topicsAndQuestionHolder}>
          <Row>
            <Col sm={tabletUp ? 4 : 12}>
              <Topics />
            </Col>
            <Col sm={tabletUp ? 8 : 12}>
              <Switch>
                <Route path={`${faqsPath}/:slug`} component={FullQuestionFromSlug} />
                <Route exact path={faqsPath} component={Questions} />
              </Switch>
            </Col>
          </Row>
        </div>
        <Row>
          <button className={classes.arrowUpButton} onClick={() => window.scrollTo({ top: 0, left: 0, behavior: "smooth" })}>
            <ArrowUpwardIcon className={classes.arrowUp} />
            <p className={classes.arrowUpText}>{t("top")}</p>
          </button>
        </Row>
      </Container>
    </div>
  );
};

export default FAQs;
