import React from "react";
import { PRODUCTS } from "../../../config";

import "./faq.styles.scss";

const FAQ = ({ data: { id, slug, topic, tags, title }, laguage }) => {
  const products = Object.values(PRODUCTS);

  return (
    <div className="faq">
      <h2 className="faq__title">{`${id}. ${title}`}</h2>
      <span className="faq__slug">{slug}</span>
      <div className="faq__tags-container">
        {tags.map((tag, index) => (
          <span key={index} className={"faq__tag" + (products.includes(tag) ? " faq__tag--red" : "")}>
            {tag}
          </span>
        ))}
      </div>
    </div>
  );
};

export default FAQ;
