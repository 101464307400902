import { SAVE_USER_ID, SET_LANGUAGE, SET_NONCE, RESET_APP } from "./types";
import moment from "moment";

const initialState = {
  userId: "",
  sessionStartTime: moment(),
  language: "en",
  nonce: "a",
};

export const appReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SAVE_USER_ID:
      return { ...state, userId: payload, sessionStartTime: moment() };
    case SET_LANGUAGE:
      return { ...state, language: payload };
    case SET_NONCE:
      return { ...state, nonce: payload };
    case RESET_APP:
      return { ...initialState };
    default:
      return state;
  }
};
