import React from "react";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";

import { basePath } from "../config";

import "./styles.scss";

const NotFound = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClick = () => {
    history.push(basePath);
  };

  return (
    <div className="page">
      <h1 className="page__title">{t("not-found")}</h1>
      <p className="page__subtitle">{t("page-does-not-exist")}</p>
      <button className="page__button" onClick={handleClick}>
        {t("take-me-home")}
      </button>
    </div>
  );
};

export default NotFound;
