import React from "react";

import "./leaf-children.styles.scss";

const LeafChildren = ({ children, leafChildrenExpanded }) => (
  <ul className="leaf-children">
    {leafChildrenExpanded && children && children.map((leafChild, index) => <li key={index}>{leafChild}</li>)}
    <div className="branch"></div>
  </ul>
);

export default LeafChildren;
