import { useHistory } from "react-router";
import { PRODUCTS, SLUG_TO_WIZARD_END_PARAM, WIZARD_END } from "../config";

export function parseUrl(url) {
  let parsedState = [];

  if (!url) return parsedState;

  const queryStringParams = url.slice(1).split("&"); // removes leading '?'

  return queryStringParams
    .reduce((parsedState, queryStringParam) => {
      const [key, value] = queryStringParam.split("=");
      parsedState.push(key, value);

      return parsedState;
    }, parsedState)
    .filter(Boolean);
}

export const isNotWizardEndSlug = (slug) => !Object.values(WIZARD_END.PARAMS).includes(slug);

export const filterNotWizardEndSlug = (slugs) => {
  const wizardEndSlugIndex = slugs.findIndex((slug) => !isNotWizardEndSlug(slug));

  if (wizardEndSlugIndex > -1) {
    return slugs.slice(0, wizardEndSlugIndex);
  }

  return slugs;
};

export function stateToQueryString(state) {
  const queryPairsString = state
    .map((_, index) => {
      if (index % 2 === 0) {
        return `${state[index]}=${state[index + 1]}`;
      }
    })
    .filter(Boolean)
    .join("&");

  return `?${queryPairsString}`;
}

export const getWizardEndParam = (answerSlug, answers) => {
  if (answers) {
    const answerLeaf = answers.find((answer) => answer.slug === answerSlug);

    if (answerLeaf && answerLeaf.leaves) {
      const {
        leaves: [currentWizardSlide],
      } = answerLeaf;
      const wizardEndParam = SLUG_TO_WIZARD_END_PARAM[currentWizardSlide.slug];

      if (wizardEndParam) {
        return `&${wizardEndParam}`;
      }
    }
  }

  return "";
};

export const useUrlUpdater = (questionSlug, answers) => {
  const history = useHistory();

  return (answerSlug) => {
    const parsedState = parseUrl(history.location.search);
    const newState = [...parsedState, questionSlug, answerSlug];
    let queryString = stateToQueryString(newState);
    queryString += getWizardEndParam(answerSlug, answers);

    window.scroll({ left: 0, top: 0, behavior: "smooth" });
    return history.push(queryString);
  };
};

export const getProductFromState = (state) => {
  const { MEATER, MEATER_PLUS, MEATER_BLOCK } = PRODUCTS;
  const products = [MEATER_BLOCK, MEATER_PLUS, MEATER];

  let product;
  const howCanWeHelpQuestionIndex = state.lastIndexOf("how-can-we-help");
  const warrantyReturnsQuestionIndex = state.lastIndexOf("warranty-returns");

  if (warrantyReturnsQuestionIndex >= 0 && warrantyReturnsQuestionIndex % 2 === 0) {
    const warrantyReturnsAnswer = state[warrantyReturnsQuestionIndex + 1];
    product = products.find((product) => warrantyReturnsAnswer.includes(product));
  } else if (howCanWeHelpQuestionIndex >= 0 && howCanWeHelpQuestionIndex % 2 === 0) {
    const howCanWeHelpAnswer = state[howCanWeHelpQuestionIndex + 1];
    product = products.find((product) => howCanWeHelpAnswer.includes(product));
  }

  return !!product ? product : MEATER;
};

export const openInNewTab = (url) => {
  if (url) {
    const tab = window.open(url, "_blank", "noopener,noreferrer");
    tab && tab.focus();
  }
};

export const redirectTo = (url) => {
  if (url) {
    window.location = url;
  }
};
