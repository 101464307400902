import { strapiURL, faqsPath } from "../config";

export const onFAQsPage = () => window.location.pathname.startsWith(faqsPath);

export const replacePlaceholderWithProductCapitalized = (string, product) => string.replace(/{{\s*product\s*}}/g, product);

export const replacePlaceholderWithProduct = (string, product) => string.replace(/{\s*product\s*}/g, product);

export const addClassToElement = (text, element, className) => {
  const re = new RegExp(`<${element}>(.|\n)*?`, "g");
  return text.replace(re, `<${element} class="${className}">`);
};

export const wrapListItem = (text) =>
  text.replace(/\<li\>(.*?)\<\/li\>/g, (_, inside) => `<li><p class="content__text">${inside}</p></li>`);

export const wrapImage = (text) =>
  text.replace(
    /\<img.+src\=(?:\"|\')(.+?)(?:\"|\')(?:.+?)\>/g,
    (_, inside) => `<div class="content__image-container"><img class="content__image" src="${strapiURL}${inside}" /></div>`,
  );
