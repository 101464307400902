import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { ZENDESK_LIVE_CHAT } from "../config";

import Loading from "../pages/Loading";
import { zendeskActions } from "../redux/zendesk-form/actions";

export const WithLiveChat = ({ children }) => {
  const dispatch = useDispatch();
  const [checkingLiveChatStatus, setCheckingLiveChatStatus] = useState(true);

  const isLiveChatLauncherVisible = (liveChatLauncher) => liveChatLauncher.style.visibility !== "hidden";

  const hideLiveChatLauncher = (liveChatLauncher) => {
    liveChatLauncher.style.top = "-9999px";
    liveChatLauncher.style.right = "-9999px";
    liveChatLauncher.style.opacity = 0;
  };

  const addLiveChatLauncherStyleObserver = (liveChatLauncher) => {
    dispatch(zendeskActions.setState({ liveChatAvailable: isLiveChatLauncherVisible(liveChatLauncher) }));

    const observer = new MutationObserver(function () {
      dispatch(zendeskActions.setState({ liveChatAvailable: isLiveChatLauncherVisible(liveChatLauncher) }));

      if (isLiveChatLauncherVisible(liveChatLauncher)) {
        hideLiveChatLauncher(liveChatLauncher);
      }
    });

    const observerOptions = {
      childList: true,
      subtree: true,
      attributes: true,
      attributeFilter: ["style"],
    };

    observer.observe(liveChatLauncher, observerOptions);
  };

  const handleLiveChatStatus = () => {
    const liveChatLauncher = document.getElementById(ZENDESK_LIVE_CHAT.LAUNCHER_ID);
    hideLiveChatLauncher(liveChatLauncher);
    addLiveChatLauncherStyleObserver(liveChatLauncher);
    setCheckingLiveChatStatus(false);
  };

  const addLiveChatSnippet = () => {
    const liveChatSnippet = document.createElement("script");
    liveChatSnippet.id = ZENDESK_LIVE_CHAT.SCIRPT_ID;
    liveChatSnippet.defer = true;

    liveChatSnippet.onload = function () {
      window.zE("webWidget:on", "chat:connected", handleLiveChatStatus);
    };

    liveChatSnippet.src = ZENDESK_LIVE_CHAT.SCRIPT_SRC;
    document.body.appendChild(liveChatSnippet);
  };

  const checkLiveChatStatus = () => {
    const isLiveChatSnippetAdded = document.getElementById(ZENDESK_LIVE_CHAT.SCIRPT_ID);
    !isLiveChatSnippetAdded ? addLiveChatSnippet() : handleLiveChatStatus();
  };

  useEffect(() => {
    checkLiveChatStatus();
  }, []);

  if (checkingLiveChatStatus) {
    return <Loading />;
  }

  return children;
};
