import React from "react";

import Col from "react-bootstrap/Col";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

import "./styles.scss";

const CustomSelect = ({ text, name, value, options, handleChange }) => {
  const generateSelectOptions = () =>
    options.map(({ text, value }, index) => (
      <option key={index} value={value}>
        {text}
      </option>
    ));

  return (
    <div className="custom-select">
      <Col sm={4} xs={3}>
        <label className="custom-select__label" htmlFor={name}>
          {text}:
        </label>
      </Col>
      <Col sm={8} xs={9}>
        <select name={name} value={value} onChange={handleChange}>
          {generateSelectOptions()}
        </select>
        <ArrowDropDownIcon className="custom-select__icon" />
      </Col>
    </div>
  );
};

export default CustomSelect;
