import React from "react";
import { useTranslation } from "react-i18next";

import Col from "react-bootstrap/Col";
import { BasicInput } from "./Input";

export const PressInfluencerBlock = () => {
  const { t } = useTranslation();

  return (
    <>
      <Col sm={6}>
        <BasicInput storeKey={"publicationPlatform"} label={t("publication-or-platform")} required />
      </Col>
      <Col sm={6}>
        <BasicInput storeKey={"websiteAccountLink"} label={t("website-or-account-link")} required />
      </Col>
    </>
  );
};
