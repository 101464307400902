import React from "react";
import MuiCheckbox from "@material-ui/core/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { makeStyles } from "@material-ui/core";
import { fontSize } from "../styles/variables";
import { zendeskActions } from "../redux/zendesk-form/actions";

const styles = {
  label: {
    fontSize: fontSize.lg,
    width: "calc(100% - 28px)",
  },
};
const useStyles = makeStyles(styles);

export const Checkbox = ({ storeKey, label, ...checkboxProps }) => {
  const dispatch = useDispatch();
  const { setState } = bindActionCreators(zendeskActions, dispatch);
  const value = useSelector((state) => state.zendeskForm[storeKey]);
  const classes = useStyles();

  return (
    <FormControlLabel
      classes={{ label: classes.label }}
      control={<MuiCheckbox checked={value} onChange={(e, checked) => setState({ [storeKey]: checked })} {...checkboxProps} />}
      label={label}
    />
  );
};
