import React, { useState } from "react";
import axios from "axios";

const LoginForm = ({ setAuthorized, setUserData, loginEndpoint }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleEmailChange = ({ target: { value } }) => {
    setEmail(value);
    errorMessage && setErrorMessage("");
  };

  const handlePasswordChange = ({ target: { value } }) => {
    setPassword(value);
    errorMessage && setErrorMessage("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!email) {
      setErrorMessage("Please enter your email");
      return;
    }

    if (!password) {
      setErrorMessage("Please enter your password");
      return;
    }

    !errorMessage && validateCredentials({ email, password });
  };

  const showResetPasswordForm = () => setAuthorized(3);

  const validateCredentials = (credentials) =>
    axios
      .post(loginEndpoint, credentials)
      .then(({ status, data }) => {
        if (status === 200) {
          setAuthorized(1);
          setUserData(data);
        }
      })
      .catch(({ response: { status } }) => {
        status === 401 && setAuthorized(0);
        status === 500 && setAuthorized(-1);
      });

  return (
    <div className="with-login">
      <h1 className="with-login__title">Login</h1>
      <div className="with-login__error-message">
        <span>{errorMessage}</span>
      </div>
      <form className="with-login__form">
        <input
          className="with-login__form__input"
          type="email"
          name="email"
          value={email}
          required
          placeholder="Email"
          onChange={handleEmailChange}
        />
        <input
          className="with-login__form__input"
          type="password"
          name="password"
          value={password}
          required
          placeholder="Password"
          onChange={handlePasswordChange}
        />
      </form>
      <button className="with-login__button" type="submit" onClick={handleSubmit}>
        Submit
      </button>
      <div className="with-login__link">
        <span onClick={showResetPasswordForm}>Forgot password?</span>
      </div>
    </div>
  );
};

export default LoginForm;
