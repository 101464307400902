import { fontSize } from "../../styles/variables";

const questionsTopicTitle = {
  marginTop: "0px",
  marginBottom: "24px",
  fontSize: fontSize.titleMd,
  fontWeight: 500,
};

const questionsContainer = {
  paddingLeft: "0px",
};

export const styles = { questionsTopicTitle, questionsContainer };
