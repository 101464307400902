import React, { useEffect } from "react";
import ReactGA from "react-ga";
import { Route, Switch } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router";

import useScrollToTop from "../helpers/scrollToTop";
import { wizardActions } from "../redux/wizard/actions";
import { zendeskActions } from "../redux/zendesk-form/actions";
import {
  faqsPath,
  wizardPath,
  basePath,
  thankYouPath,
  happyCookingPath,
  contactUsPath,
  thankYouPathMarketing,
  traegerSupportPath,
  cmsPath,
  devMode,
  resellerSupportPath,
} from "../config";

import FAQs from "../faqs";
import { WizardSlidesAPI } from "./WizardSlidesAPI";
import { Hero } from "../wizard/Hero";
import { Home } from "../pages/Home";
import { ThankYou, ThankYouMarketing } from "../pages/ThankYou";
import { HappyCooking } from "../pages/HappyCooking";
import NotFound from "../pages/NotFound";
import Forbidden from "../pages/Forbidden";
import { ContactUs } from "../contact-us";
import { ResellerZendeskForm, TraegerSupportFormWithLogin } from "../zendesk-form";

import "./styles.scss";

const usePageViewAnalytics = () => {
  const location = useLocation();
  useEffect(() => {
    const url = `${basePath === "/" ? "" : basePath}${location.pathname}${location.search}`;
    !devMode && ReactGA.pageview(url);
  }, [location.search, location.pathname]);
};

export default function Wizard() {
  usePageViewAnalytics();
  useScrollToTop();

  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const onTraegerSupportFormPage = () => pathname === traegerSupportPath;
  const clearAgentDetails = () => dispatch(zendeskActions.setState({ agentEmail: "", contactID: "" }));

  const resetWizardState = () => {
    dispatch(wizardActions.setAnswers([]));
    dispatch(wizardActions.setAnswerTitles([]));
  };

  useEffect(() => {
    if (!onTraegerSupportFormPage()) {
      clearAgentDetails();
    } else {
      resetWizardState();
    }
  }, [pathname]);

  return (
    <div className="wizard-wrapper">
      <Hero />
      <Switch>
        <Route exact path={basePath} component={Home} />
        <Route path={faqsPath} component={FAQs} />
        <Route exact path={wizardPath} component={WizardSlidesAPI} />
        <Route exact path={thankYouPath} component={ThankYou} />
        <Route exact path={thankYouPathMarketing} Marketing component={ThankYouMarketing} />
        <Route exact path={happyCookingPath} component={HappyCooking} />
        {/* <Route
          exact
          path={traegerSupportPath}
          component={TraegerSupportFormWithLogin}
        /> */}
        <Route path={contactUsPath} component={ContactUs} />
        <Route path={resellerSupportPath} component={ResellerZendeskForm} />
        <Route path={cmsPath} component={Forbidden} />
        <Route component={NotFound} />
      </Switch>
    </div>
  );
}
