import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { isNumeric } from "../helpers/regex";
import { zendeskFormSelector } from "../redux/zendesk-form/selector";

import { OrderDetails } from "./OrderDetails";

export default ({ onSubmit }) => {
  const { t } = useTranslation();
  const [statusMessage, setStatusMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [recaptcha, setRecaptcha] = useState("");
  const [recaptchaError, setRecaptchaError] = useState(false);
  const [recaptchaInstance, setRecaptchaInstance] = useState(null);
  const { orderNumber, email } = useSelector(zendeskFormSelector);

  const onRecaptchaChange = (value) => {
    if (typeof value === "string" && value.length) {
      setRecaptcha(value);
      setRecaptchaError(false);
    } else {
      setRecaptcha("");
      setRecaptchaError(true);
    }
  };

  const resetRecaptcha = () => {
    if (recaptchaInstance && typeof recaptchaInstance.reset === "function") {
      recaptchaInstance.reset();
      setRecaptcha("");
    }
  };

  const resetMessages = () => {
    statusMessage && setStatusMessage("");
    errorMessage && setErrorMessage("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    resetMessages();

    if (isNumeric(orderNumber.substring(0, 2))) {
      return setErrorMessage(t("include-the-letters-in-your-order-number"));
    }

    if (typeof recaptcha !== "string" || recaptcha.length < 10) {
      return setRecaptchaError(true);
    }

    try {
      setStatusMessage(t("fetching-order-details"));
      await onSubmit(recaptcha);
    } catch (error) {
      setStatusMessage("");
      resetRecaptcha();

      if (error.response.status === 400) {
        setErrorMessage(t("could-not-find-your-order"));
      } else if (error.response.status === 422) {
        setErrorMessage(t("could-not-process-your-order"));
      }
    }
  };

  useEffect(() => {
    resetMessages();
  }, [orderNumber, email]);

  useEffect(() => {
    recaptcha && resetMessages();
  }, [recaptcha]);

  useEffect(() => {
    return () => {
      resetMessages();
      resetRecaptcha();
    };
  }, []);

  return (
    <OrderDetails
      statusMessage={statusMessage}
      recaptchaError={recaptchaError}
      errorMessage={errorMessage}
      setRecaptchaInstance={setRecaptchaInstance}
      onRecaptchaChange={onRecaptchaChange}
      onSubmit={handleSubmit}
    />
  );
};
