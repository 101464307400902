import React, { useEffect, useState, useRef } from "react";
import { useLocation, useParams } from "react-router";
import { useSelector, useDispatch } from "react-redux";
import i18next from "i18next";

import { Cache, useCache, CACHE, COLLECTION_TYPES } from "../../helpers/Cache";
import { getContentByLanguage } from "../../helpers/Cache/utils";
import { faqsActions, fetchFAQs } from "../../redux/faqs/actions";
import { appActions } from "../../redux/app/actions";

import { ContentContainerWithForwardRef } from "../../content/ContentContainer";
import FAQ from "../../content/FAQ";
import { NoFaqFound } from "../NoFaqFound";

const FullQuestion = () => {
  const { faqsStore } = Cache;
  const { FAQS_LAST_VISIT } = CACHE;
  const { FAQS } = COLLECTION_TYPES;
  const { slug } = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const faqContentRef = useRef(null);
  const [faq, setFaq] = useState(null);
  const { questions, activeProduct, search, activeTags, allTopics, language } = useSelector((state) => ({
    questions: state.faqs.allQuestions,
    activeProduct: state.faqs.activeProduct,
    search: state.faqs.search,
    activeTags: state.faqs.activeTags,
    allTopics: state.faqs.allTopics,
    language: state.app.language,
  }));

  const question = questions.find((question) => question.slug === slug);

  const handleFreshCache = async () => {
    if (!!question) {
      const uncachedFaqs = await Cache.check(faqsStore, [question]);

      if (uncachedFaqs) {
        const [faq] = await Cache.load(faqsStore, uncachedFaqs);
        setFaq(getContentByLanguage(faq, FAQS, "en"));
      } else {
        const [cachedFaq] = await Cache.get(faqsStore, [question]);
        setFaq(getContentByLanguage(cachedFaq, FAQS, "en"));
      }
    }
  };

  const handleExpiredOrNoCache = async () => {
    const faqs = await dispatch(fetchFAQs());
    const question = faqs.find((faq) => faq.slug === slug);

    const [faq] = await Cache.load(faqsStore, [question]);
    setFaq(getContentByLanguage(faq, FAQS, "en"));
  };

  useCache(
    handleFreshCache,
    handleExpiredOrNoCache,
    faqsStore,
    FAQS_LAST_VISIT,
    [location.pathname],
    //[language, location.pathname]
  );

  useEffect(() => {
    if (!!faq && !!faqContentRef && !!faqContentRef.current) {
      const faqContentTopPadding = faqContentRef.current.getBoundingClientRect().top + window.scrollY;
      window.scrollTo({ top: faqContentTopPadding - 80, behavior: "smooth" });
    }

    if (!!question) {
      !!search || activeTags.length
        ? !!allTopics.CUSTOM_TOPIC && dispatch(faqsActions.setTopic(allTopics.CUSTOM_TOPIC.slug))
        : !!allTopics.COMMON_QUESTIONS && dispatch(faqsActions.setTopic(allTopics.COMMON_QUESTIONS.slug));
      !question.tags.includes(activeProduct) && dispatch(faqsActions.setProduct(question.tags[0]));
    }
  }, [faq]);

  useEffect(() => {
    language !== "en" && dispatch(appActions.setLanguage("en"));
    !i18next.language.includes("en") && i18next.changeLanguage("en");
  }, []);

  if (!question) {
    return <NoFaqFound />;
  }

  return (
    <ContentContainerWithForwardRef ref={faqContentRef}>{!!faq && <FAQ key={faq.id} data={faq} embedded />}</ContentContainerWithForwardRef>
  );
};

export const FullQuestionFromSlug = FullQuestion;
