import React from "react";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import GenericError from "../pages/GenericError";
import ErrorBoundary from "../common/ErrorBoundary";
import { WithNavigation } from "./WithNavigation";

export const WizardSlides = ({ children, onNavigationBackClick, onNavigationHomeClick, onNavigationRestartClick }) => (
  <ErrorBoundary fallbackComponent={GenericError}>
    <Container>
      <Row className="main-wrapper">
        <Col xs={12}>
          <WithNavigation onBackClick={onNavigationBackClick} onHomeClick={onNavigationHomeClick} onRestartClick={onNavigationRestartClick}>
            {children}
          </WithNavigation>
        </Col>
      </Row>
    </Container>
  </ErrorBoundary>
);
