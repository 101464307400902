import { SAVE_USER_ID, SET_LANGUAGE, SET_NONCE, RESET_APP } from "./types";

const saveUserID = (payload) => ({
  type: SAVE_USER_ID,
  payload,
});

const setLanguage = (payload) => ({
  type: SET_LANGUAGE,
  payload,
});

const setNonce = (payload) => ({
  type: SET_NONCE,
  payload,
});

const resetApp = () => ({
  type: RESET_APP,
});

export const appActions = { saveUserID, setLanguage, setNonce, resetApp };
