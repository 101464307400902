import { DISPLAY_CONTACT_SUPPORT_FORM, HIDE_CONTACT_SUPPORT_FORM, SET_ORDER_CHECKER_STATE, RESET_ORDER_CHECKER_STATE } from "./types";

const displayContactSupportForm = () => ({
  type: DISPLAY_CONTACT_SUPPORT_FORM,
});
const hideContactSupportForm = () => ({
  type: HIDE_CONTACT_SUPPORT_FORM,
});

const setState = (payload) => ({
  type: SET_ORDER_CHECKER_STATE,
  payload,
});

const resetState = () => ({
  type: RESET_ORDER_CHECKER_STATE,
});

export const orderCheckerActions = {
  displayContactSupportForm,
  hideContactSupportForm,
  setState,
  resetState,
};
