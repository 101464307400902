import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import { cmsPath } from "../config";
import { Cache } from "../helpers/Cache";

import Alert from "./common/Alert";
import Confirm from "./common/Confirm";
import ScrollToTop from "./common/ScrollToTop";
import Button from "./common/Button";
import Navbar from "./Navbar";
import Home from "./Home";
import WizardCMS from "./wizard";
import FAQsCMS from "./faqs";

import "./cms.styles.scss";

const App = () => (
  <Router basename={cmsPath}>
    <Navbar />
    <Alert />
    <Confirm />
    <ScrollToTop />
    <Button text="Clear cache" type="clear-cache" handleClick={Cache.clearLastVisit()} />
    <Switch>
      <Route exact path="/" component={Home} />
      <Route path="/wizard" component={WizardCMS} />
      <Route path="/faqs" component={FAQsCMS} />
    </Switch>
  </Router>
);

export default App;
