import React, { useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";

import "./modal.styles.scss";

const Modal = ({ visible, close, children }) => {
  const [isVisible, setIsVisible] = useState(visible);

  useEffect(() => {
    setIsVisible(visible);
  }, [visible]);

  return (
    <div className={"modal" + (isVisible ? " modal--visible " : "")} onClick={close}>
      <div className="modal__content-container">
        <CloseIcon className="modal__close-icon" onClick={close} />
        {children}
      </div>
    </div>
  );
};

export default Modal;
