import React from "react";
import { useTranslation } from "react-i18next";

import { wizardPath } from "../config";

import { ContentContainer } from "../content/ContentContainer";
import { StrongText } from "../common/StrongText";
import { ButtonAsAnchor } from "../common/ButtonAsAnchor";

export const DisclaimerPressBusiness = ({ title }) => {
  const { t } = useTranslation();

  return (
    <ContentContainer>
      <h1 className="content__title text--centered text--uppercase">{title}</h1>
      <p className="content__text">
        <StrongText text={t("press-influencers-business-disclaimer-strong")} spaceBefore={false} />
        {t("press-influencers-business-disclaimer-1")}
        <ButtonAsAnchor text={t("press-influencers-business-disclaimer-link")} location={wizardPath} spaceAfter={false} />
        {t("press-influencers-business-disclaimer-2")}
      </p>
    </ContentContainer>
  );
};
