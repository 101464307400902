import React from "react";

import "./form.styles.scss";

const Form = ({ children, data: { formTitle, buttonType = "", buttonText, handleSubmit } }) => (
  <div className="form-container">
    <h2 className="form__title">{formTitle}</h2>
    <form onSubmit={handleSubmit}>
      {children}
      <button className={"form__submit-button" + (buttonType ? " form__submit-button--" + buttonType : "")} type="submit">
        {buttonText}
      </button>
    </form>
  </div>
);

export default Form;
