import React from "react";

import "./styles.scss";

const Forbidden = () => (
  <div className="page">
    <h1 className="page__title">Forbidden</h1>
    <p className="page__subtitle">You don't have permission to access this resource.</p>
  </div>
);

export default Forbidden;
