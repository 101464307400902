import React from "react";
import { useTranslation } from "react-i18next";

import { wizardPath } from "../config";

import { ContentContainer } from "../content/ContentContainer";
import { ButtonAsAnchor } from "../common/ButtonAsAnchor";

export const DisclaimerReseller = () => {
  const { t } = useTranslation();
  const title = t("customer-happiness-team-ready-to-help");

  return (
    <ContentContainer>
      <h1 className="content__title text--centered">{title}</h1>
      <p className="content__text">
        {t("reseller-disclaimer-1")}
        <ButtonAsAnchor text={t("reseller-disclaimer-link")} location={wizardPath} spaceAfter={false} />
        {t("reseller-disclaimer-2")}
      </p>
    </ContentContainer>
  );
};
