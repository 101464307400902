import { colors, fontSize } from "./variables";

export const buttonAsAnchor = {
  background: "transparent",
  color: colors.anchorBlue,
  border: "none",
  padding: 0,
  fontSize: fontSize.lg,
  "&:hover": {
    textDecoration: "underline",
    color: colors.anchorHoverBlue,
    cursor: "pointer",
  },
  "&:visited": {
    color: colors.anchorVisitedBlue,
  },
};

// styles for text block
export const textStyles = {
  body: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.lg : fontSize.sm,
    textAlign: "justify",
  }),
  inline: {
    fontSize: fontSize.lg,
    display: "inline",
  },
  h1: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.subtitle : fontSize.xlg,
    fontWeight: 500,
    margin: 0,
    textAlign: "center",
    marginBottom: "8px",
  }),
  h2: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.xlg : fontSize.md,
    margin: 0,
    fontWeight: 900,
  }),
  centerBody: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.lg : fontSize.sm,
    textAlign: "center",
  }),
  zendeskSubtitle: ({ tabletUp }) => ({
    fontSize: tabletUp ? fontSize.lg : fontSize.sm,
    textAlign: "center",
    fontWeight: 600,
  }),
};
