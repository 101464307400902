import React from "react";
import { useTranslation } from "react-i18next";

import LoopIcon from "@mui/icons-material/Loop";

import "./styles.scss";

const Loading = () => {
  const { t } = useTranslation();

  return (
    <div className="page">
      <LoopIcon className="page__loading-spinner" />
      <p className="page__subtitle">{t("please-wait")}</p>
    </div>
  );
};

export default Loading;
