import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import { useHistory, useLocation } from "react-router";
import axios from "axios";

import { SUPPORT_CMS_ENDPOINTS, API_URL, cmsPath } from "../config";
import { hideTraegerLiveChat } from "./utils";
import { cmsActions } from "../redux/cms/actions";

import App from "./App";
import WithLogin from "../HOC/withLogin";

import "./cms.styles.scss";

const useEditorToken = () => {
  const { editorToken } = useSelector((state) => state.cms);
  let reqInterceptor;

  useEffect(() => {
    if (editorToken) {
      axios.interceptors.request.handlers = [];
      reqInterceptor = axios.interceptors.request.use(
        (config) => {
          if (config.method === "delete") {
            if (!!config.params) {
              config.params.editorToken = editorToken;
            } else {
              config.params = { editorToken };
            }
          } else {
            if (!!config.data) {
              config.data.editorToken = editorToken;
            } else {
              config.data = { editorToken };
            }
          }

          return config;
        },
        (error) => Promise.reject(error),
      );
    }
  }, [editorToken]);

  useEffect(() => {
    return () => {
      axios.interceptors.request.eject(reqInterceptor);
    };
  }, []);
};

const CMS = () => {
  useEditorToken();

  return (
    <div className="cms-container">
      <App />
    </div>
  );
};

export const CMSwithLogin = () => {
  useEditorToken();
 // hideTraegerLiveChat();
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { setEditorToken, setEditorTokenExpiryTime } = bindActionCreators(cmsActions, dispatch);
  const { editorTokenExpiryTime } = useSelector((state) => state.cms);
  const editorTokenExpired = new Date().getTime() > editorTokenExpiryTime;

  const updateStore = ({ editorToken, editorTokenExpiryTime }) => {
    setEditorToken(editorToken);
    setEditorTokenExpiryTime(editorTokenExpiryTime);
  };

  useEffect(() => {
    location.pathname !== cmsPath && editorTokenExpired && history.push(cmsPath);
  }, [location]);

  useEffect(() => {
    document.body.classList.add("body--transparent");

    return () => {
      document.body.classList.remove("body--transparent");
    };
  }, []);

  return editorTokenExpired ? <WithLogin apiEndpoints={SUPPORT_CMS_ENDPOINTS} updateStore={updateStore} /> : <CMS />;
};
