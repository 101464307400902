import { useEffect } from "react";
import localforage from "localforage";

import {
  isEmpty,
  isNotUndefined,
  getIndexIfNotCached,
  getColletionType,
  getStrapiData,
  filterOutForms,
  getUniqueElements,
  createQueryString,
  checkImage,
  checkContentForImages,
  extractDataFrom,
} from "./utils";

export const COLLECTION_TYPES = {
  QUESTIONS: "questions",
  ANSWERS: "answers",
  FORMS: "forms",
  FAQS: "faqs",
  TOPICS: "topics",
  TAGS: "tags",
};

export const CACHE = {
  EXPIRY_TIME: 60 * 60 * 1000,
  WIZARD_LAST_VISIT: "MEATER-support-wizard-last-visit",
  FAQS_LAST_VISIT: "MEATER-support-faqs-last-visit",
  PREFIXES: {
    [COLLECTION_TYPES.QUESTIONS]: "Q_",
    [COLLECTION_TYPES.ANSWERS]: "A_",
    [COLLECTION_TYPES.FAQS]: "FAQ_",
  },
};

const wizardStore = localforage.createInstance({
  name: "MEATER Support Wizard Cache",
});

const faqsStore = localforage.createInstance({
  name: "MEATER Support FAQs Cache",
});

const { EXPIRY_TIME, PREFIXES } = CACHE;
const { QUESTIONS, ANSWERS, FAQS } = COLLECTION_TYPES;

const clearLastVisit = () => {
  const { WIZARD_LAST_VISIT, FAQS_LAST_VISIT } = CACHE;
  wizardStore.removeItem(WIZARD_LAST_VISIT);
  faqsStore.removeItem(FAQS_LAST_VISIT);
};

const empty = (store) => store.clear();

const getTitles = (slugs) =>
  Promise.all(
    slugs.map((slug, index) =>
      wizardStore.getItem((index % 2 === 0 ? PREFIXES[QUESTIONS] : PREFIXES[ANSWERS]) + slug).then((element) => !!element && element.title),
    ),
  );

const get = async (store, elements) => {
  if (!Array.isArray(elements)) return false;
  const filteredElements = filterOutForms(elements);

  if (isEmpty(filteredElements)) return false;
  const uniqueElements = getUniqueElements(filteredElements);
  const collectionType = getColletionType(uniqueElements[0]);
  const collectionPrefix = PREFIXES[collectionType];

  return Promise.all(elements.map((element) => store.getItem(collectionPrefix + element.slug))).then((cachedElements) =>
    cachedElements.filter(Boolean),
  );
};

const add = async (store, elements, collectionType) => {
  const collectionPrefix = PREFIXES[collectionType];
  elements.map((element) => {
    checkImages(element, collectionType);
    store.setItem(collectionPrefix + element.slug, element);
  });
};

const check = async (store, elements) => {
  if (!Array.isArray(elements)) return false;
  const filteredElements = filterOutForms(elements);

  if (isEmpty(filteredElements)) return false;
  const uniqueElements = getUniqueElements(filteredElements);
  const collectionType = getColletionType(uniqueElements[0]);
  const collectionPrefix = PREFIXES[collectionType];
  const cachedElementPromises = [];
  uniqueElements.map((element) => cachedElementPromises.push(store.getItem(collectionPrefix + element.slug)));

  const uncachedElementIndexes = await Promise.all(cachedElementPromises).then((cachedElements) =>
    cachedElements.reduce(getIndexIfNotCached, []),
  );

  const uncachedElements = uniqueElements.filter((_, index) => uncachedElementIndexes.includes(index));

  return isEmpty(uncachedElements) ? false : uncachedElements;
};

const checkImages = (element, collectionType) => {
  const { QUESTIONS, ANSWERS, FAQS } = COLLECTION_TYPES;

  switch (collectionType) {
    case QUESTIONS:
    case FAQS:
      checkContentForImages(element.content);
      break;
    case ANSWERS:
      checkImage(element.image);
  }
};

const load = async (store, elements) => {
  if (!Array.isArray(elements)) return;
  const filteredElements = filterOutForms(elements);

  if (isEmpty(filteredElements)) return;
  const uniqueElements = getUniqueElements(filteredElements);
  const collectionType = getColletionType(uniqueElements[0]);
  const queryString = createQueryString(uniqueElements);
  const dataPromise = getStrapiData(collectionType, queryString);
  const extractData = extractDataFrom(collectionType);

  return dataPromise
    .then((data) => data.map(extractData))
    .then((elements) => {
      add(store, elements, collectionType);
      return elements;
    })
    .catch(() => {
      console.log("No data (undefined) returned");
      return [];
    });
};

const loadBySlug = (elements, store, collectionType) => {
  const queryString = elements.map((element) => `Slug=${element}`).join("&");
  const dataPromise = getStrapiData(collectionType, queryString);
  const extractData = extractDataFrom(collectionType);

  return dataPromise
    .then((data) => data.map(extractData))
    .then((elements) => {
      add(store, elements, collectionType);
      return elements;
    })
    .catch(() => {
      console.log("No data (undefined) returned");
      return [];
    });
};

const loadEmbeddedFAQs = (elements) => {
  const uniquefaqIDs = new Set();
  const uniqueQuestionSlugs = new Set();

  elements.map(({ slug, content }) => {
    content.map(
      ({ type, faqs }) =>
        type === "faq-list" &&
        faqs.map(({ faqRef: { id }, embedded }) => {
          if (embedded) {
            uniquefaqIDs.add(`id_in=${id}`);
            uniqueQuestionSlugs.add(slug);
          }
        }),
    );
  });

  if (!uniquefaqIDs.size) return elements;
  const queryString = [...uniquefaqIDs].join("&");
  const dataPromise = getStrapiData(FAQS, queryString);
  const extractData = extractDataFrom(FAQS);

  return dataPromise
    .then((data) => data.map(extractData))
    .then((embeddedFAQs) => {
      const questionSlugs = [...uniqueQuestionSlugs];
      const questionsWithEmbeddedFAQsData = questionSlugs.map((slug) => {
        const question = elements.find((element) => element.slug === slug);
        question.content.map(({ type, faqs }, index) => {
          type === "faq-list" &&
            faqs.map(({ faqRef: { slug }, embedded }, faqIndex) => {
              if (embedded) {
                const embeddedFAQ = embeddedFAQs.find((embeddedFAQ) => embeddedFAQ.slug === slug);
                question.content[index].faqs[faqIndex].faqRef = {
                  ...embeddedFAQ,
                };
              }
            });
        });

        return question;
      });

      add(wizardStore, questionsWithEmbeddedFAQsData, QUESTIONS);
      return questionsWithEmbeddedFAQsData;
    })
    .catch(() => {
      console.log("No data (undefined) returned");
      return [];
    });
};

const preload = async (store, answers) => {
  const nextQuestions = answers.reduce((questions, currentAnswer) => {
    !!currentAnswer.leaves && questions.push(currentAnswer.leaves[0]);
    return questions;
  }, []);

  check(store, nextQuestions).then(
    (uncachedQuestions) => uncachedQuestions && load(store, uncachedQuestions).then((questions) => loadEmbeddedFAQs(questions)),
  );

  const nextAnswers = nextQuestions
    .map((nextQuestion) => nextQuestion.leaves)
    .filter(isNotUndefined)
    .flat(2);

  check(store, nextAnswers).then((uncachedAnswers) => uncachedAnswers && load(store, uncachedAnswers));
};

export const useCache = (handleFreshCache, handleExpiredOrNoCache, store, lastVisitKey, dependencyList = []) => {
  useEffect(() => {
    async function handleCache() {
      const lastVisitTime = await store.getItem(lastVisitKey);
      const visited = !!lastVisitTime;
      const currentVisitTime = new Date().getTime();

      if (visited) {
        const cacheExpired = currentVisitTime - EXPIRY_TIME > lastVisitTime;

        if (cacheExpired) {
          await empty(store);
          await store.setItem(lastVisitKey, currentVisitTime);
          handleExpiredOrNoCache();
        } else {
          handleFreshCache();
        }
      } else {
        await empty(store);
        await store.setItem(lastVisitKey, currentVisitTime);
        handleExpiredOrNoCache();
      }
    }

    handleCache();
  }, dependencyList);
};

export const Cache = {
  check,
  get,
  clearLastVisit,
  getTitles,
  load,
  loadBySlug,
  loadEmbeddedFAQs,
  preload,
  wizardStore,
  faqsStore,
};
