import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import { zendeskActions } from "../redux/zendesk-form/actions";
import { makeStyles } from "@material-ui/core";

import Col from "react-bootstrap/Col";
import { BasicInput } from "./Input";
import { FormSection } from "./FormSection";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";

import { fontSize, colors } from "../styles/variables";
import "../styles/shared.scss";

const styles = {
  root: {
    overflow: "hidden",
  },
  input: {
    fontSize: fontSize.lg,
    lineHeight: "normal",
    background: colors.zenFormInputBackground,
  },
  label: {
    fontSize: fontSize.lg,
    width: "calc(100% - 28px)",
  },
  notchedOutline: {
    border: 0,
  },
};

const useStyles = makeStyles(styles);

export const ZENDESK_ORDER_METHODS = {
  DEFAULT: "",
  MEATER: "origin-meater.com",
  AMZN: "origin-amazon",
  TRAEGER: "origin-traeger",
  RETAIL: "origin-retail",
  SECOND_HAND: "origin-2ndhand",
  GIFT: "origin-gift",
  NOT_PURCHASED: "origin-notpurchased",
  IGG: "origin-igg",
  KSS: "origin-kss",
  OTHER: "origin-other",
};

export const OrderMethod = () => {
  const storeKey = "orderMethod";
  const { t } = useTranslation();
  const orderMethod = useSelector((state) => state.zendeskForm[storeKey]);
  const dispatch = useDispatch();
  const { setState } = bindActionCreators(zendeskActions, dispatch);
  const classes = useStyles();

  return (
    <>
      <FormSection text={t("order-method")} />
      <Col xs={12}>
        <TextField
          value={orderMethod}
          onChange={(e) => setState({ [storeKey]: e.target.value })}
          inputProps={{ className: classes.input }}
          InputProps={{
            classes: {
              root: classes.root,
              notchedOutline: classes.notchedOutline,
            },
          }}
          variant="outlined"
          fullWidth
          select
          label={t("order-method")}
          InputLabelProps={{ className: classes.label }}
        >
          <MenuItem value={ZENDESK_ORDER_METHODS.DEFAULT}>{t("order-method")}</MenuItem>
          <MenuItem value={ZENDESK_ORDER_METHODS.MEATER}>{t("purchased-from-meater")}</MenuItem>
          <MenuItem value={ZENDESK_ORDER_METHODS.AMZN}>{t("purchased-from-amazon")}</MenuItem>
          <MenuItem value={ZENDESK_ORDER_METHODS.TRAEGER}>{t("purchased-from-traeger")}</MenuItem>
          <MenuItem value={ZENDESK_ORDER_METHODS.RETAIL}>{t("purchased-from-retail")}</MenuItem>
          <MenuItem value={ZENDESK_ORDER_METHODS.SECOND_HAND}>{t("purchased-2nd-hand")}</MenuItem>
          <MenuItem value={ZENDESK_ORDER_METHODS.GIFT}>{t("gift")}</MenuItem>
          <MenuItem value={ZENDESK_ORDER_METHODS.NOT_PURCHASED}>{t("not-purchased-yet")}</MenuItem>
          <MenuItem value={ZENDESK_ORDER_METHODS.IGG}>{t("indie-go-go-backer")}</MenuItem>
          <MenuItem value={ZENDESK_ORDER_METHODS.KSS}>{t("kickstarter-backer")}</MenuItem>
          <MenuItem value={ZENDESK_ORDER_METHODS.OTHER}>{t("purchased-from-other")}</MenuItem>
        </TextField>
        {orderMethod === ZENDESK_ORDER_METHODS.OTHER && (
          <BasicInput storeKey={"purchasedFromOther"} label={t("purchased-from-other")} required />
        )}
        {orderMethod === ZENDESK_ORDER_METHODS.RETAIL && (
          <BasicInput storeKey={"purchasedFromRetailer"} label={t("purchased-from-retailer")} required />
        )}
      </Col>
    </>
  );
};
