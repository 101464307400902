import React from "react";
import axios from "axios";

import { strapiURL } from "../config";

import LeafWrapper from "./wizard/leaf-wrapper/LeafWrapper";
import LeafChildren from "./wizard/leaf-children/LeafChildren";

export const hideTraegerLiveChat = () => {
  const interval = setInterval(() => {
    const chatWidget = document.getElementById("amazon-connect-chat-widget");
    if (!!chatWidget) {
      chatWidget.style.display = "none";
      clearInterval(interval);
    }
  }, 50);
};

export const isEmptyObject = (obj) => obj && Object.keys(obj).length === 0 && obj.constructor === Object;

export const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

export const generateSupportWizardTree = (siblingLeaves, expanded = false, branch = "") => {
  if (!siblingLeaves) {
    return;
  }

  const leafChildren = [];

  siblingLeaves.map(({ id, slug, title, leaves }, index) => {
    branch = createLeafBranch(branch, index);
    const hasChildren = !!leaves;

    leafChildren.push(
      <LeafWrapper
        key={index}
        data={{
          id,
          slug,
          title,
          leaves,
          siblingLeaves,
          branch,
          hasChildren,
        }}
      >
        {hasChildren && <LeafChildren leafChildrenExpanded={expanded}>{generateSupportWizardTree(leaves, expanded, branch)}</LeafChildren>}
      </LeafWrapper>,
    );
  });

  return leafChildren;
};

const createLeafBranch = (branch, index) => {
  if (!isFirstChild(index) && !isFirstLevel(branch)) {
    branch = removeLastLevel(branch);
  }

  return !isEmpty(branch) ? addNextLevel(branch, index) : String(index);
};

const isFirstChild = (index) => index === 0;

const isEmpty = (string) => string === "";

const isFirstLevel = (branch) => branch.length === 1;

const addNextLevel = (branch, index) => `${branch}_${index}`;

const removeLastLevel = (branch) => branch.substring(0, branch.length - 2);

export const findByID = (id) => fetch(`${strapiURL}${id}`).then((response) => response.json());

export const findBySlug = (collectionType, slug) =>
  fetch(`${strapiURL}/${collectionType}?Slug=${slug}`).then((response) => response.json());

export const fetchDataFromStrapi = (collectionType) => axios.get(`${strapiURL}/${collectionType}?_limit=-1`).then(({ data }) => data);
