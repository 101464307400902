import React from "react";
import { useHistory } from "react-router";

export const ButtonAsAnchor = ({ text, location, spaceBefore = true, spaceAfter = true }) => {
  const history = useHistory();

  const handleClick = () => history.push(location);

  return text ? (
    <>
      {spaceBefore && <>&nbsp;</>}
      <button className="button-as-anchor" onClick={handleClick}>
        {text}
      </button>
      {spaceAfter && <>&nbsp;</>}
    </>
  ) : null;
};
